import React, { useState, useEffect } from "react";
import SearchResults from "./search_results";
import { useDispatch } from "react-redux";
import { vinanti } from "../../../../data/data";
import { updateSearchLinksList } from "../../../../store/actions";

const Search = () => {
  const [searchRes, SetSearchRes] = useState([]);
  const [dictRes, setDictRes] = useState([]);
  const [oxfordRes, setOxfordRes] = useState([]);
  const [query, setquery] = useState("");
  const [searchingState, setsearchingState] = useState(false);
  const [searchSpaceOn, setSearchSpaceOn] = useState(false);

  const dispatch = useDispatch();

  const handleOnInputChange = (e) => {
    let q = e.target.value;
    setquery(q);
    if ((e.charCode == 13) & (!e.target.value == "")) {
      submitQuery();
    }
    if (e.target.value === "") {
      SetSearchRes([]);
      setDictRes([]);
      setOxfordRes([]);
    }
  };

  const toggel_search_on = () => {
    setSearchSpaceOn(true);
  };
  const toggel_search_off = () => {
    setSearchSpaceOn(false);
    SetSearchRes([]);
    setquery("");
  };

  const dict_btn = () => {
    setsearchingState(true);
    vinanti("eng", "dict", "local_token", {
      email: "vinfinitysailor@gmail.com",
      word: query,
    }).then((query_res) => {
      query_res.length === 0 ? setDictRes([]) : setDictRes(query_res);
      setsearchingState(false);
    });
  };
  const oxford_dict_btn = () => {
    setsearchingState(true);
    vinanti("oxford", "dict", "local_token", {
      email: "vinfinitysailor@gmail.com",
      text: query,
    }).then((query_res) => {
      setOxfordRes(query_res);
      console.log(query_res);
      setsearchingState(false);
    });
  };

  const submitQuery = async () => {
    setsearchingState(true);
    await vinanti("query", "search", null, {
      query: query,
      email: "vinfinitysailor@gmail.com",
    }).then((query_res) => {
      if (query_res?.length === 0 || query_res === undefined) {
        SetSearchRes([]);
        dispatch(updateSearchLinksList([]));
      } else {
        SetSearchRes(query_res);
        dispatch(updateSearchLinksList(query_res));
      }
      setsearchingState(false);
    });
    // oxford_dict_btn();
    dict_btn();
  };
  useEffect(() => {}, [searchRes, dictRes, oxfordRes]);

  return (
    <div className="extra-search">
      <div className="input-group">
        <div className="form-outline" onClick={toggel_search_on}>
          <input
            type="search"
            id="form1"
            className="form-control"
            value={query}
            // id="search-input"//
            placeholder="Ultimate search"
            onKeyPress={handleOnInputChange}
            onChange={handleOnInputChange}
          />
        </div>
        {searchSpaceOn ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={toggel_search_off}
          >
            {/* <i className="fas fa-search"></i> */}x
          </button>
        ) : (
          <button type="button" className="btn btn-primary" onClick={submitQuery}>
            {/* <i className="fas fa-search"></i> */}Go
          </button>
        )}
      </div>
      {searchSpaceOn ? (
        <SearchResults
          srchRes={searchRes}
          searchState={searchingState}
          query={query}
          dictRes={dictRes}
          oxfordRes={oxfordRes}
          dict_btn={dict_btn}
          oxford_dict_btn={oxford_dict_btn}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;
