import { ProfileIoSchema, TypePayloadAction } from "../../type";
import * as actionTypes from "../actions/actionTypes";

const profile_init_state: ProfileIoSchema = {
  list: [],
  curr_profile: null,
  curr_profile_exp: null,
};

export const profileReducer = (
  state: ProfileIoSchema = profile_init_state,
  action: TypePayloadAction,
): ProfileIoSchema => {
  switch (action.type) {
    case actionTypes.profile_update_list:
      let new_list: any[] = [];
      if (action.payload && action.payload.length > 0)
        new_list = [...action.payload];
      return { ...state, list: new_list };

    case actionTypes.profile_set_curr_active:
      return { ...state, curr_profile: action.payload };

    case actionTypes.profile_set_curr_exp:
      return { ...state, curr_profile_exp: action.payload };

    case actionTypes.profile_update_profile_to_list:
      let nlist = state.list.map((m) =>
        m._id === action.payload._id ? action.payload : m,
      );
      return { ...state, list: [...nlist], curr_profile: action.payload };

    // case actionTypes.tasks_delete_task_from_list:
    //   let del_list = state.list.filter((m) => m._id !== action.payload._id);
    //   return { ...state, list: [...del_list] };

    default:
      return { ...state };
  }
};
