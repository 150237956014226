import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { useEffect, useState } from "react";
import {
  EventChangeArg,
  EventContentArg,
  EventInput,
} from "@fullcalendar/core";
import { Task } from "../task";
import { StreakLogger } from "../../Streak/src/streak_logger";
import { create_today } from "./Create_Today";
import { vinanti } from "../../../data/data";
import { get_date } from "../../../components/utils/general_utils";

// type CalenderState = {
//   view: "timeGridDay" | "dayGridMonth,timeGridWeek,timeGridDay";
// };

const default_state = {
  view: "timeGridDay", //"dayGridMonth,timeGridWeek,timeGridDay",
  headerToolbar: {
    left: "prev,next",
    center: "title",
    right: "today",
  },
};

export default function Calendar() {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [state] = useState<typeof default_state>(default_state);

  const onEventChange = (evt: EventChangeArg) => {
    // console.log(evt.event.toPlainObject());

    setEvents([
      ...events.map((event) =>
        event.extendedProps?.data._id === evt.event.extendedProps.data._id
          ? evt.event.toPlainObject()
          : event,
      ),
    ]);
  };

  const get_list = async () => {
    // let data = await vinanti("get_list", "today", null, null);
    let { items } = await vinanti("new", "today", null, null);
    setEvents(items);
    // console.log(items);
    return items;
  };

  const get_today = async () => {
    let data = await vinanti("get", "today", null, {
      today: get_date(Date.now(), true),
    });
    // let { items } = await vinanti("new", "today", null, null);
    if (data && data.length) {
      setEvents(data[0].items);
      // console.log("today items ", data[0].items);
    } // return items;
  };

  useEffect(() => {
    // get_list();
    get_today();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full min-h-[500px] w-full min-w-[400px] rounded-md bg-white p-2">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        height={"100%"}
        events={events}
        editable={true}
        eventStartEditable
        headerToolbar={state.headerToolbar}
        allDaySlot={false}
        eventChange={(evt) => onEventChange(evt)}
        slotLabelFormat={{
          hour: "numeric",
          minute: "numeric",
          //   meridiem: false,
          hour12: false,
        }}
        views={{
          timeGrid: {
            eventTimeFormat: {
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
              hour12: false,
            },
          },
        }}
        eventContent={renderEventContent}
        // slotMinTime={10}
      />
    </div>
  );
}

function renderEventContent(eventInfo: EventContentArg) {
  return (
    <div className="flex  gap-2 text-black">
      <b>{eventInfo.timeText}</b>
      {eventInfo.event.extendedProps.type === "Task" ? (
        <Task task={eventInfo.event.extendedProps.data} view="mini" />
      ) : eventInfo.event.extendedProps.type === "Streak" ? (
        <StreakLogger streak={eventInfo.event.extendedProps.data} />
      ) : (
        <b>{eventInfo.event.title}</b>
      )}
    </div>
  );
}
