import React, { useState, FC, useEffect } from "react";
import { get_curr_time_counter } from "../../../components/utils/general_utils.js";

interface StreakCounterProps {
  time: string;
}

const default_state = {
  counter: "",
};
export const StreakCounter: FC<StreakCounterProps> = ({
  time,
}: StreakCounterProps) => {
  const [state, setState] = useState<typeof default_state>(default_state);

  useEffect(() => {
    const timer = setInterval(() => {
      setState({
        ...state,
        counter: get_curr_time_counter(time),
      });
    }, 1 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return <div>{state.counter}</div>;
};
