// import "./css/link.css";
import { useState, useEffect, useReducer, useCallback } from "react";
import { get_date_diff } from "../../components/utils/general_utils";
import { vinanti } from "../../data/data";
import { selectPage } from "../../store/actions";
import { MaterialIcon } from "../../components/utils/material_icon";
import { LinkTypeRouter } from "./link_type_router";
import { AknowledgementGif } from "../../components/utils/aknowledgement_gif";
import { LinkIdAdder } from "./link_adder";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useAppDispatch } from "../../store/store";
import { link_update_link_to_list } from "../../store/actions/actionTypes";
import { LinkSchema } from "../../type";
import { Tag } from "../../components/utils/tag";
import { Input } from "react-utility-yard";
import { search_links } from "./utils";

type LinkProps = {
  id?: string | null;
  d?: LinkSchema;
  i?: number;
  recurr_level?: number;
  list_type?: "page_list";
  delete_model?: any; //() => openModal(d, i)
  desc_on?: boolean;
  width?: "auto";
  view?: "Binary" | "Normal" | "Expanded";
};
const defaultLink: LinkSchema = {
  _id: "",
  link_name: "",
  link_description: "",
  link_tags: "",
  link_type: "link",
  hyperlink: "",
  link_hyperlink: "",
  iframe: { src: "" },
  code_snippet: {
    lan: null,
    code: "",
    info: null,
  },
  img: "",
  glink: false,
  glinks: [],
  glinks_sorted: [],
  date_created: new Date(),
};

type linkStateProp = {
  editOn: boolean;
  status: "loading" | "success" | "error" | null;
  descOn: boolean;
};

export function Link({
  id = null,
  d = defaultLink,
  i = 0,
  list_type = "page_list",
  delete_model = null, //() => openModal(d, i)
  recurr_level = 0,
  desc_on = false,
  width = "auto",
}: LinkProps) {
  // if (!d) d = defaultLink;
  //  link State
  const [link, setLink] = useState<LinkSchema>(d);

  const [link_type, setLink_type] = useState(
    d?.link_type ? d.link_type : undefined,
  );

  const [editLink, setEditLink] = useState<LinkSchema>(link);

  //  Link View State
  const [state, setState] = useState(null);
  const [isEdit_on, setIsEdit_on] = useState(false);
  const [save_update_status, setSave_update_status] = useState<
    "loading" | "success" | "error" | null
  >(null);
  const [show_desc, toggle_disc] = useReducer(
    (show_desc) => !show_desc,
    desc_on,
  );

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: any) => dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const toggle_search_duplicates = () => {
    if (link_type === "duplicates") {
      setLink_type(d?.link_type ? d.link_type : undefined);
    } else {
      setLink_type("duplicates");
    }
  };

  // use state do not update asap, so provide raw link
  const update_link = async (raw_link?: LinkSchema) => {
    // copy of the edited link;
    let _link = raw_link || { ...editLink };
    delete _link["glinks_sorted"];
    let vin_res = await vinanti("update", "link", null, {
      updated_info: _link,
    });
    if (vin_res === "OK") {
      setSave_update_status("success");
      dispatch_action({ type: link_update_link_to_list, payload: _link });
      setLink(_link);
      setEditLink(_link);
    } else setSave_update_status("error");
    setIsEdit_on(false);
    setTimeout(() => {
      setSave_update_status(null);
    }, 1000 * 5);
  };

  const update_glink = async (link_id: string) => {
    if (!link.glinks.length) update_link({ ...link, glinks: [link_id] });
    else update_link({ ...link, glinks: [...link.glinks, link_id] });
  };

  const delete_link_from_glink = async (id: string, j: number) => {
    let _glinks = [...link.glinks];
    _glinks.splice(j, 1);
    let _link = { ...link, glinks: [..._glinks] };
    setLink(_link);
    await vinanti("update", "link", null, {
      updated_info: _link,
    });
    dispatch({ type: link_update_link_to_list, payload: _link });
  };

  // const delete_link = () => {
  //   setLink_name("LINK DELETED");
  //   setGlink(false);
  //   setGlinks([]);
  //   setLink_description("NO INFO found......");
  //   setLink_tags(undefined);
  //   delete_model();
  //   console.log("delete link");
  // };

  useEffect(() => {
    // if (link.glinks?.length > 0) {
    //   search_links(link.glinks).then((res) => {
    //     if (res) {
    //       setLink({ ...link, glinks_sorted: res });
    //     }
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => {
    // console.log("Updating for Id", id);
    if (id) {
      console.log("Id serached :", id);
      search_links([id]).then((res: any) => {
        console.log("Id found :", res);
        if (res?.length) {
          setLink(res[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <li
        // key={`${i * 10}`}
        className="w-fit list-none rounded-md bg-white p-1 px-2"
      >
        <div className="">
          <div className="flex flex-wrap justify-between">
            <div className="flex items-center justify-start">
              <div>{i}.</div>
              <div>
                <Input
                  placeholder="Link Name"
                  editOn={isEdit_on}
                  value={isEdit_on ? editLink.link_name : link.link_name}
                  onchange={(value: any) =>
                    setEditLink({ ...editLink, link_name: value })
                  }
                />
              </div>
              <MaterialIcon
                onclick={() => toggle_disc()}
                icon={show_desc ? "visibility_off" : "visibility"}
              />

              {/* {show_desc && (
                <div className="w-[500px] px-2">
                  <Input
                    className="w-full bg-gray-300"
                    placeholder="Link Description"
                    editOn={isEdit_on}
                    value={
                      isEdit_on
                        ? editLink.link_description
                        : link.link_description
                    }
                    onchange={(value: any) =>
                      setEditLink({ ...editLink, link_description: value })
                    }
                  />
                </div>
              )} */}
            </div>
            <div className="flex items-center gap-1">
              <Tag
                // isEdit={isEdit_on}
                color="bg-red-500"
                text={get_date_diff(link.date_created)}
              />
              <Input
                className=" bg-gray-400"
                placeholder="Link Tags"
                editOn={isEdit_on}
                value={isEdit_on ? editLink.link_tags : link.link_tags}
                onchange={(value: any) =>
                  setEditLink({ ...editLink, link_tags: value })
                }
              />
              <MaterialIcon
                cursor="pointer"
                onclick={() => navigator.clipboard.writeText(d?._id || "")}
                icon="link"
              />
              <AknowledgementGif status={save_update_status} />
              <MaterialIcon
                cursor="pointer"
                onclick={() => toggle_search_duplicates()}
                icon="sync"
              />
              {isEdit_on ? (
                <>
                  <MaterialIcon onclick={update_link} icon="save" />
                  <MaterialIcon
                    icon="cancel"
                    onclick={() => {
                      setEditLink(link);
                      isEdit_on ? setIsEdit_on(false) : setIsEdit_on(true);
                    }}
                  />
                </>
              ) : (
                <MaterialIcon
                  cursor="pointer"
                  onclick={() =>
                    isEdit_on ? setIsEdit_on(false) : setIsEdit_on(true)
                  }
                  icon="edit"
                />
              )}
              <div className="task-tag">
                <div
                  style={{
                    borderLeft: "1px solid #000",
                    height: "auto",
                  }}
                >
                  &nbsp;
                </div>
              </div>
              {delete_model ? (
                <MaterialIcon
                  // onClick={() => delete_model(d._id, link_name)}
                  cursor="pointer"
                  // onclick={() => delete_link()}
                  icon="delete"
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="link-wrapper row" style={{ maxWidth: width }}>
          {show_desc && (
            <div className="w-[500px] px-2">
              <Input
                className="w-full bg-gray-300"
                placeholder="Link Description"
                editOn={isEdit_on}
                value={
                  isEdit_on ? editLink.link_description : link.link_description
                }
                onchange={(value: any) =>
                  setEditLink({ ...editLink, link_description: value })
                }
              />
            </div>
          )}
          {link_type ? <LinkTypeRouter page={link_type} d={d} /> : <></>}
          {d?.code_snippet && d.code_snippet.code ? (
            <SyntaxHighlighter
              // language={d.code_snippet?.lan}
              language={"python"}
              style={coldarkDark}
              // showLineNumbers="true"
            >
              {/* {JSON.stringify(JSON.parse(custom_data ? custom_data : "{}"), null, 4)} */}
              {d.code_snippet?.code}
            </SyntaxHighlighter>
          ) : (
            <></>
          )}
          {d.iframe && d.iframe.src !== "" ? (
            <>
              <div style={{ display: "flex" }}>
                <a
                  href={d.iframe.src}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    width: "200px",
                    display: "block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {d.iframe.src}
                </a>

                <MaterialIcon
                  icon="share"
                  onclick={async () => {
                    await navigator.clipboard.writeText(d.iframe.src);
                    dispatch(selectPage("IFrame"));
                  }}
                  cursor={"pointer"}
                />
              </div>
            </>
          ) : (
            <> </>
          )}
          {d.img && d.img !== "null" ? (
            <img
              src={
                "https://storage.cloud.google.com/cloud_db_imvd/pro-admin-io/link-io/" +
                d.img
              }
              alt={d.img}
              style={{ width: "200px" }}
            />
          ) : (
            <></>
          )}

          {link.glink && recurr_level < 4 ? (
            <ul className="mx-10 flex w-fit flex-col gap-1 rounded-md bg-gray-300 p-1">
              {link.glinks?.length > 0 ? (
                link.glinks?.map((g: string, j: number) => (
                  <li className="flex items-center rounded-md bg-blue-200 p-1">
                    <Link id={g} i={j} recurr_level={recurr_level + 1} />
                    <MaterialIcon
                      icon="delete"
                      onclick={() => delete_link_from_glink(g, j)}
                    />
                  </li>
                ))
              ) : (
                <></>
              )}

              <LinkIdAdder on_attach_link={update_glink} />
            </ul>
          ) : (
            <></>
          )}
        </div>
      </li>
    </>
  );
}
