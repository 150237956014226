import React from "react";
import { ProfileSchema } from "../../type";
import Isagi_Yoichi from "../../data/images/yoichi_isagi.png";

const _profile : ProfileSchema = {
  profile_pic: Isagi_Yoichi,
  profile_name: "Isagi Yoichi",
  MBTI: "INFJ",
  tags: ["Forward Striker"],
  aka_tags: ["Egoist"],
  isFictional : true
};

export const ProfileMini = ({
  info = _profile,
  onclick = null,
}: {
  info: ProfileSchema;
  onclick: any;
}) => {
  return (
    <>
      <li className="p-3/4 mt-1 flex min-w-[250px] max-w-sm cursor-pointer items-center justify-between rounded-md bg-white transition hover:shadow-lg">
        <div className="ml-1/2 flex" onClick={onclick}>
          <img
            src={info.profile_pic}
            width="40"
            height="40"
            className="rounded-l-md"
            alt={`profile-pic-${info.profile_name}`}
          />
          <div className="ml-2 flex flex-col">
            <span className="overflow-hidden font-medium text-black">
              {info.profile_name}
            </span>
            <span className="w-32 truncate text-sm text-gray-600">
              {info.aka_tags[0] + " | " + info.MBTI}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center">
          {/* <span className="text-gray-300">11:26</span> */}
          <i className="fa fa-star text-green-400"></i>
        </div>
      </li>
    </>
  );
};
