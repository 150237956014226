import React, { useEffect, useReducer } from "react";
import { Timeline, TimelineOptions } from "vis-timeline";
import "./css/vis.css";
import { useAppSelector } from "../../store/store";
import { DataSet } from "vis-data";
import { Button } from "react-utility-yard";
import { Tasks } from "../Tasks/tasks";
// import { createRoot, Root } from "react-dom/client";

// type Props = {};

// const createRootForTimelineItem = (
//   item: TimelineItem,
//   element: Element & { timelineItemRoot: Root },
//   data: unknown,
// ) => {
//   if (!element.hasAttribute("data-is-rendered")) {
//     element.setAttribute("data-is-rendered", "true");
//     const root = createRoot(element);
//     root.render(React.createElement("div", { item, data }, item.content));
//     element.timelineItemRoot = root;
//   } else {
//     element.timelineItemRoot.render(
//       React.createElement(
//         "div",
//         {
//           item,
//           data,
//           setItemHeight: 40,
//         },
//         item.content,
//       ),
//     );
//   }
//   return "";
// };

const options: TimelineOptions = {
  width: "100%",
  // height: "360px",
  stack: true,
  margin: {
    axis: 0,
    item: 2,
  },
  orientation: "top",
  zoomMin: 1000000,
  // React componets not working well  with template and overlap
  // template: function (item: any, ele: any) {
  //   createRoot(ele).render(<ItemTemplate item={item} />);
  //   return "";
  // },
  // template: createRootForTimelineItem,
};

// const ItemTemplate = ({ item = null }: { item: any }) => {
//   if (!item) return;
//   return (
//     <div className="flex w-[500px] overflow-hidden">
//       {item.content}
//     </div>
//   );
// };

function TimeLine() {
  const _tasks = useAppSelector((state) => state.tasks.list);

  let container: HTMLElement | null;
  let timeline: any;

  const [tasks_visible, toggleTasks_visible] = useReducer(
    (tasks_visible) => !tasks_visible,
    false,
  );

  if (typeof window !== "undefined" && document) {
    container = document.getElementById("timeline-wrapper");
  }

  const get_task_items = () => {
    var tasks = _tasks;
    var Items: any = new DataSet();

    tasks?.length &&
      tasks.map((task) =>
        Items.add({
          id: task._id,
          group: 0,
          start: new Date(
            task.date_scheduled ? task.date_scheduled : task.date_expire,
          ).toLocaleString(),
          end: new Date(
            // 1000 * (task.task_length ? task.task_length : 24 * 60 * 60) +
              new Date(
                task.date_expire ? task.date_expire : 24 * 60 * 60 *1000,
              ).getTime(),
          ),
          content: task.task_name,
          item_type: "task",
          item_raw: task,
          // className: task.status ? "green" : task.date_scheduled "red",
          className: `${
            task.status
              ? " bg-green-300"
              : new Date(task.date_scheduled).getTime() > Date.now()
                ? " bg-blue-300"
                : " bg-red-300"
          }`,
        }),
      );

    return Items;
  };

  const marker_adder = () => {
    const marker_list = [
      { time: "4/2/2025", text: "2 April" },
      { time: "5/1/2025", text: "I'm In Pune" },
      { time: "2/1/2025", text: "Resigned" },
    ];
    if (timeline) {
      marker_list.map((marker) => {
        let id = new Date(marker.time).getTime();
        timeline.addCustomTime(id, id);
        timeline.setCustomTimeMarker(marker.text, id, false);
      });
    }
  };

  const redraw = () => {
    container = document.getElementById("timeline-wrapper");
    if (container) {
      container.innerHTML = "";
      let items: any[] = get_task_items();
      // let groups: any[] = [];
      timeline = new Timeline(container, items, options);
      marker_adder();
    }
  };
  useEffect(() => {
    redraw();
    return () => {
      if (timeline) timeline.destroy();
    };
  }, []);

  useEffect(() => {
    redraw();

    return () => {};
  }, [_tasks]);

  return (
    <div className="flex flex-col gap-2 p-3">
      <div className="flex gap-2">
        <Button
          text="Today 24h"
          onclick={() =>
            timeline &&
            timeline.setWindow(
              new Date().toLocaleDateString().split("T")[0],
              new Date(1000 * 60 * 60 * 24 + new Date().valueOf())
                .toLocaleDateString()
                .split("T")[0],
            )
          }
        />
        <Button
          text="Today"
          onclick={() =>
            timeline?.moveTo(new Date().toISOString().split("T")[0])
          }
        />
        <Button
          text="This Week"
          onclick={() => {
            var curr = new Date(); // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first))
              .toISOString()
              .split("T")[0];
            var lastday = new Date(curr.setDate(last))
              .toISOString()
              .split("T")[0];
            timeline?.setWindow(firstday, lastday);
          }}
        />
        <Button
          text="This month"
          onclick={() => {
            var curr = new Date();
            var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
            var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

            var firstday = first.toISOString().split("T")[0];
            var lastday = last.toISOString().split("T")[0];
            timeline?.setWindow(firstday, lastday);
          }}
        />
        <Button
          text="nxt 6 month"
          onclick={() => {
            var curr = new Date();
            var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
            var last = new Date(curr.getFullYear(), curr.getMonth() + 6, 0);

            var firstday = first.toISOString().split("T")[0];
            var lastday = last.toISOString().split("T")[0];
            timeline?.setWindow(firstday, lastday);
          }}
        />
        <Button text="Show Tasks" onclick={() => toggleTasks_visible()} />
        <Button text="refresh" onclick={() => redraw()} />
      </div>
      {tasks_visible ? (
        <div className="absolute right-2 top-12 z-10 float-right h-[800px] w-[800px]">
          <Tasks view="normal" />
        </div>
      ) : (
        <></>
      )}
      <div id="timeline-wrapper"></div>;
    </div>
  );
}

export default TimeLine;
