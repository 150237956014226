import React, { useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { useSelector } from "react-redux";
import { DataSet, Timeline } from "vis-timeline/standalone";
import { Button } from "../../components/utils/button";

import "./css/index.css";
import { Tasks } from "../Tasks/tasks";
import { TimelineWrapper } from "./timeline_page.tsx";

// create groups
var numberOfGroups = 25;
var groups = new DataSet();
groups.add(
  {
    id: 0,
    content: "Tasks",
  },
  {
    id: 0,
    content: "Days",
  },
);

// create items
var numberOfItems = 1000;
window.items = new DataSet();
var itemsPerGroup = Math.round(numberOfItems / numberOfGroups);
for (var truck = 0; truck < numberOfGroups; truck++) {
  var date = new Date();
  for (var order = 0; order < itemsPerGroup; order++) {
    date.setHours(date.getHours() + 4 * (Math.random() < 0.2));
    var start = new Date(date);
    date.setHours(date.getHours() + 2 + Math.floor(Math.random() * 4));
    var end = new Date(date);
    window.items.add({
      id: order + itemsPerGroup * truck,
      group: truck,
      start: start,
      end: end,
      content: "Order " + order,
    });
  }
}

var GroupTemplate = (group) => {
  return (
    <div>
      <label>{group.content}</label>
    </div>
  );
};

const ItemTemplate = ({ item = null }) => {
  // const [edit_on, toggleEdit] = useReducer((edit_on) => !edit_on, false);
  return (
    <div>
      {/* {edit_on ? (
        <>
          <Task d={item?.item_raw} />
          <MaterialIcon icon="cancel" onclick={() => toggleEdit()} />
        </>
      ) : (
        <MaterialIcon icon="edit" onclick={() => toggleEdit()} />
      )} */}
      <label>{item?.content}</label>
    </div>
  );
};

class VisibleFramTemplate extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  render() {
    return (
      <div>
        id: {this.props.item.id}
        <button
          onClick={() => {
            return console.log("aaaaaa");
          }}
        >
          aaaa
        </button>
      </div>
    );
  }
}

// specify options
// var options_today = {
//   orientation: "top",
//   editable: true,
//   onInitialDrawComplete: () => {
//     window.timeline_today.setWindow(
//       new Date().toLocaleDateString().split("T")[0],
//       new Date(1000 * 60 * 60 * 24 + new Date().valueOf())
//         .toLocaleDateString()
//         .split("T")[0]
//     );
//   },
//   template: function (item, element) {
//     if (!item) {
//       return;
//     }

//     return ReactDOM.createPortal(
//       ReactDOM.render(<ItemTemplate item={item} />, element),
//       element,
//       () => {
//         window.timeline.redraw();
//       }
//     );

//     // Works too
//     // return ReactDOMServer.renderToString(<ItemTemplate item={item} />)

//     // Kinda works
//     // ReactDOM.render(<ItemTemplate item={item} />, element );
//     // return ''
//   },

//   groupTemplate: function (group, element) {
//     if (!group || !group.content) {
//       return;
//     }
//     return ReactDOM.createPortal(
//       ReactDOM.render(<GroupTemplate group={group} />, element),
//       element
//     );
//   },

//   visibleFrameTemplate: function (item, element) {
//     if (!item || !element) {
//       return;
//     }
//     if (element.className.indexOf("timeline-item-visible-frame") === -1) {
//       return;
//     }
//     return ReactDOM.createPortal(
//       ReactDOM.render(<VisibleFramTemplate item={item} />, element),
//       element
//     );
//   },
// };
var options = {
  // orientation: "top",
  maxHeight: 600,
  // start: new Date(),
  // end: new Date(1000 * 60 * 60 * 24 + new Date().valueOf()),
  editable: true,
  onInitialDrawComplete: () => {
    // window.timeline.setItems(window.items);
    var curr = new Date();
    var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
    var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

    var firstday = first.toISOString().split("T")[0];
    var lastday = last.toISOString().split("T")[0];
    window.timeline.setWindow(firstday, lastday);
  },
  template: function (item, element) {
    if (!item) {
      return;
    }
    // react 18 updated root render
    // const options_template_root = createRoot(element);
    // const OptionsTemplateCallback = () => {
    //   useEffect(() => {
    //     // window.timeline.redraw();
    //     // return options_template_root.unmount();
    //   },[element]);
    //   return <ItemTemplate item={item} />;
    // };

    // return options_template_root.render(<OptionsTemplateCallback />);
    // return options_template_root.render(<ItemTemplate item={item} />);

    // Previous implimentaaion react 17
    return ReactDOM.createPortal(
      ReactDOM.render(<ItemTemplate item={item} />, element),
      element,
      () => {
        window.timeline.redraw();
      },
    );

    // Works too
    // return ReactDOMServer.renderToString(<ItemTemplate item={item} />)

    // Kinda works
    // ReactDOM.render(<ItemTemplate item={item} />, element );
    // return ''
  },

  groupTemplate: function (group, element) {
    if (!group || !group.content) {
      return;
    }
    // return ReactDOM.createPortal(
    //   ReactDOM.render(<GroupTemplate group={group} />, element),
    //   element
    // );

    // react 18 updated root render
    const group_template_root = createRoot(element);
    const GroupTemplateCallback = () => {
      return <GroupTemplate group={group} />;
    };

    return group_template_root.render(<GroupTemplateCallback />);
  },

  visibleFrameTemplate: function (item, element) {
    if (!item || !element) {
      return;
    }
    if (element.className.indexOf("timeline-item-visible-frame") === -1) {
      return;
    }
    // return ReactDOM.createPortal(
    //   ReactDOM.render(<VisibleFramTemplate item={item} />, element),
    //   element
    // );
    // react 18 updated root render
    const visible_template_root = createRoot(element);
    const VisibleTemplateCallback = () => {
      // useEffect(() => {
      //   window.timeline.redraw();
      //   return visible_template_root.unmount();
      // },[item]);
      return <VisibleFramTemplate item={item} />;
    };

    return visible_template_root.render(<VisibleTemplateCallback />);
  },
};

export const TimeLineIo = () => {
  var tasks_list = useSelector((state) => state.tasks.list);
  var days_list = useSelector((state) => state.days_list);
  const [tasks_visible, toggleTasks_visible] = useReducer(
    (tasks_visible) => !tasks_visible,
    false,
  );
  // const [curr_task, setCurr_task] = useState(null);

  const get_task_items = () => {
    var tasks = tasks_list;
    var Items = new DataSet();

    tasks && tasks.length > 0
      ? tasks.map((task) =>
          Items.add({
            id: task._id,
            group: 0,
            start: new Date(
              task.date_scheduled ? task.date_scheduled : task.date_expire,
            ).toLocaleString(),
            end: new Date(
              1000 * (task.task_length ? task.task_length : 24 * 60 * 60) +
                new Date(
                  task.date_scheduled ? task.date_scheduled : task.date_expire,
                ).getTime(),
            ),
            content: task.task_name,
            item_type: "task",
            item_raw: task,
            // className: task.status ? "green" : task.date_scheduled "red",
            className: task.status
              ? "green"
              : new Date(task.date_scheduled).getTime() > Date.now()
                ? "blue"
                : "red",
          }),
        )
      : console.log("no tasks found");

    return Items;
  };
  const get_day_items = () => {
    var days = days_list;
    var Items = new DataSet();
    console.log(days);

    days && days.length > 0
      ? days.map((day) =>
          day.items && day.items.length > 0
            ? day.items.map((item) =>
                Items.add({
                  id: item._id,
                  group: 0,
                  start: item.item_scheduled ? item.item_scheduled : Date.now(),
                  end: new Date(
                    1000 *
                      (item.item_length ? item.item_length : 24 * 60 * 60) +
                      new Date(
                        item.item_scheduled ? item.item_scheduled : Date.now(),
                      ).getTime(),
                  ),
                  content: item.item_name,
                  item_type: "Days",
                  item_raw: item,
                  className: "blue",
                  // className: task.status
                  //   ? "green"
                  //   : new Date(task.date_scheduled).getTime() > Date.now()
                  //   ? "blue"
                  //   : "red",
                }),
              )
            : console.log("items not found"),
        )
      : console.log("no today found");

    return Items;
  };
  const update_timeline = () => {
    initTimeline({ tasks: get_task_items(), days: get_day_items() });
  };

  useEffect(() => {
    // initTimeline({ tasks: get_task_items(), days: get_day_items() });
    // return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col gap-3">
      <h1>TimeLine</h1>
      <div className="flex gap-2">
        <Button
          text="Today 24h"
          onclick={() =>
            window.timeline.setWindow(
              new Date().toLocaleDateString().split("T")[0],
              new Date(1000 * 60 * 60 * 24 + new Date().valueOf())
                .toLocaleDateString()
                .split("T")[0],
            )
          }
        />
        <Button
          text="Today"
          onclick={() =>
            window.timeline.moveTo(new Date().toISOString().split("T")[0])
          }
        />
        <Button
          text="This Week"
          onclick={() => {
            var curr = new Date(); // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first))
              .toISOString()
              .split("T")[0];
            var lastday = new Date(curr.setDate(last))
              .toISOString()
              .split("T")[0];
            window.timeline.setWindow(firstday, lastday);
          }}
        />
        <Button
          text="This month"
          onclick={() => {
            var curr = new Date();
            var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
            var last = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

            var firstday = first.toISOString().split("T")[0];
            var lastday = last.toISOString().split("T")[0];
            window.timeline.setWindow(firstday, lastday);
          }}
        />
        <Button
          text="nxt 6 month"
          onclick={() => {
            var curr = new Date();
            var first = new Date(curr.getFullYear(), curr.getMonth(), 1);
            var last = new Date(curr.getFullYear(), curr.getMonth() + 6, 0);

            var firstday = first.toISOString().split("T")[0];
            var lastday = last.toISOString().split("T")[0];
            window.timeline.setWindow(firstday, lastday);
          }}
        />
        <Button text="Show Tasks" onclick={() => toggleTasks_visible()} />
        <Button text="refresh" onclick={() => update_timeline()} />
      </div>

      <div id="visualization"></div>
      {tasks_visible ? (
        <div className="timeline-tasksList-visible">
          <Tasks />
        </div>
      ) : (
        <></>
      )}
      {/* <Task d={curr_task} /> */}
      <TimelineWrapper />
    </div>
  );
};

function initTimeline({ tasks = null, days = null, func }) {
  console.log(tasks);
  var container = document.getElementById("visualization");
  container.replaceChildren();
  // var container1 = document.getElementById("visualization-today");
  // container1.replaceChildren();
  window.timeline = new Timeline(container, tasks, groups, options);
  // window.timeline = new Timeline(container, Items, groups);
  // window.timeline_today = new Timeline(container1, days, groups, options_today);
  // window.timeline_today.setWindow(
  //   new Date().toLocaleDateString().split("T")[0],
  //   new Date(1000 * 60 * 60 * 24 + new Date().valueOf())
  //     .toLocaleDateString()
  //     .split("T")[0]
  // );
}

// export default connect(get_task_items)(TimeLineIo);
