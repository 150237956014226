import "./css/text.css";
import { useRef, useEffect } from "react";
// import { MaterialIcon } from "./material_icon";
import { AknowledgementGif } from "./aknowledgement_gif";
/**
 ** param {String} width - width defaut value 100px
 @param {*} onChange 
 */
export function Text({
  text = "No Text",
  title = "",
  isEdit = false,
  font_size = 14,
  placeholder = "Give me Placeholder",
  onChange = null,
  value = "",
  width = "100px",
  height = "40px",
  onDoubleClick = null,
  color = "",
  onkeydown = null,
  focus = false,
  textArea = false,
  isResults = false,
  availability = { state: false, status: "error" },
  results = [],
}) {
  const Text_ref = useRef(null);

  useEffect(() => {
    if (focus) {
      Text_ref.current.focus();
    }
  }, [focus]);

  return (
    <>
      {!isEdit ? (
        <div className="text-wrapper">
          <div>{text}</div>
        </div>
      ) : (
        <div
          class=" text-input-wrapper input-group content-center"
          style={{ width: width, height: height }}
        >
          {textArea ? (
            <textarea
              class="form-control"
              aria-label="basic-addon1"
              ref={Text_ref}
              title={title}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onDoubleClick={onDoubleClick}
              onKeyDown={onkeydown}
            ></textarea>
          ) : (
            <>
              <input
                type="text"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                ref={Text_ref}
                title={title}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onDoubleClick={onDoubleClick}
                onKeyDown={onkeydown}
              />
              {availability.state ? (
                <div
                  className="text-input-availability-status "
                  style={{ zIndex: 3, position: "absolute", right: 5 }}
                >
                  <AknowledgementGif
                    size_num={30}
                    status={availability.status}
                  />
                </div>
              ) : (
                <></>
              )}
              {isResults && results.length > 0 ? (
                <div className="text-input-search-results">
                  <ul style={{ listStyleType: "none", paddingLeft: "1rem" }}>
                    {results.map((m) => (
                      <li>{m}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
export function TextWithValue({
  text = "Key : ",
  value = "Value",
  isEdit = false,
  font_size = 14,
  placeholder = "Give me Placeholder",
  onChange = null,
  width = "100px",
  onDoubleClick = null,
}) {
  return (
    <>
      <div className="text_with_value">
        <div className="text-wrapper">{text}</div>

        {!isEdit ? (
          <div className="text-wrapper">{value}</div>
        ) : (
          <div className="input_style" style={{ width: width }}>
            <input
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onDoubleClick={onDoubleClick}
            />
          </div>
        )}
      </div>
    </>
  );
}
