import { useState, useEffect, useReducer } from "react";
import { Button } from "../../components/utils/button";
import { ClipboardCopy } from "../../components/utils/clipboard_copy";
import "./docs_analyser.css";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data";
import { MaterialIcon } from "../../components/utils/material_icon";
import { Link } from "../Link_io/link";
import { TextToSpeech } from "../../components/utils/text_to_speech";
import { Text } from "../../components/utils/text";
// import { link } from "fs";
import { ToggleButton } from "../../components/utils/toggle_switch";

// const reg = new RegExp(/(\b\W+\b)(?![\s\S]*\b\1\b)/g);
// const reg = new RegExp(/\w+/g);
const reg = new RegExp(/(\b[a-z]{2,}\b)(?![\s\S]*\b\1\b)/g);
// const reg = new RegExp(/[a-zA-Z]{2,}/g);

export function DocsAnalyser() {
  const [isHTML, toggleHTML] = useReducer((isHTML) => !isHTML, true);
  const [clipboard, setClipboard] = useState(null);
  const [clipboard_plain, setClipboard_plain] = useState("no text");
  const [clip_text, setClip_text] = useState(null);
  const [clip_links, setClip_links] = useState(null);
  const [mega_words, setMega_words] = useState(null);
  const [iswordChecking, setIswordChecking] = useState(false);
  const [known_words, setKnown_words] = useState(0);
  const user_info = useSelector((state) => state.user_info);
  const [mass_tags, setMass_tags] = useState("");
  const [akn_list, setAkn_list] = useState(null); // aknowledgement of links created
  const [is_gen_links, setIs_gen_links] = useState(false);

  const load_clipboard = async () => {
    setClip_text(null);
    setClip_links(null);
    setKnown_words(0);
    setAkn_list(null);

    setClipboard_plain(await ClipboardCopy("text/plain"));

    setClipboard(await ClipboardCopy("text/html"));
    let clip = await ClipboardCopy("text/plain").then((res) => {
      let ress = res?.toLowerCase();
      return ress?.match(reg);
    });
    await setClip_text(clip?.map((res) => ({ word: res, state: false })));
    getLinks();
  };
  const generate_links = async () => {
    setIs_gen_links(true);
    if (clip_links) {
      let links = await Promise.all(
        clip_links.map(
          async (link) =>
            await vinanti("new", "link", user_info.auth_token, {
              email: user_info.user_email,
              link_name: link.link_name,
              link_description: link.link_description,
              link_tags: mass_tags,
              link_type: "link",
              hyperlink: "",
              // visits: visits,
              glink: false,
            }).then((res) => (res && res.length > 0 ? res[0] : undefined))
        )
      );
      setAkn_list(links);
      setIs_gen_links(false);
    }
  };
  const check_words = async () => {
    setIswordChecking(true);
    let clip_words = clip_text;
    let clip_fixed_words = mega_words;
    let knownWords = 0;

    !clip_fixed_words
      ? await vinanti("get_id", "custom", user_info.auth_token, {
          id: "638ed0522eda1200080e8d60",
          email: user_info.email,
        }).then(async (res) => {
          clip_fixed_words = JSON.stringify(res?.[0]?.data);
          await setMega_words(JSON.stringify(res?.[0]?.data));
        })
      : console.log("mega words found.");

    for (let i = 0; i < clip_words?.length; i++) {
      if (clip_fixed_words?.includes(clip_words[i]?.word)) {
        clip_words[i].state = true;
        knownWords++;
      }
    }
    await setClip_text(clip_words);
    await setKnown_words(knownWords);
    setIswordChecking(false);
  };

  const getLinks = async () => {
    const textToLinks = (res, specifier = "\r\n") => {
      // console.log(res);
      if (!res) return null;
      let ress = res.split(specifier);
      let list = [];
      ress.forEach((res) => {
        if (res.includes(" => ")) {
          let spt = res.split(" => ");
          console.log("include => ", spt);
          list.push({ link_name: spt?.[0], link_description: spt?.[1] });
        }
      });
      return list;
    };
    let links = isHTML
      ? await ClipboardCopy("text/plain").then((res) => textToLinks(res))
      : textToLinks(clipboard_plain, "\n");
    setClip_links(links);
  };
  // const createLink = () => {
  //   console.log("link create");
  // };
  useEffect(() => {
    // setTimeout(async () => {
    //   let links = await clip_links?.map((res) => res);
    //   setClip_links(links);
    //   console.log("50000");
    // }, 5000);
    // console.log("akn");
  }, [akn_list]);

  useEffect(() => {
    console.log(akn_list);
  });
  return (
    <>
      <div className="analyser-wrapper">
        <div className="analyser-tools">
          <Button text={"Paste ClipBoard"} onclick={() => load_clipboard()} />
          <ToggleButton
            text="html"
            state={isHTML}
            onChange={() => toggleHTML()}
          />
          <Button
            text={"Get Total Words"}
            onclick={async () =>
              setClip_text(
                await ClipboardCopy("text/plain").then((res) =>
                  res.toLowerCase().match(/\w+/g)
                )
              )
            }
          />
          <Button text={"get Links"} onclick={() => getLinks()} />
          {/* <Button text={"get Links"} onclick={() => getLinks()} /> */}
          <Button
            text={"Word Check"}
            onclick={() => check_words()}
            loading={iswordChecking}
          />
          <Button
            // z_id={10000}
            text={"Clear ClipBoard"}
            onclick={() => {
              setClipboard(null);
              setClipboard_plain(null);
              setClip_text(null);
              setClip_links(null);
              setMega_words(null);
              setKnown_words(0);
            }}
          />
          {/* <Button text={"Speak"} onclick={() => speak()} /> */}
          <TextToSpeech txt={clipboard_plain} />
        </div>
        <div className="analyser-content">
          {isHTML ? (
            <div
              className="analyser-content-clip"
              dangerouslySetInnerHTML={{ __html: clipboard }}
            />
          ) : (
            <div className="analyser-content-clip">
              <textarea
                style={{ width: "100%", height: "100%" }}
                onChange={(e) => setClipboard_plain(e.target.value)}
              >
                {clipboard_plain ? clipboard_plain : "No Text Found"}
              </textarea>
              {console.log(clipboard_plain)}
            </div>
          )}
          <div className="analyser-content-side">
            <div className="analyser-words-list">
              <div className="analyser-words-list-title">
                <h5>
                  List of Words {clip_text?.length} ( known :{known_words})
                </h5>
              </div>
              <ol className="ana-items">
                {clip_text ? (
                  clip_text.map((d) => (
                    <li className="ana-item">
                      {d?.word}
                      {d?.state ? (
                        <MaterialIcon icon="verified" />
                      ) : (
                        <>
                          <MaterialIcon icon="no_accounts" />
                        </>
                      )}
                    </li>
                  ))
                ) : (
                  <></>
                )}
              </ol>
            </div>
            {clip_links?.length > 0 ? (
              <div className="analyser-links-list">
                <div className="analyser-links-list-title">
                  <h5> List of Links {clip_links?.length}</h5>
                </div>
                <div className="analyser-links-mass-adder">
                  <Text
                    isEdit={true}
                    value={mass_tags}
                    placeholder={"Common Tags"}
                    onChange={(e) => setMass_tags(e.target.value)}
                    width={"250px"}
                  />
                  <Button
                    text="Add links"
                    icon="publish"
                    onclick={() => generate_links()}
                    loading={is_gen_links}
                  />
                  {/* <MaterialIcon
                    icon="publish"
                    onclick={() => generate_links()}
                  /> */}
                </div>
                <ol className="ana-items">
                  {clip_links ? (
                    clip_links.map((d, i) => (
                      <li className="ana-item">
                        <Link
                          d={d}
                          i={i}
                          desc_on={true}
                          // serach_duplicate={true}
                          isNew={true}
                        />
                        {akn_list && akn_list[i] ? (
                          <>
                            <div className="new-mass-link-res">
                              <MaterialIcon icon="check_circle" />
                              <Link
                                d={akn_list[i]}
                                i={i}
                                desc_on={true}
                                // serach_duplicate={true}
                                // isNew={true}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <MaterialIcon icon="error" />
                          </>
                        )}
                        {/* <h6>{`${d?.link_name} => ${d?.link_description}`}</h6> */}
                        {/* <Button text="link" onclick={() => createLink()} /> */}
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ol>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
