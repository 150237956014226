import React from "react";
import "./index.css";
import Search from "./search/search";


const UltimateSearchLayout = () => {
  return (
    <div className="search-layout">
        <Search/>
    </div>
  );
};

export default UltimateSearchLayout;
