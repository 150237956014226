import { tasks_update_list } from "./actionTypes";

/**
 * @param {String} type - Type [ TASK_PAGE_TASKS_LIST , STORY_PAGE_STORY_LIST,HOME_PAGE_TTRENDS_LIST,]
 * @param {Array} payload - Array containing the list
 */
export const listUpdateAction = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};
/**
 * @param {String} type - Type [ TASK_PAGE_TASKS_LIST , STORY_PAGE_STORY_LIST,HOME_PAGE_TTRENDS_LIST,]
 * @param {Any} payload - Array containing the list
 */
export const StateUpdateAction = (type, payload) => {
  return {
    type: type,
    payload: payload,
  };
};

export const updateTasksList = (payload) => {
  return {
    type: tasks_update_list,
    payload: payload,
  };
};
export const toggleLogin = (payload) => {
  return payload ? { type: "SIGN_IN" } : { type: "SIGN_OUT" };
};

export const userInfo = (payload) => {
  return {
    payload: payload,
    type: "USER_LOGIN_INFO",
  };
};
export const toggleMenu = () => {
  return {
    type: "MENU_VISIBLE",
  };
};
export const toggleBuzy = () => {
  return {
    type: "Buzy",
  };
};
export const selectPage = (payload) => {
  return {
    type: "PAGE_SELECTION",
    payload: payload,
  };
};
export const updateUsersList = (payload) => {
  return {
    type: "USERS_PAGE_USERS_LIST",
    payload: payload,
  };
};
export const updateLeetcodeList = (payload) => {
  return {
    type: "LEETCODE_PROBLEMS_LIST",
    payload: payload,
  };
};
export const updateStoryIoList = (payload = null) => {
  return {
    type: "STORYIO_LIST",
    payload: payload,
  };
};
export const updateRitualIoList = (payload = null) => {
  return {
    type: "RITUALIO_LIST",
    payload: payload,
  };
};
export const updateCustomSettings = (payload = null) => {
  return {
    type: "CUSTOM_SETTINGS",
    payload: payload,
  };
};
export const updateLinksList = (payload) => {
  return {
    type: "LINKS_IO_LIST",
    payload: payload,
  };
};
export const updateFavLinksList = (payload) => {
  return {
    type: "FAV_LINKS_IO_LIST",
    payload: payload,
  };
};
export const updateSearchLinksList = (payload) => {
  // console.log("update search links");
  return {
    type: "SEARCH_LINKS_LIST",
    payload: payload,
  };
};
// export const updateTTrendsList = (payload) => {
//   return {
//     type: "HOME_PAGE_TTRENDS_LIST",
//     payload: payload,
//   };
// };
export const updateProjectsList = (payload) => {
  return {
    type: "PROJECTS_PAGE_PROJECTS_LIST",
    payload: payload,
  };
};
