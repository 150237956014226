import { useState } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-sqlserver";

import Alert from "@mui/material/Alert/Alert";

import "./BoardTab.css";
import { MaterialIcon } from "../../../components/utils/material_icon";

export type BoardTabProps = {
  /** The board value. */
  value: string;

  onChange(value: string): void;

  /** The definition error message. */
  errorMessage?: string;

  readOnly: boolean;
};

/**
 * The BoardTab component.
 */
export const BoardTab: React.FunctionComponent<BoardTabProps> = ({
  value,
  onChange,
  errorMessage,
  readOnly,
}) => {
  const [editBoard, setEditBoard] = useState<Boolean>(true);
  return (
    <div className="sidebar-tab board-tab">
      <div className="flex gap-x-0.5 bg-blue-400">
        <div className="px-2 font-bold font-mono text-center my-auto">
          Agent
        </div>
        <MaterialIcon
          icon={editBoard ? "cancel" : "edit"}
          onclick={() => setEditBoard((editBoard) => !editBoard)}
          cursor="pointer"
        />
      </div>
      <AceEditor
        className="definition-tab-ace-editor"
        value={value}
        onChange={onChange}
        readOnly={!readOnly && editBoard ? false : true}
        width="100%"
        height="inherit"
        mode="mdsl"
        theme="sqlserver"
        setOptions={{ useWorker: false }}
        
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};
