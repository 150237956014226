import React, { useState, useEffect, FC } from "react";

interface prop {
  size?: string;
}
export const TimeHomePage: FC<prop> = ({ size }: prop) => {
  size = size ?? "text-7xl";
  const [live_time, setLive_time] = useState(
    `${new Date().getHours()}:${new Date().getMinutes()}`,
  );
  useEffect(() => {
    const timer = setInterval(() => {
      // setLive_time(`${new Date().getHours()}:${new Date().getMinutes()}`);
      let times = new Date()
        .toLocaleTimeString("en-US", {
          hour12: false,
        })
        .split(":");
      setLive_time(`${times[0]}:${times[1]}`);
    }, 1 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex font-poppins text-white">
      <span className={`inline-flex font-light text-inherit ${size}`}>
        {live_time}
      </span>
    </div>
  );
};
