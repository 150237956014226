import React, { useState } from "react";
import { useEffect } from "react";
import "./projects.css";
import {
  newProject,
  projectsList,
  deleteProject,
  updateProject,
} from "../../data/data";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import { updateProjectsList } from "../../store/actions";
import { Modal } from "../../components/utils/modal";

export function Projects() {
  const [project_name, setProject_name] = useState("New project");
  const [project_details] = useState("None");
  const [project_towards_project, setProject_towards_project] =
    useState("General");
  const [date_expire, setDate_expire] = useState(Date.now());
  const [subProject_on, setSubProject_on] = useState(null);
  // const [list, setList] = useState([]);
  const list = useSelector((state) => state.projects_page_projects);
  // const projects_list = useSelector((state) => state.projects_list);
  const user_info = useSelector((state) => state.user_info);
  const [Count, setCount] = useState(0);
  const [busy, setBusy] = useState(false);
  const [delModel, setDelModel] = useState(false);
  const [currDel, setCurrDel] = useState(null);
  const dispatch = useDispatch();

  const get_projects_List = async () => {
    setBusy(true);
    // var projects_data = await projectsList(user_info.auth_token, user_info.user_email);
    var data = await projectsList(user_info.auth_token, user_info.user_email);
    setBusy(false);
    // dispatch(updateProjectsList(projects_data));
    dispatch(updateProjectsList(data));
    return null;
    // setList(data);
  };
  const newProject_submit = async () => {
    setBusy(true);
    await newProject(
      user_info.auth_token,
      project_name,
      project_details,
      user_info.user_email,
      date_expire,
      "IDEL",
      [],
      [user_info.user_email]
    );
    setProject_name("New Project / Sub-project");
    get_projects_List();
    setBusy(false);
  };
  const newSubProject_submit = async (Project) => {
    var subproject = project_name;
    "sub_projects" in Project
      ? Project.sub_projects.push({
          sub_project_name: subproject,
          status: false,
        })
      : (Project.sub_projects = [
          { sub_project_name: subproject, status: false },
        ]);
    setBusy(true);
    await updateProject(Project, user_info.auth_token);
    setBusy(false);
    var newList = list;
    var index = newList.findIndex((x) => x._id === Project._id);
    newList[index] = Project;
    dispatch(updateProjectsList(newList));
    setProject_name("New Project / Sub-project");
    refresh(); //Since List not updating on time this is added temp
    console.log("Sub-Project Updated Success.");
  };

  const project_checkbox_submit = async (Project, i) => {
    var newList = list;
    Project.status ? (Project.status = false) : (Project.status = true);
    setBusy(true);
    await updateProject(Project, user_info.auth_token);
    setBusy(false);
    newList[i] = Project;
    dispatch(updateProjectsList(newList));
    refresh(); //Since List not updating on time this is added temp
    console.log("Project Updated Success.");
  };
  const sub_project_checkbox_submit = async (Project, i) => {
    var newList = list;
    Project.sub_projects[i].status
      ? (Project.sub_projects[i].status = false)
      : (Project.sub_projects[i].status = true);
    setBusy(true);
    await updateProject(Project, user_info.auth_token);
    setBusy(false);
    var index = newList.findIndex((x) => x._id === Project._id);
    newList[index] = Project;
    dispatch(updateProjectsList(newList));
    refresh(); //Since List not updating on time this is added temp
    console.log("SubProject status Updated Success.");
  };
  const Del_SubProject = async (Project, i) => {
    var newList = list;
    Project.sub_projects.splice(i, 1);
    setBusy(true);
    await updateProject(Project, user_info.auth_token);
    setBusy(false);
    var index = newList.findIndex((x) => x._id === Project._id);
    newList[index] = Project;
    dispatch(updateProjectsList(newList));
    refresh(); //Since List not updating on time this is added temp
    console.log("SubProject Deleted Updated Success.");
  };

  const refresh = () => {
    setCount(Count + 1);
    // setIcon(icon === "done" ? "pending_actions" : "done");
  };

  const project_delete = async () => {
    console.log(currDel, " topic sent for delete Final Operation.");
    setDelModel(false);
    await deleteProject(currDel, user_info.auth_token, user_info.user_email);
    setCurrDel(null);
    // console
    get_projects_List();
    // refresh();
  };
  const openModal = (project, i) => {
    console.log(project, " wants to get deleted.");
    setDelModel(true);
    setCurrDel(list[i]._id);
  };
  const closeOpenModal = () => {
    console.log("modal closed");
    setDelModel(false);
  };
  // const subProject_ = (i) => {

  // }
  useEffect(() => {
    console.log("jjjo");
    // setBusy(true);
    // get_projects_List();
    // setBusy(false);
  }, [subProject_on]);

  const get_date = (date) => {
    var data = new Date(date);
    var options = { weekday: "short", month: "short", day: "numeric" };
    // console.log(data.toLocaleDateString('en-US', options));
    return data.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {console.log("Rederered.")}
      <div className="Projects-Card-wrapper">
        <div className="Projects-Card">
          <div className="card text-center">
            <div className="card-header">
              <div>Admin Panel Projects</div>
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  {/* <a className="nav-link active" aria-current="true">
                All Projects
              </a> */}
                  <div className="nav-link active" aria-current="true">
                    All
                  </div>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link">Today</a> */}
                  <div className="nav-link">Today</div>
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link disabled">Pending</a> */}
                  <div className="nav-link disabled">Pending</div>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div>
                <input
                  title="project"
                  type="text"
                  placeholder="Enter New Project Name"
                  value={project_name}
                  onChange={(e) => setProject_name(e.target.value)}
                />
                <input
                  title="Date"
                  type="datetime-local"
                  value={project_name}
                  onChange={(e) => setDate_expire(e.target.value)}
                />
                <input
                  title="Project"
                  type="text"
                  value={project_towards_project}
                  onChange={(e) => setProject_towards_project(e.target.value)}
                />
                <button
                  type="button"
                  className="btn-sm btn-outline-success"
                  onClick={() => newProject_submit()}
                >
                  Create New
                </button>
                <button
                  type="button"
                  className="btn-sm btn-outline-success"
                  onClick={() => get_projects_List()}
                >
                  Get Projects
                </button>
                <button
                  type="button"
                  className="btn-sm btn-outline-success"
                  onClick={() => refresh()}
                >
                  refresh {Count}
                </button>
                <button
                  type="button"
                  className={
                    busy
                      ? "btn-sm btn-outline-danger"
                      : "btn-sm btn-outline-success"
                  }
                  // onClick={() => refresh()}
                >
                  {busy ? "Busy" : "Idel"}
                </button>
              </div>
              <br />
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Today</li>
                <ul className="list-group list-group-flush">
                  {list ? (
                    list.map((d, i) => (
                      <li className="list-group-item">
                        <div className="project-info-wrapper">
                          <div
                            className="project-info-inline"
                            onClick={() => setSubProject_on(i)}
                          >
                            <div className="project-name">
                              <i
                                class="material-icons"
                                onClick={() => project_checkbox_submit(d, i)}
                              >
                                {d.status ? "done" : "check_box_outline_blank"}
                              </i>
                              <label className="form-check-label">
                                {d.project_name}
                              </label>
                            </div>
                            <div className="project-tags">
                              {/* <div className="project-tag" onClick={()=> setSubProject_on(i)}>
                              <i className="material-icons">add</i>
                              </div> */}
                              <div className="project-tag">
                                <span class="badge bg-secondary">
                                  {d.towards_project}
                                </span>
                              </div>
                              <div className="project-tag">
                                <span class="badge bg-secondary">
                                  {get_date(d.date_expire)}
                                </span>
                              </div>
                              <div className="project-tag">
                                {d.status ? (
                                  <span class="badge bg-success">Done</span>
                                ) : new Date(d.date_expire).getTime() >
                                  Date.now() ? (
                                  <span class="badge bg-info text-dark">
                                    Idle
                                  </span>
                                ) : (
                                  <span class="badge bg-danger">Due</span>
                                )}
                              </div>
                              <div className="project-tag">
                                <span onClick={() => openModal(d, i)}>
                                  <i className="material-icons">delete</i>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              subProject_on === i
                                ? "project-subproject-active list-group list-group-flush"
                                : "project-subproject-block list-group list-group-flush"
                            }
                          >
                            <div className="subproject-adder">
                              <input
                                title="Sub-project"
                                type="text"
                                placeholder="Enter New Sub Project"
                                value={project_name}
                                onChange={(e) =>
                                  setProject_name(e.target.value)
                                }
                              />
                              <button
                                type="button"
                                className="btn-sm btn-outline-success"
                                onClick={() => newSubProject_submit(d)}
                              >
                                Add Sub Project
                              </button>
                              <button onClick={() => setSubProject_on(null)}>
                                close
                              </button>
                            </div>
                            {d.sub_projects ? (
                              d.sub_projects.map((s, si) => (
                                <li className="list-group-item">
                                  <div className="project-info-inline">
                                    <div className="project-name">
                                      <i
                                        class="material-icons"
                                        onClick={() =>
                                          sub_project_checkbox_submit(d, si)
                                        }
                                      >
                                        {s.status
                                          ? "done"
                                          : "check_box_outline_blank"}
                                      </i>
                                      <label className="form-check-label">
                                        {s.sub_project_name}
                                      </label>
                                    </div>
                                    <div className="project-tags">
                                      {/* <div className="project-tag" onClick={()=> setSubProject_on(i)}>
                              <i className="material-icons">add</i>
                              </div>
                                      <div className="project-tag">
                                        <span class="badge bg-secondary">
                                          {d.towards_project}
                                        </span>
                                      </div>
                                      <div className="project-tag">
                                        <span class="badge bg-secondary">
                                          {get_date(d.date_expire)}
                                        </span>
                                      </div>
                                      <div className="project-tag">
                                        {d.status ? (
                                          <span class="badge bg-success">
                                            Done
                                          </span>
                                        ) : new Date(d.date_expire).getTime() >
                                          Date.now() ? (
                                          <span class="badge bg-info text-dark">
                                            Idle
                                          </span>
                                        ) : (
                                          <span class="badge bg-danger">
                                            Due
                                          </span>
                                        )}
                                      </div> */}
                                      <div className="project-tag">
                                        <span
                                          onClick={() => Del_SubProject(d, si)}
                                        >
                                          <i className="material-icons">
                                            delete
                                          </i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <li className="list-group-item">
                                No sub-Projects -_-
                              </li>
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">No Data Found -_-</li>
                  )}
                </ul>
              </ul>
              {delModel ? (
                <Modal
                  handleClose={() => closeOpenModal()}
                  handleConfirm={() => project_delete()}
                >
                  <div style={{ display: "inline" }}>
                    <h5>Do you Want To Delete </h5>{" "}
                    <h3>
                      <b>{currDel}</b>
                    </h3>{" "}
                    <h5>Project ?</h5>{" "}
                  </div>
                </Modal>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
