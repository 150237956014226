import React from "react";
import "./css/toggle_switch.css";

export const ToggleText = ({
  text = "null",
  value = false,
  onclick = null,
}) => {
  //   const click = () => {
  //     onclick();
  //   };
  return (
    <>
      <button
        type="button"
        onClick={() => onclick()}
        class={`text-md mx-0.5 my-0.5 rounded-md px-2 font-normal ${
          value ? "dark:bg-gray-600 dark:text-white" : "bg-white text-black"
        }`}
      >
        {text}
      </button>
    </>
  );
};

export const ToggleButton = ({
  state = false,
  text = "ToggleBTN",
  onChange= null,
  children = null,
}) => {
  return (
    <>
      <div id="toggle-btn" class="form-check form-switch w-fit">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          checked={state}
          onChange={onChange}
        />
        <label class="ml-[-0.4em] pr-1">{text}</label>
      </div>
      {children}
    </>
  );
};
