import { UserInfo } from "../../interface";
export const IsLoggedReducer = (state = false, action: any) => {
  // console.log("Reducer >>  Logged_in ");
  switch (action.type) {
    case "SIGN_IN":
      return true;
    case "SIGN_OUT":
      return false;
    default:
      return state;
  }
};

export const userInfoReducer = (state: UserInfo | null = null, action: any) => {
  // console.log("Reducer >>  User_Info ");
  switch (action.type) {
    case "USER_LOGIN_INFO":
      return action.payload;
    case "SIGN_OUT":
      return null;
    default:
      return state;
  }
};
