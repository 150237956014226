import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { vinanti } from "../../data/data.js";

import { useSelector } from "react-redux";
import { Task } from "./task.tsx";
import {
  getDayNum,
  getWeek,
  get_date,
} from "../../components/utils/general_utils.js";
import { TaskAdder } from "./task_adder.tsx";
import { Button } from "react-utility-yard";
import { TaskSchema, TypePayloadAction } from "../../type";
import { RootState, useAppDispatch } from "../../store/store.ts";
import { tasks_update_list } from "../../store/actions/actionTypes.ts";
// import { Planby } from "./planner/planby.tsx";

const default_times = { All: 0, Due: 0, Today: 0, Tomorrow: 0 };
type contentState = "All" | "Today" | "Due" | "Tomorrow";

type TasksProps = {
  view: "normal" | "mini";
  taskAdderOn?: boolean;
};

export const Tasks = ({ view = "normal", taskAdderOn = true }: TasksProps) => {
  const [content_state, setContent_state] = useState<contentState>("All");

  const [curr_list, setCurr_list] = useState<TaskSchema[] | null>(null);
  const [curr_cnt, setCurr_cnt] = useState<typeof default_times>(default_times);

  const [live_time, setLive_time] = useState(
    `${get_date(
      Date.now(),
      true,
    )}  ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
  );

  const list = useSelector((state: RootState) => state.tasks.list);

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const get_tasks_List = async () => {
    let data = await vinanti("list", "task", null, {});
    if (data && data.length > 1) {
      let new_data = [
        ...data.sort(
          (a: TaskSchema, b: TaskSchema) => Number(a.status) - Number(b.status),
        ),
      ];
      dispatch_action({ type: tasks_update_list, payload: new_data });
      return new_data;
    }
    return null;
  };

  const filter_list = async (state: contentState) => {
    let data;
    if (list) data = [...list];
    else {
      data = await get_tasks_List();
    }
    if (data) {
      switch (state) {
        case "All":
          data = data.sort((b, a) => Number(b.status) - Number(a.status));
          break;
        case "Today":
          data = data.filter(
            (d) => get_date(d.date_expire) === get_date(Date.now()),
          );
          break;
        case "Tomorrow":
          data = data?.filter(
            (d) =>
              get_date(d.date_expire) ===
              get_date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
          );
          break;
        case "Due":
          data = data?.filter(
            (d) =>
              new Date(d.date_expire) <
                new Date(new Date().getTime() + 24 * 60 * 60 * 1000) &&
              !d.status,
          );
          break;

        default:
          break;
      }
      setCurr_list(data);
      let curr = { ...(curr_cnt ? curr_cnt : default_times) };
      curr[state] = data?.length;
      setCurr_cnt(curr);
    }
    setContent_state(state);
  };

  useEffect(() => {
    if (!list?.length) {
      get_tasks_List();
    }
    filter_list("All");
    const timer = setInterval(() => {
      setLive_time(
        `⌚ ${get_date(
          Date.now(),
          true,
        )}  [ ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()} ] `,
      );
    }, 1 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!list?.length) {
      get_tasks_List();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    filter_list(content_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content_state, list]);

  return (
    <>
      <div className="h-full w-full rounded-xl p-2">
        <div className="mx-auto h-full max-w-[800px]">
          <div className="card h-full rounded-lg text-center">
            <div className="card-header gap-1">
              <div>{`${live_time} 
                     [week ${getWeek()}] [day ${getDayNum()}]
                     `}</div>
              <ul className="nav nav-tabs card-header-tabs">
                <li
                  key={"tab-all"}
                  className="nav-item"
                  onClick={() => {
                    filter_list("All");
                  }}
                >
                  <div
                    className={
                      "nav-link " + (content_state === "All" ? "active" : "")
                    }
                    aria-current="true"
                  >
                    {`All (${curr_cnt?.["All"]})`}
                  </div>
                </li>
                <li
                  key={"tab-today"}
                  className="nav-item"
                  onClick={() => {
                    filter_list("Today");
                  }}
                >
                  <div
                    className={
                      "nav-link " + (content_state === "Today" ? "active" : "")
                    }
                    aria-current="true"
                  >
                    {`Today (${curr_cnt?.["Today"]})`}
                  </div>
                </li>
                <li
                  key={"tab-due"}
                  className="nav-item"
                  onClick={() => {
                    filter_list("Due");
                  }}
                >
                  <div
                    className={
                      "nav-link " + (content_state === "Due" ? "active" : "")
                    }
                    aria-current="true"
                  >
                    {`Due (${curr_cnt?.["Due"]})`}
                  </div>
                </li>
                <li
                  key={"tab-Tomorrow"}
                  className="nav-item"
                  onClick={() => {
                    filter_list("Tomorrow");
                  }}
                >
                  <div
                    className={
                      "nav-link " +
                      (content_state === "Tomorrow" ? "active" : "")
                    }
                    aria-current="true"
                  >
                    {`Tomorrow (${curr_cnt?.["Tomorrow"]})`}
                  </div>
                </li>
              </ul>
            </div>
            <div className="card-body h-full gap-1 overflow-y-scroll ">
              {taskAdderOn && <TaskAdder />}
              <Button text="Get List" onclick={get_tasks_List} />
              <br />
              <div className=" h-full overflow-y-scroll">
                <ul className="mx-1 flex flex-col gap-1">
                  {curr_list ? (
                    curr_list.map((d, i) => <Task task={d} view={view} />)
                  ) : (
                    <li className="my-1">No Tasks Found.</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
