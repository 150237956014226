import React, { useEffect, useState } from "react";
import { SideBar } from "react-utility-yard";
import App from "./src";
import { RitualList } from "./ritual_list";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data";
import { RootState } from "../../store/store";

const def_ritual = {
  ritual_name: "Demo",
  defination: `root {
    action [New-Ritual-Created]
}`,
  agent: "class Agent {}",
};
export const RitualIo = () => {
  const [ritual, setRitual] = useState(def_ritual);
  const curr_ritual = useSelector(
    (state: RootState) => state.ritual_curr_active,
  );
  const user_info = useSelector((state: RootState) => state.user_info);

  const updateRitual = async (data: object) => {
    await vinanti("update", "ritual", null, {
      email: user_info.user_email,
      ritual: data,
    });
  };

  useEffect(() => {
    setRitual(curr_ritual);
    // console.log("global_Story changed");
  }, [curr_ritual, ritual]);

  return (
    <>
      <div className="flex h-full max-h-full flex-row justify-between">
        <SideBar
          sideBar_list={<RitualList />}
          leftside={true}
          title={"Rituals"}
          width={"w-[18rem]"}
        >
          <App
            ritual={ritual ? { ...ritual } : def_ritual}
            onSave={updateRitual}
          />
        </SideBar>
      </div>
    </>
  );
};
