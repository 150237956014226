import React, { useState } from "react";
import { useEffect } from "react";
// import "./css/linkio.css";
import { vinanti } from "../../data/data";

import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components/utils/modal";
import { updateLinksList } from "../../store/actions";

import { Link } from "./link";
import { LinkAdder } from "./link_adder";
import { get_date } from "../../components/utils/general_utils";
import Input from "../../components/utils/ts/input";
import { Button } from "react-utility-yard";

export function LinkIo() {
  const [search_link_name, setSearch_link_name] = useState("");
  const [search_results, setSearch_results] = useState([]);

  var list = useSelector((state) => state.links.list);
  const user_info = useSelector((state) => state.user_info);

  const [delModel, setDelModel] = useState(false);
  const [currDel, setCurrDel] = useState(null);
  const dispatch = useDispatch();

  const get_problems_List = async () => {
    // var data = await linksList(user_info.auth_token, user_info.user_email);
    var data = await vinanti("list", "link", user_info.auth_token, {
      email: user_info.user_email,
    });
    data.map((d, i) => {
      d.glink
        ? (data[i]["glinks_sorted"] = search_links_locally(
            d.glinks,
            (data = data),
          ))
        : console.log("no links found to update");
    });
    // console.log("Links List ", data);
    dispatch(updateLinksList(data));
  };

  const search_links_online = async () => {
    var data = await vinanti("search", "link", null, {
      search: search_link_name,
    });
    data = data ? data : [];
    data.map((d, i) => {
      d.glink
        ? (data[i]["glinks_sorted"] = search_links_locally(
            d.glinks,
            (data = data),
          ))
        : console.log("no links found to update");
    });
    setSearch_results(data);
  };

  const search_links_locally = (links, data = undefined) => {
    var res = [];
    var list_local;
    data ? (list_local = data) : (list_local = list);
    links
      ? links.map((d, i) => {
          const ind = list_local.find((l) => l._id === d);
          res.push(ind);
        })
      : console.log("empty Glink found");
    // console.log("search_links_locally", res);
    return res;
  };

  const linkIo_delete = async () => {
    // console.log(currDel, " topic sent for delete Final Operation.");
    setDelModel(false);
    await vinanti("del", "link", user_info.auth_token, {
      user: user_info.user_email,
      link_id: currDel,
    });
    // await deleteLink(currDel, user_info.auth_token, user_info.user_email);
    setCurrDel(null);
    get_problems_List();
  };
  const openModal = (id, name) => {
    // console.log(name, " wants to get deleted.");
    setDelModel(true);
    setCurrDel(id);
  };
  const closeOpenModal = () => {
    console.log("modal closed");
    setDelModel(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="h-full">
        <div class="flex h-full w-full flex-col gap-2 p-3 lg:flex-row">
          <div class="flex w-full flex-col items-center gap-2 lg:w-[50]">
            <div className="flex items-center gap-1">
              <Input
                editOn={true}
                title="Search Link here..."
                type="text"
                placeholder="Search Link here"
                value={search_link_name}
                onchange={(e) => setSearch_link_name(e)}
                onkeydown={(e) => {
                  if (e.key === "Enter") {
                    search_links_online();
                  }
                }}
              />
              <Button
                className="btn-sm btn-outline-success"
                onclick={() => search_links_online()}
                text="Search Link"
              />
            </div>
            <div className="flex items-center gap-1">
              <h1 className="rounded-md bg-white px-2">
                No. of Links: {list ? list.length : 0}
              </h1>
              <Button
                type="button"
                className="btn-sm btn-outline-success"
                onClick={() => get_problems_List()}
                text="Get Links"
              />
            </div>
            <div class="mt-3 w-full overflow-y-scroll p-1">
              {search_results?.length ? (
                <div className="flex flex-col">
                  <div className="my-1 flex items-center gap-2">
                    <h4 className="m-1 w-fit rounded-md bg-gray-500 p-1 text-white">
                      {search_results.length} Search Results Found
                    </h4>
                    <Button
                      text={"Clear"}
                      onclick={() => setSearch_results([])}
                    />
                  </div>
                  <ul class=" flex flex-col gap-1 bg-gray-400">
                    {search_results.map((d, i) => (
                      <>
                        <Link
                          d={d}
                          i={i}
                          delete_model={openModal}
                          desc_on={true}
                        />
                      </>
                    ))}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              <h4 className="m-1 w-fit rounded-md bg-gray-500 p-1 text-white">
                {list.length} Links Available Locally
              </h4>
              <ul class=" mt-2 flex flex-col gap-1">
                {list ? (
                  list.map((d, i) => (
                    <Link
                      d={d}
                      i={i}
                      delete_model={() => openModal(d._id, d.link_name)}
                      desc_on={true}
                    />
                  ))
                ) : (
                  <li className="flex items-center justify-center rounded-md bg-white py-1">
                    No Data Found -_-
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div class="flex h-full w-full flex-col justify-between lg:w-[50]">
            <div className="flex flex-col">
              <div class="mt-3">
                <ul class="">
                  {list ? (
                    list
                      .filter(
                        (d) =>
                          get_date(d.date_created, true) ===
                          get_date(Date.now(), true),
                      )
                      .map((d, i) => (
                        <Link
                          d={d}
                          i={i}
                          delete_model={() => openModal(d._id, d.link_name)}
                          desc_on={true}
                          width={"100%"}
                        />
                      ))
                  ) : (
                    <li className="flex justify-center rounded-md bg-white py-1">
                      No Data Found -_-
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="">
              <LinkAdder />
            </div>
          </div>
        </div>

        {delModel && (
          <Modal
            handleClose={() => closeOpenModal()}
            handleConfirm={() => linkIo_delete()}
          >
            <div style={{ display: "inline" }}>
              <h5>Do you Want To Delete </h5>{" "}
              <h3>
                <b>{currDel}</b>
              </h3>{" "}
              <h5>Problem ?</h5>{" "}
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
