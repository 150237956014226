/**
 ** param {String} width - width defaut value 100px
 @param {*} onclick 
 */
export function MaterialIcon({
  onclick = () => null,
  icon = "duck",
  cursor = "normal",
  size = 24,
}) {
  return (
    <span
      onClick={onclick}
      className="p-0"
      style={{
        marginLeft: "0.25rem",
        // marginTop:"0.15rem",
        cursor: cursor,
        display: "content",
        fontSize: size,
      }}
    >
      <i
        className="material-icons p-0 m-0"
        style={{
          fontVariationSettings: `'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' ${size}`,
        }}
      >
        {icon}
      </i>
    </span>
  );
}
