import React, { useState } from "react";
import { vinanti } from "../../../data/data";
import { Button, SelectOptions } from "react-utility-yard";
import CloudImageAdder from "./image_adder";
import copy from "./../../../data/images/img-copy.png";

type Props = {};

const GallaryApp = (props: Props) => {
  const [gallary, setGallary] = useState<any[] | null>(null);

  const get_gallary = async () => {
    const res = await vinanti("list", "storage", null, {});
    if (res) {
      console.log(res);

      setGallary(res);
    }
  };
  return (
    <div className="px-10 flex h-full w-full flex-col items-center gap-2">
      <h1 className="text-4xl font-bold text-white">Gallary</h1>
      <Button text="Get Files" onclick={get_gallary} />
      <CloudImageAdder />
      <div className="flex flex-wrap items-center gap-1 rounded-md bg-white/50 p-1 backdrop-blur-md">
        {/* <SelectOptions options={["profi"]} */}
      </div>

      <div className="flex w-full ">
        <h1 className="text-xl font-bold text-white">Images</h1>
      </div>
      <div className="flex max-h-full w-full flex-wrap gap-0 overflow-y-scroll">
        {gallary &&
          gallary?.map((m) => (
            <div className="group relative flex gap-2">
              {/* <div> {m.name} </div> */}
              {m.metadata.contentType?.split("/")[0] === "image" && (
                <>
                  <div className="h-36 w-36 bg-black/20 backdrop-blur-sm flex justify-center items-center">
                    <span className="absolute bottom-0 left-0 z-20 hidden cursor-pointer rounded-sm bg-white px-1 group-hover:flex">
                      {m.name?.split("/").slice(-1)}
                    </span>
                    <img
                      className="absolute right-0 top-0 hidden h-7 cursor-pointer group-hover:block"
                      src={copy}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          "https://storage.cloud.google.com/" +
                            m.metadata.bucket +
                            "/" +
                            m.name,
                        )
                      }
                    />
                    <img
                      className="object-cover object-center"
                      src={
                        "https://storage.cloud.google.com/" +
                        m.metadata.bucket +
                        "/" +
                        m.name
                      }
                      alt={m.name}
                      aria-label={m.name}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default GallaryApp;
