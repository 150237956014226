import "./css/rein_logo.css";

export const ReinLogo = () => {
  return (
    <div className="e213_485">
      <link
        href="https://fonts.googleapis.com/css?family=DM+Mono&display=swap"
        rel="stylesheet"
      ></link>
      <div className="ei213_485_213_484">
        <p className="ei213_485_213_482 mix-blend-difference">Rein</p>
      </div>
    </div>
  );
};
