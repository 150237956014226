import React, { useState } from "react";
import { vinanti } from "../../data/data";
import { useDispatch, useSelector } from "react-redux";
import { updateUsersList } from "../../store/actions";
import { Button } from "react-utility-yard";

export function Users() {
  const list = useSelector((state) => state.users_page_users);
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();

  const get_users_List = async () => {
    setBusy(true);
    var data = await vinanti("list", "users");
    setBusy(false);
    dispatch(updateUsersList(data));
  };

  const get_date = (date) => {
    var data = new Date(date);
    var options = { weekday: "short", month: "short", day: "numeric" };
    return data.toLocaleDateString("en-US", options);
  };

  return (
    <>
      <div className="">
        <div className="m-1 flex h-10 items-center rounded-md bg-white px-10">
          <Button onClick={() => get_users_List()} text="Get Users"></Button>
        </div>
        <br />
        <div className="flex flex-col gap-1">
          <div className="bg-white p-2">Users</div>
          <div className="flex h-auto w-full flex-wrap gap-2 overflow-scroll p-2">
            {list ? (
              list.map((d, i) => (
                <li className="rounded-md bg-white p-4 w-96">
                  <div class="">
                    <div class="flex gap-2">
                      <div className="h-36 w-36">
                        <img src={d.img_url} class="card-img" alt="..." />
                      </div>
                      <div class="">
                        <div class="">
                          <h5 class="">{d.name}</h5>
                          <div className="">{d.google_id}</div>
                          <div className="">{d.email}</div>
                          <div className="">{get_date(d.date)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-group-item">No Data Found -_-</li>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
