import React, { useState, FC, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data.js";
import { RootState, useAppDispatch } from "../../store/store.ts";
import { TypePayloadAction } from "../../type";
import { streaks_update_raw_list } from "../../store/actions/actionTypes.ts";
import { StreakLogger, streakSchema } from "./src/streak_logger.tsx";
import { Input, Loading, MaterialIcon } from "react-utility-yard";

interface prop {
  //   list_name?: string;
  //   id?: string;
}

const date_today = () => {
  var data = new Date();
  var options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return data.toLocaleDateString("en-US", options);
};

const _streak = {
  _id: "",
  activity_name: "Activity Name",
  frequency: "0 0 * * *",
  activity_desc: "Do da Fuck you want!",
  last_date: new Date().toISOString(),
  due: new Date().toISOString(),
  streak: 0,
  highest_streak: 0,
};
const default_state = {
  loading: false,
  new_edit_on: false,
  streaks_edit_on: false,
};
export const StreakLoggerWrapper: FC<prop> = () => {
  const _streaks = useSelector((state: RootState) => state.streaks.list);

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );
  const [streaks, setStreaks] = useState<streakSchema[]>(_streaks || []);
  const [streak, setStreak] = useState<streakSchema>(_streak);
  const [state, setState] = useState<typeof default_state>(default_state);

  const get_streaks = async () => {
    setState({ ...state, loading: true });
    let data: any = await vinanti("list", "streak", null, {});

    if (data) {
      setStreaks(data);
      dispatch_action({ type: streaks_update_raw_list, payload: data });
    }
    setState({ ...state, loading: false });
  };

  const new_streak = async () => {
    setState({ ...state, loading: true });
    let data: any = await vinanti("new", "streak", null, {
      streak,
    });
    if (data) {
      get_streaks();
      setStreak(_streak);
      setState({ ...state, new_edit_on: false });
    }
    setState({ ...state, loading: false });
  };
  const update_streakUp = async (streak_id: string) => {
    let res: any = await vinanti("streakUp", "streak", null, {
      streak_id,
    });
    if (res) {
      get_streaks();
    }
  };
  const update_streak = async (streak: streakSchema) => {
    let res: any = await vinanti("update", "streak", null, {
      streak,
    });
    if (res) {
      get_streaks();
    }
  };
  const delete_streak = async (streak_id: string) => {
    let res: any = await vinanti("del", "streak", null, {
      streak_id,
    });
    if (res) {
      get_streaks();
    }
  };

  useEffect(() => {
    get_streaks();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" min-h-auto z z-50 max-h-[350px] rounded-lg bg-black bg-opacity-25 p-2 text-white">
      <div className="flex flex-col gap-1">
        <div className="mt-1 flex items-center gap-2">
          <div> {date_today()} </div>
          <Loading state={state.loading} size="md" />
          <MaterialIcon
            onClick={get_streaks}
            icon="download"
            cursor="pointer"
          />
          <MaterialIcon
            onClick={() =>
              setState({ ...state, new_edit_on: !state.new_edit_on })
            }
            icon={state.new_edit_on ? "cancel" : "add"}
            cursor="pointer"
          />
          <MaterialIcon
            onClick={() =>
              setState({
                ...state,
                streaks_edit_on: !state.streaks_edit_on,
                new_edit_on: false,
              })
            }
            icon={state.streaks_edit_on ? "cancel" : "edit"}
            cursor="pointer"
          />
        </div>
        {state.new_edit_on && (
          <div className="flex flex-wrap gap-2">
            <Input
              className="text-black"
              editOn={true}
              placeholder="Activity Name"
              value={streak.activity_name}
              onchange={(e: any) => setStreak({ ...streak, activity_name: e })}
            />
            <Input
              className="text-black"
              editOn={true}
              placeholder="Activity Description"
              value={streak.activity_desc}
              onchange={(e: any) => setStreak({ ...streak, activity_desc: e })}
            />
            <Input
              className="text-black"
              editOn={true}
              placeholder="Frequency"
              value={streak.frequency}
              onchange={(e: any) => setStreak({ ...streak, frequency: e })}
            />
            <Input
              type="datetime-local"
              className="text-black"
              editOn={true}
              placeholder="Due Date Time"
              value={streak.due}
              onchange={(e: any) => setStreak({ ...streak, due: e })}
            />
            <MaterialIcon onClick={new_streak} icon="save" cursor="pointer" />
          </div>
        )}
      </div>
      <div className="flex flex-col p-2">
        {Array.isArray(streaks) &&
          streaks?.map((m) => (
            <StreakLogger
              delete_streak={delete_streak}
              update_streak={update_streak}
              edit_btn={state.streaks_edit_on}
              streak={m}
              streakUp={update_streakUp}
            />
          ))}
      </div>
    </div>
  );
};
