import React from "react";
import TimeLine from "./timeline";

type Props = {};

export function TimelineWrapper({}: Props) {
  return (
    <div>
      <TimeLine />
    </div>
  );
}
  