import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { SubTaskSchema, TaskSchema, TypePayloadAction } from "../../type";
// import { MaterialIcon } from "../../utils/material_icon";
import { Loading } from "../../components/utils/loading";
import { Tag } from "../../components/utils/tag";
import { Button, Input, MaterialIcon } from "react-utility-yard";
import { timeToNumber, timeToString } from "./task_helper";
import { get_date } from "../../components/utils/general_utils";
import { vinanti } from "../../data/data";
import { useAppDispatch } from "../../store/store";
import {
  tasks_delete_task_from_list,
  tasks_update_task_to_list,
} from "../../store/actions/actionTypes";
import { Modal } from "../../components/utils/modal";
import moment from "moment";
import { List, arrayMove, arrayRemove } from "react-movable";

type Props = {
  task: TaskSchema;
  view?: "normal" | "mini";
  canDelete?: boolean;
};
const defaultTask: TaskSchema = {
  task_name: "Dummy Task",
  task_length: 600,
  date_scheduled: "",
  date_expire: "",
  towards_project: "",
  sub_tasks: [],
  recc_tasks: [],
  status: false,
  task_details: "",
};

export const HandleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#555"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-move"
  >
    <polyline points="5 9 2 12 5 15" />
    <polyline points="9 5 12 2 15 5" />
    <polyline points="15 19 12 22 9 19" />
    <polyline points="19 9 22 12 19 15" />
    <line x1="2" y1="12" x2="22" y2="12" />
    <line x1="12" y1="2" x2="12" y2="22" />
  </svg>
);

export const Task = ({
  task = defaultTask,
  view = "normal",
  canDelete = true,
}: Props) => {
  // task destucture
  const [Task, setTask] = useState(task);

  const {
    task_name,
    task_length,
    date_scheduled,
    date_expire,
    towards_project,
    sub_tasks,
    recc_tasks,
    status,
    // task_details,
  } = Task;

  const [task_checking, setTask_checking] = useState(false);
  const [editOn, setEditOn] = useState(false);
  const [subTaskOn, setSubTaskOn] = useState(false);
  const [modelOn, setModelOn] = useState(false);
  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );
  // task update
  const update_task = async (task = Task) => {
    setTask_checking(true);
    let data = await vinanti("update", "task", null, {
      task,
    });
    if (data) {
      update_local_list();
      setEditOn(false);
    }

    setTask_checking(false);
  };
  const delete_task = async () => {
    setTask_checking(true);
    let data = await vinanti("del", "task", null, {
      task_id: Task._id,
    });
    if (data) {
      dispatch_action({ type: tasks_delete_task_from_list, payload: Task });
      setTask_checking(false);
      setModelOn(false);
    }
  };
  // sub task update
  const update_local_list = (task: TaskSchema | null = null) => {
    dispatch_action({
      type: tasks_update_task_to_list,
      payload: task ? task : Task,
    });
  };
  // sub task delete
  const del_sub_task = async (sb: SubTaskSchema) => {
    setTask_checking(true);
    let data = await vinanti("delSubTask", "task", null, {
      task_id: Task._id,
      sub_task_name: sb.sub_task_name,
    });
    if (data) {
      let idx = Task.sub_tasks.findIndex(
        (x) => x.sub_task_name === sb.sub_task_name,
      );
      let new_subs = [...Task.sub_tasks];
      new_subs.splice(idx, 1);
      let task = { ...Task, sub_tasks: [...new_subs] };
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };

  const toggle_sub_task_status = async (sb: SubTaskSchema) => {
    setTask_checking(true);
    let new_subs = [...Task.sub_tasks];
    new_subs = new_subs.map((m) =>
      m.sub_task_name === sb.sub_task_name ? { ...sb, status: !sb.status } : m,
    );
    let task = { ...Task, sub_tasks: [...new_subs] };

    let data = await vinanti("checkSubTask", "task", null, {
      task: task,
    });
    if (data) {
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };
  const toggle_task_status = async () => {
    setTask_checking(true);
    let data = await vinanti("checkTask", "task", null, {
      task_id: Task._id,
    });
    if (data) {
      let task = { ...Task, status: !Task.status };
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };
  const update_sub_tasks_order = async (sbs: SubTaskSchema[]) => {
    setTask_checking(true);
    // Just update the task
    let task = { ...Task, sub_tasks: [...sbs] };
    setTask(task);
    update_task(task);

    setTask_checking(false);
  };
  const add_new_subTask = async (sb: SubTaskSchema) => {
    setTask_checking(true);
    const data = await vinanti("newSubTask", "task", null, {
      task_id: task._id,
      sub_task_name: sb,
    });
    if (data) {
      let task = { ...Task, sub_tasks: [...Task.sub_tasks, data.new_sub] };
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };

  const add_new_reccTask = async (rt: string) => {
    setTask_checking(true);
    const data = await vinanti("newReccTask", "task", null, {
      task_id: task._id,
      recc_task_id: rt,
    });
    if (data) {
      let task = { ...Task, recc_tasks: [...Task.recc_tasks, rt] };
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };

  const del_recc_task = async (rt: string) => {
    setTask_checking(true);
    let data = await vinanti("delReccTask", "task", null, {
      task_id: Task._id,
      recc_task_id: rt,
    });
    if (data) {
      let idx = Task.recc_tasks.findIndex((x) => x === rt);
      let new_subs = [...Task.recc_tasks];
      new_subs.splice(idx, 1);
      let task = { ...Task, recc_tasks: [...new_subs] };
      setTask(task);
      update_local_list(task);
    }
    setTask_checking(false);
  };

  useEffect(() => {
    setTask(task);
    return () => {};
  }, [task]);

  return (
    <div className="flex flex-col justify-center gap-2 rounded-[4px] border-0 border-black bg-slate-200 p-[0.125rem]">
      <div className="flex flex-col justify-between md:flex-row">
        <div className="float-left flex items-center">
          {task_checking ? (
            <Loading size_num={25} />
          ) : (
            <MaterialIcon
              icon={status ? "done" : "check_box_outline_blank"}
              onClick={() => toggle_task_status()}
            />
          )}
          <div
            className={`flex h-6 overflow-hidden`}
            onClick={() => (editOn ? null : setSubTaskOn(!subTaskOn))}
          >
            <Input
              className={`bg-slate-200 ${!editOn && "cursor-pointer"}`}
              placeholder={"Task Name"}
              editOn={editOn}
              value={task_name}
              onchange={(e: any) => setTask({ ...Task, task_name: e })}
            />
          </div>
        </div>
        {view !== "mini" && (
          <div className="float-right flex justify-end">
            <div className="flex flex-wrap items-center justify-center ">
              {!editOn ? (
                <Tag
                  text={towards_project}
                  color="bg-secondary"
                  // input_type="text"
                  // isEdit={edit_on}
                />
              ) : (
                <></>
              )}
              <Tag
                text={timeToString(task_length)}
                value={timeToString(task_length) + ":00"}
                color="bg-secondary"
                input_type="time"
                isEdit={editOn}
                onChange={(value: any) =>
                  setTask({ ...Task, task_length: timeToNumber(value) })
                }
              />
              <Tag
                text={get_date(date_scheduled)}
                value={moment(date_scheduled).format("YYYY-MM-DDTHH:mm")}
                input_type="datetime-local"
                isEdit={editOn}
                color={
                  status
                    ? "bg-success"
                    : new Date(date_expire).getTime() > Date.now()
                      ? "bg-info text-dark"
                      : "bg-danger"
                }
                onChange={(e: any) => setTask({ ...Task, date_scheduled: e })}
              />
              <Tag
                text={get_date(date_expire)}
                value={moment(date_expire).format("YYYY-MM-DDTHH:mm")}
                input_type="datetime-local"
                isEdit={editOn}
                color={
                  status
                    ? "bg-success"
                    : new Date(date_expire).getTime() > Date.now()
                      ? "bg-info text-dark"
                      : "bg-danger"
                }
                onChange={(value: any) =>
                  setTask({ ...Task, date_expire: value })
                }
              />
              <MaterialIcon
                cursor="pointer"
                onClick={() => navigator.clipboard.writeText(task?._id || "")}
                icon="link"
              />
              {editOn ? (
                <>
                  {task_checking ? (
                    <Loading size_num={25} />
                  ) : (
                    <>
                      <MaterialIcon icon="save" onClick={() => update_task()} />
                    </>
                  )}
                  <MaterialIcon
                    icon="cancel"
                    onClick={() => setEditOn(false)}
                  />
                </>
              ) : (
                <MaterialIcon icon="edit" onClick={() => setEditOn(true)} />
              )}
              {canDelete ? (
                <>
                  {task_checking ? (
                    <Loading size_num={25} />
                  ) : (
                    <MaterialIcon
                      icon="delete"
                      cursor={"pointer"}
                      onClick={() => setModelOn(true)}
                    />
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      {subTaskOn ? (
        <>
          {sub_tasks && sub_tasks.length > 0 ? (
            <List
              values={sub_tasks}
              removableByMove={false}
              onChange={({ oldIndex, newIndex }) => {
                if (newIndex === -1) {
                  update_sub_tasks_order([...arrayRemove(sub_tasks, oldIndex)]);
                } else
                  update_sub_tasks_order([
                    ...arrayMove(sub_tasks, oldIndex, newIndex),
                  ]);
              }}
              renderList={({ children, props }) => (
                <div {...props} className="ml-6 flex flex-col gap-1">
                  {children}
                </div>
              )}
              renderItem={({ value, props }) => (
                // rendering issue is there for adding the links mostly relatd to useeffect
                <li className="flex w-full px-1" {...props}>
                  <div className="w-full px-1">
                    <SubTask
                      key={`sub-task-${value.sub_task_name}`}
                      sub_task={value}
                      toggle_checkbox={() => toggle_sub_task_status(value)}
                      del_sub_task={() => del_sub_task(value)}
                      loading={task_checking}
                      view={view}
                    />
                  </div>
                  <button data-movable-handle className="cursor-grab">
                    <MaterialIcon icon="swap_vert" />
                  </button>
                </li>
              )}
            />
          ) : (
            <li className="m-1">No sub-Tasks -_-</li>
          )}
          {recc_tasks?.length ? (
            <List
              values={recc_tasks}
              removableByMove={false}
              onChange={({ oldIndex, newIndex }) => {
                if (newIndex === -1) {
                  update_sub_tasks_order([...arrayRemove(sub_tasks, oldIndex)]);
                } else
                  update_sub_tasks_order([
                    ...arrayMove(sub_tasks, oldIndex, newIndex),
                  ]);
              }}
              renderList={({ children, props }) => (
                <div {...props} className="ml-6 flex flex-col gap-1">
                  {children}
                </div>
              )}
              renderItem={({ value, props }) => (
                // rendering issue is there for adding the links mostly relatd to useeffect
                <li className="flex w-full px-1" {...props}>
                  <div className="flex w-full items-center gap-3 px-1 ">
                    <TaskById task_id={value} />
                    {task_checking ? (
                      <Loading size_num={25} />
                    ) : (
                      <MaterialIcon
                        icon="delete"
                        cursor={"pointer"}
                        onClick={() => del_recc_task(value)}
                      />
                    )}
                  </div>
                  <button data-movable-handle className="cursor-grab">
                    <MaterialIcon icon="swap_vert" />
                  </button>
                </li>
              )}
            />
          ) : (
            <li className="m-1">No Recc-Tasks -_-</li>
          )}

          <li className="m-1 flex list-none gap-2 pl-6">
            <SubTaskAdder onSubmit={add_new_subTask} />
            <Button
              text="Attach Recc-Task"
              onclick={async () => {
                const text = await navigator.clipboard.readText();
                add_new_reccTask(text);
              }}
            />
          </li>
        </>
      ) : (
        <></>
      )}
      {modelOn && (
        <Modal
          handleClose={() => setModelOn(false)}
          handleConfirm={() => delete_task()}
        >
          <div className="inline">
            <h5>Do you Want To Delete </h5>{" "}
            <h3>
              <b>{task_name}</b>
            </h3>
            <h5>Task ?</h5>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const TaskById = ({ task_id }: { task_id: string }) => {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<TaskSchema | null>(null);

  const get_task = async (id: string) => {
    setLoading(true);
    if (id.trim() !== "") {
      let data = await vinanti("list_limited", "task", null, {
        task_id: id,
      });
      console.log(data);
      if (data?.length) {
        console.log(data);
        setTask(data[0]);
      } else {
        setTask(null);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    get_task(task_id);
    return () => {};
  }, [task_id]);

  return (
    <>
      {task ? (
        <Task task={task} canDelete={false} />
      ) : loading ? (
        <> getting task... </>
      ) : (
        <> No task FOund for {task_id}</>
      )}
    </>
  );
};

type SubTaskAdderProps = {
  onSubmit: any;
};

export const SubTaskAdder = ({ onSubmit }: SubTaskAdderProps) => {
  const [sub_task, setSub_task] = useState("");
  const submit_sb = (sb: string) => {
    onSubmit(sb);
    setSub_task("");
  };
  // Sub task Adder
  return (
    <div className="mb-2 flex flex-wrap gap-2">
      <Input
        editOn={true}
        size="sm"
        type="text"
        placeholder="Enter New Sub Task"
        value={sub_task}
        onchange={(value: any) => setSub_task(value)}
        onkeyDown={(e: KeyboardEvent) => {
          if (e.key === "Enter" && sub_task !== "") {
            submit_sb(sub_task);
          }
        }}
      />
      <Button
        text="Add Sub Task"
        onclick={() => submit_sb(sub_task)}
        // loading={new_sub_task_loading}
      />
    </div>
  );
};

type SubTaskProps = {
  sub_task: SubTaskSchema;
  toggle_checkbox?: any;
  del_sub_task?: any;
  loading?: boolean;
  view?: "normal" | "mini";
};

const DefaultSubTask = {
  sub_task: { sub_task_name: "Sub Task 1", status: true },
  toggle_checkbox: null,
  del_sub_task: null,
  loading: false,
};

export const SubTask = ({
  sub_task,
  toggle_checkbox,
  del_sub_task,
  loading,
  view = "normal",
}: SubTaskProps = DefaultSubTask) => {
  const [{ sub_task_name, status }, setSubtask] = useState(sub_task);

  useEffect(() => {
    setSubtask(sub_task);
    return () => {
      // second;
    };
  }, [sub_task]);

  return (
    <div className="mx-1 rounded-md border-0 border-black bg-slate-100 p-1">
      <div className="flex items-center justify-between">
        <div className="float-left flex items-center justify-center">
          {loading ? (
            <Loading size_num={25} />
          ) : (
            <MaterialIcon
              icon={status ? "done" : "check_box_outline_blank"}
              onClick={() => toggle_checkbox()}
            />
          )}
          <div className="flex flex-wrap justify-start px-1 text-left text-base md:text-base">
            {sub_task_name}
          </div>
        </div>
        {view !== "mini" && (
          <div className="float-right flex">
            <div className="flex items-center">
              {status ? (
                <Tag text="Done" color="bg-success" />
              ) : (
                <Tag text="Due" color="bg-danger" />
              )}
              {loading ? (
                <Loading size_num={20} />
              ) : (
                <MaterialIcon icon="delete" onClick={() => del_sub_task()} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
