import React, { useCallback, useState } from "react";
import { vinanti } from "../../data/data";
import kakashi from "../../data/images/kakashi.png";

import { useAppDispatch } from "../../store/store";
import { TypePayloadAction } from "../../type";
import { profile_update_profile_to_list } from "../../store/actions/actionTypes";
import { Button } from "react-utility-yard";
import Input from "../../components/utils/ts/input";

export function NewProfile() {
  const _profile = {
    profile_pic: "",
    profile_name: "",
    aka_tags: [],
    Contact_links: [], //[ links to social/personal contact]
    MBTI: "----", // (personality desc )
    isFictional: false, // ( Character from anime/movie/liturature)
    tags: [], // (tags defining work / passion / scope ) [ space separated ]
    _v_id: "none", // link to exp info
  };

  const [profile, setProfile] = useState(_profile);
  const { profile_pic, profile_name, aka_tags, MBTI, isFictional, tags } =
    profile;

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const newProfile_submit = async () => {
    let data = await vinanti("new", "profile", null, {
      ...profile,
    });
    if (data?.length) {
      dispatch_action({
        type: profile_update_profile_to_list,
        payload: data[0],
      });
    }

    setProfile(_profile);
  };

  const clipboard_to_img_local = async (func: any) => {
    var permissions = [
      "image/bmp",
      "image/gif",
      "image/png",
      "image/jpeg",
      "image/tiff",
    ];

    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === "text/plain") {
          return func(await navigator.clipboard.readText());
        }
        if (permissions.includes(type)) {
          const blob = await clipboardItem.getType(type);
          if (window.FileReader) {
            let reader = new FileReader();
            reader.onload = () => {
              console.log(reader.result);
              func(reader.result);
              return reader.result;
            };
            reader.onerror = () => {
              func(null);
              return null;
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  return (
    <div className="flex max-h-40 min-h-[160px] w-full flex-row justify-center gap-2">
      {/* Image Container */}
      <div className="max-h-40">
        <img
          className="max-h-40"
          src={profile_pic || kakashi}
          alt="profile new pic"
        />
      </div>
      {/* New Profile adder */}
      <div className="flex flex-col justify-center gap-1">
        <div className="flex flex-row justify-center gap-1">
          <Input
            placeholder="new_profile Pic"
            value={profile_pic}
            onchange={(e: any) => setProfile({ ...profile, profile_pic: e })}
            onDoubleClick={async () =>
              clipboard_to_img_local((e: any) =>
                setProfile({ ...profile, profile_pic: e }),
              )
            }
            editOn={true}
            className="w-[150px]"
          />
          <Input
            placeholder="Enter Profile Name"
            value={profile_name}
            onchange={(e: any) => setProfile({ ...profile, profile_name: e })}
            editOn={true}
            className="w-[350px]"
          />
          <Input
            placeholder="MBTI"
            value={MBTI}
            onchange={(e: any) => setProfile({ ...profile, MBTI: e })}
            editOn={true}
            className="w-[70px]"
          />
        </div>
        <div className="flex flex-row justify-center gap-1">
          <div id="link-type-glink" className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={isFictional}
              onChange={() =>
                setProfile({ ...profile, isFictional: !profile.isFictional })
              }
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              IsFictional
            </label>
          </div>
          <Input
            placeholder="Aka Tags"
            value={aka_tags.join(" ")}
            onchange={(e: any) =>
              setProfile({ ...profile, aka_tags: e.split(",") })
            }
            editOn={true}
            className="w-[300px]"
          />
          <Input
            placeholder="Work passion tags"
            value={tags.join(" ")}
            onchange={(e: any) =>
              setProfile({ ...profile, tags: e.split(",") })
            }
            editOn={true}
            className="w-[300px]"
          />
          <Button text="Create New" onclick={() => newProfile_submit()} />
        </div>
      </div>
    </div>
  );
}
