import { Excalidraw } from "@excalidraw/excalidraw";
import { ExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import {
  AppState,
  ExcalidrawImperativeAPI,
} from "@excalidraw/excalidraw/types/types";
import React, { useMemo, useState } from "react";

type Props = {};
const _elements: object | null = [
  {
    type: "rectangle",
    version: 141,
    versionNonce: 361174001,
    isDeleted: false,
    id: "oDVXy8D6rom3H1-LLH2-f",
    fillStyle: "hachure",
    strokeWidth: 1,
    strokeStyle: "solid",
    roughness: 1,
    opacity: 100,
    angle: 0,
    x: 100.50390625,
    y: 93.67578125,
    strokeColor: "#000000",
    backgroundColor: "transparent",
    width: 186.47265625,
    height: 141.9765625,
    seed: 1968410350,
    groupIds: [],
  },
];

function Exacalidraw({}: Props) {

  // Scene Data contains appState and elements
  const [State, setState] = useState<{
    appState: AppState | null;
    elements: object | null;
  }>({ appState: null, elements: _elements });

  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI>();

  
  
  const Excal = useMemo(() => {
    return (
      <Excalidraw
        initialData={{
          elements: _elements as ExcalidrawElement[],
          // appState: State.appState,
        }}
        excalidrawAPI={(api) => setExcalidrawAPI(api)}
        onChange={() => {
          const els = excalidrawAPI?.getSceneElements();
          if (els) setState({ ...State, elements: els });
        }}
      />
    );
  }, [excalidrawAPI]);

  return <div className="h-full w-full">{Excal}</div>;
}

export default Exacalidraw;
