import * as React from "react";
import Box from "@mui/material/Box";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xData = [
  new Date(2022, 5, 1),
  new Date(2022, 5, 2),
  new Date(2022, 5, 5),
  new Date(2022, 5, 6),
  new Date(2022, 5, 7),
  new Date(2022, 5, 8),
  new Date(2022, 5, 11),
];

type SparkLineChartType = {
  height?: number;
  width?: number;
  yAxisData?: any[];
  xAxisData?: any[];
  dots?: boolean;
  xAxisDataValueFormatter?: any;
  xAxisScaleType?:
    | "time"
    | "linear"
    | "band"
    | "point"
    | "log"
    | "pow"
    | "sqrt"
    | "utc"
    | undefined;
};

export default function SparkLine({
  height = 300,
  width = 500,
  yAxisData = pData,
  xAxisData = xData,
  xAxisScaleType = "time",
  xAxisDataValueFormatter = (value: any) => value.toISOString().slice(0, 10),
}: SparkLineChartType) {
  // console.log(yAxisData, xAxisData);
  return (
    <div className={``}>
      <Box sx={{ flexGrow: 1, width: width, height: height }}>
        <SparkLineChart
          sx={{
            ".MuiLineElement-root": {
              stroke: "#8884d8",
              strokeWidth: 2,
            },
            ".MuiMarkElement-root": {
              stroke: "#8884d8",
              scale: "0.6",
              fill: "#fff",
              strokeWidth: 2,
            },
          }}
          data={yAxisData}
          xAxis={{
            scaleType: xAxisScaleType,
            data: xAxisData,
            valueFormatter: xAxisDataValueFormatter,
          }}
          showTooltip
          showHighlight
        />
      </Box>
    </div>
  );
}
