import React, { useState, useEffect, useCallback } from "react";
import { MaterialIcon } from "../../components/utils/material_icon";

import Isagi_Yoichi from "../../data/images/yoichi_isagi.png";
import { vinanti } from "../../data/data";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import Input from "../../components/utils/ts/input";
import { ProfileSchema, TypePayloadAction } from "../../type";
import { profile_update_profile_to_list } from "../../store/actions/actionTypes";

const _profile: ProfileSchema = {
  profile_pic: Isagi_Yoichi,
  profile_name: "Isagi Yoichi",
  MBTI: "INFJ",
  tags: ["Forward Striker"],
  aka_tags: ["Egoist"],
  isFictional: true,
};

const UserProfile = () => {
  const profile: ProfileSchema =
    useSelector((state: RootState) => state.profile_io.curr_profile) ||
    _profile;

  const [profile_state, setProfile] = useState(profile);
  const { profile_name, tags, profile_pic, aka_tags, MBTI, isFictional } =
    profile_state;
  const [editable, toggle_edit] = useState(false);
  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const update_profile = async () => {
    await vinanti("update_info", "profile", null, {
      updated_info: profile_state,
    });
    toggle_edit(false);
    dispatch_action({
      type: profile_update_profile_to_list,
      payload: profile_state,
    });
  };

  const clipboard_to_img_local = async (func: any) => {
    var permissions = [
      "image/bmp",
      "image/gif",
      "image/png",
      "image/jpeg",
      "image/tiff",
    ];
    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === "text/plain") {
          return func(await navigator.clipboard.readText());
        }
        if (permissions.includes(type)) {
          const blob = await clipboardItem.getType(type);
          if (window.FileReader) {
            let reader = new FileReader();
            reader.onload = () => {
              func(reader.result);
              return reader.result;
            };
            reader.onerror = () => {
              func(null);
              return null;
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  useEffect(() => {
    setProfile(profile);
    return () => {
      // setProfile_name();
    };
  }, [profile, editable]);

  return (
    <section className="relative mx-auto h-fit w-72 rounded-t-xl px-3 py-2 backdrop-brightness-50">
      <div className="absolute right-2 top-2 cursor-pointer rounded-sm bg-white">
        {editable ? (
          <>
            <MaterialIcon icon="save" onclick={() => update_profile()} />
            <MaterialIcon
              icon="cancel"
              onclick={() => toggle_edit((state) => !state)}
            />
          </>
        ) : (
          <MaterialIcon
            icon="edit"
            onclick={() => toggle_edit((state) => !state)}
          />
        )}
      </div>
      <div className="mx-auto mt-1 w-fit">
        <img
          src={profile_pic}
          className="w-38 rounded-lg"
          alt={"profile_name"}
        />
        {editable && (
          <Input
            value={profile_pic}
            onDoubleClick={async () => {
              await clipboard_to_img_local((img: string) =>
                setProfile({ ...profile_state, profile_pic: img }),
              );
            }}
            className="w-min bg-transparent text-white"
            editOn={editable}
            placeholder="profile_pic"
          />
        )}
      </div>
      <div className="mt-3 ">
        <h2 className="text-2xl font-bold tracking-wide text-white">
          <Input
            placeholder="profile name"
            value={profile_name}
            onchange={(val: any) =>
              setProfile({ ...profile_state, profile_name: val })
            }
            className="bg-transparent text-white"
            editOn={editable}
          />
        </h2>
      </div>
      <div className="mt-1 flex flex-col gap-2 font-semibold text-emerald-400 ">
        <Input
          value={MBTI}
          placeholder="MBTI"
          onchange={(val: any) => setProfile({ ...profile_state, MBTI: val })}
          className="w-fit bg-transparent text-inherit"
          editOn={editable}
        />
        <h1 className="ml-1">{isFictional ? "Fictional" : "Real"}</h1>
      </div>
      <div className="tag-cloud mx-auto my-1 flex max-w-sm flex-wrap justify-start gap-1 p-1">
        {aka_tags.length &&
          Array.isArray(aka_tags) &&
          aka_tags.map((m) => (
            <button className="tag py-1/2 rounded bg-blue-50 px-2 text-sm text-gray-700 transition duration-200 ease-in-out hover:bg-gray-300">
              {m}
            </button>
          ))}
        {editable && (
          <Input
            value={Array.isArray(aka_tags) ? aka_tags.join(" ") : aka_tags}
            placeholder="aka Tags"
            onchange={(val: any) =>
              setProfile({ ...profile_state, aka_tags: val.split(" ") })
            }
            className={`w-full ${
              !editable ? "bg-transparent" : "bg-white"
            } text-inherit`}
            editOn={editable}
          />
        )}
      </div>
      <div className="tag-cloud mx-auto my-1 flex max-w-sm flex-wrap justify-start gap-2 p-1">
        {tags.length &&
          tags.map((m) => (
            <button className="tag py-1/2 rounded bg-green-50 px-2 text-sm text-gray-700 transition duration-200 ease-in-out hover:bg-gray-300">
              {m}
            </button>
          ))}
        {editable && (
          <Input
            value={Array.isArray(tags) ? tags.join(" ") : tags}
            placeholder="Tags"
            onchange={(val: any) =>
              setProfile({ ...profile_state, tags: val.split(" ") })
            }
            className={`w-full ${
              !editable ? "bg-transparent" : "bg-white"
            } text-inherit`}
            editOn={editable}
          />
        )}
      </div>
    </section>
  );
};

export default UserProfile;
