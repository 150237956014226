import { Button } from "react-utility-yard";
import { Text } from "../../components/utils/text";

import { useState, useEffect } from "react";
import { vinanti } from "../../data/data";
import { useSelector, useDispatch } from "react-redux";
import { listUpdateAction, StateUpdateAction } from "../../store/actions";
import { Loading } from "../../components/utils/loading";
import { MaterialIcon } from "../../components/utils/material_icon";
import { RootState } from "../../store/store";

export const RitualList = () => {
  const list = useSelector((state: RootState) => state.ritual_list);
  const [ritual_deleting, setRitual_deleting] = useState(false);
  const [new_ritual_name, setNew_ritual_name] = useState("");
  const [ritual_list, setRitual_list] = useState(list);
  const [Changing_ritual, setChanging_ritual] = useState(false);

  const user_info = useSelector((state: RootState) => state.user_info);
  const dispatch = useDispatch();

  const create_ritual = async () => {
    const new_req = await vinanti("new", "ritual", user_info.auth_token, {
      email: user_info.user_email,
      ritual_name: new_ritual_name,
    });
    if (new_req) {
      setNew_ritual_name("");
      search_all_ritual();
    }
  };

  const delete_ritual = async (id: string) => {
    // delete the ritual.
    setRitual_deleting(true);
    const new_req = await vinanti("del", "ritual", user_info.auth_token, {
      email: user_info.user_email,
      ritual_id: id,
    });

    if (new_req) {
      search_all_ritual();
    }
    setRitual_deleting(false);
  };
  const search_all_ritual = async () => {
    setChanging_ritual(true);
    const new_req = await vinanti("list", "ritual", user_info.auth_token, {
      email: user_info.user_email,
    });
    if (new_req) {
      setRitual_list(new_req);
      dispatch(listUpdateAction("RITUAL_PAGE_RITUAL_LIST", new_req));
    }
    setChanging_ritual(false);
  };
  const set_curr_ritual = async (id = null) => {
    setChanging_ritual(true);
    if (!id) return;
    const new_req = await vinanti("get", "ritual", user_info.auth_token, {
      email: user_info.user_email,
      ritual_id: id,
    });

    if (!new_req) return;
    if (new_req.length === 0) new_req[0] = null;
    if (new_req[0].defination === "")
      new_req[0].defination = `root {
      action [Update-Something]
  }`;
    console.log("new Fetched: ", new_req);

    dispatch(StateUpdateAction("RITUAL_CURR_ACTIVE", new_req[0]));
    setChanging_ritual(false);
  };

  useEffect(() => {
    if (!list) {
      search_all_ritual();
      // console.log("searching for stories");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="ritual-list-tools">
          <Button
            text={"Get All Stories"}
            onclick={() => search_all_ritual()}
            loading={Changing_ritual}
          />
          <Text
            isEdit={true}
            value={new_ritual_name}
            placeholder="New Ritual..."
            onChange={(e: any) => setNew_ritual_name(e.target.value)}
            width="300px"
          />
          <Button
            text={"Create Ritual"}
            onclick={() => create_ritual()}
            loading={Changing_ritual}
          />
          {/* <Text
            isEdit={true}
            value={search_ritual_txt}
            placeholder="Search ritual..."
            onChange={(e) => setSearch_ritual_txt(e.target.value)}
            width="300px"
          />
          <Button text={"Search Stories"} onclick={() => search_ritual()} /> */}
        </div>
        <div className="flex-1 overflow-hidden px-1">
          {ritual_list ? (
            <ul className="mt-1 divide-y divide-gray-100 overflow-y-scroll">
              {ritual_list.map((d: any) => (
                <>
                  <li
                    // key={person.email}
                    className="py-0.1 flex justify-between gap-x-1 px-3"
                  >
                    <div className="flex gap-x-4">
                      <div
                        className="min-w-0 flex-auto cursor-pointer"
                        onClick={() => set_curr_ritual(d._id)}
                      >
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {d.ritual_name}
                        </p>
                      </div>
                    </div>
                    <div className="hidden cursor-pointer sm:flex sm:flex-col sm:items-end">
                      {ritual_deleting ? (
                        <Loading size_num={30} />
                      ) : (
                        <MaterialIcon
                          icon="delete"
                          onclick={() => delete_ritual(d._id)}
                        />
                      )}
                    </div>
                  </li>
                </>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
