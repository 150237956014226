import loading from "../../data/images/Loading-Img.gif";
import success from "../../data/images/green_check_1.png";
import error from "../../data/images/red_cross_1.png";

type AknowProps = {
  size_num?: number;
  status: "success" | "error" | "loading" | null;
};

export const AknowledgementGif = ({
  size_num = 30,
  status = null,
}: AknowProps) => {
  return (
    <>
      {!status ? (
        <></>
      ) : (
        <div className="loading-img-wrap flex flex-col justify-center">
          <img
            className={"loading-img"}
            style={{
              height: size_num + "px",
              // width: size_num + "px"
            }}
            alt="topic-wait"
            src={
              status === "success"
                ? success
                : status === "loading"
                  ? loading
                  : error
            }
          />
        </div>
      )}
    </>
  );
};
