import "./css/tag.css";
import Input from "./ts/input";

type TagProps = {
  text: string;
  value?: string;
  color?: string | "bg-secondary";
  isEdit?: boolean;
  onChange?: any | null;
  input_type?: "text" | "time" | "datetime-local";
};

export const Tag = ({
  text = "null",
  value = "",
  color = "bg-secondary",
  isEdit = false,
  onChange = null,
  input_type = "text",
}: TagProps) => {
  return (
    <>
      <div className="tag">
        {isEdit ? (
          <Input
            editOn={true}
            placeholder={value}
            type={input_type}
            value={value}
            onchange={onChange}
          />
        ) : (
          <span className={"badge " + color}>{text}</span>
        )}
      </div>
    </>
  );
};
