import React from "react";

type Props = {
  value: string | number | undefined;
  onchange?: any;
  onkeyDown?: any;
  placeholder: string;

  size?: "sm" | "md" | "lg";
  type?: "text" | "time" | "datetime-local";
  className?: string;
  editOn: boolean;
  onDoubleClick?: any;
};

function Input({
  value,
  className,
  onchange = () => null,
  onkeyDown = () => null,
  placeholder,
  size = "sm",
  type = "text",
  editOn = false,
  onDoubleClick = null,
}: Props) {
  const sizes = { sm: "h-6", md: "h-8", lg: "h-10" };
  return (
    <input
      type={type}
      readOnly={!editOn}
      className={`${className}  ${sizes[size]} rounded-[0.225rem] px-1`}
      value={value}
      onChange={(e) => onchange(e.target.value)}
      placeholder={placeholder}
      onKeyDown={onkeyDown}
      onDoubleClick={onDoubleClick}
    />
  );
}

export default Input;
