import { StoryEditor } from "./story_editor";
import { StoryList } from "./story_list";
// import "./css/story_io.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SideBar } from "react-utility-yard";

export const StoryIo = () => {
  const global_story = useSelector((state) => state.story_curr_active);
  const [story, setStory] = useState(global_story);

  useEffect(() => {
    setStory(global_story);
  }, [global_story, story]);

  return (
    <>
      <div className="flex h-full max-h-full flex-row justify-between">
        <SideBar
          sideBar_list={<StoryList />}
          leftside={false}
          title={"Story List"}
          width={"w-[20rem]"}
        >
          {/* Main container */}
          <div className="flex max-h-full w-full flex-col overflow-y-scroll">
            <StoryEditor story={story} />
            {/* <App story={story} /> */}
          </div>
        </SideBar>
      </div>
    </>
  );
};
