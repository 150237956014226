import React, { useState, FC, useEffect } from "react";
import { Button, Input, MaterialIcon } from "react-utility-yard";
import { StreakCounter } from "./counter";
import { Tooltip } from "@mui/material";

export type streakSchema = {
  _id: string;
  activity_name: string;
  activity_desc: string;
  frequency: string;
  last_date: string;
  due: string;
  streak: number;
  highest_streak: number;
};
const _streak = {
  _id: "",
  activity_name: "Streak",
  frequency: "0 0 * * *",
  activity_desc: "Do da Fuck you want!",
  last_date: new Date().toISOString(),
  due: new Date(
    "Sun May 15 2024 02:52:59 GMT+0530 (India Standard Time)",
  ).toISOString(),
  streak: 0,
  highest_streak: 0,
};
interface StreakProps {
  streak: streakSchema | null;
  edit_btn?: boolean;
  streakUp?: any;
  update_streak?: any;
  delete_streak?: any;
}

const default_state = {
  edit_on: false,
};
export const StreakLogger: FC<StreakProps> = ({
  streak = null,
  streakUp = () => null,
  edit_btn = false,
  delete_streak = () => null,
  update_streak = () => null,
}: StreakProps) => {
  // GET the date from the activity_name
  // schema : activity_name/frequency/due/status/streak

  const [data, setData] = useState<streakSchema | null>(streak || _streak);
  const [updated_streak, setStreak] = useState<streakSchema>(streak || _streak);

  const [state, setState] = useState<typeof default_state>(default_state);
  // const [loading_state, setLoading_state] = useState(false);

  useEffect(() => {
    setData(streak);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streak]);

  useEffect(() => {
    if (!edit_btn)
      setState({
        ...state,
        edit_on: false,
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit_btn]);

  return (
    <div
      id={data?.activity_name}
      className=" min-h-auto z m-1 flex max-h-[350px] flex-col gap-1 rounded-lg bg-black bg-opacity-25 p-2"
    >
      <div className=" flex flex-wrap gap-2">
        {edit_btn && (
          <>
            <MaterialIcon
              onClick={() =>
                setState({
                  ...state,
                  edit_on: !state.edit_on,
                })
              }
              icon={state.edit_on ? "cancel" : "edit"}
              cursor="pointer"
            />
            <MaterialIcon
              onClick={() => delete_streak(data?._id)}
              icon={"delete"}
              cursor="pointer"
            />
          </>
        )}
        <div className=" text-black">
          <Button
            text=" ✨ "
            onclick={() => streakUp(data?._id)}
            color={
              new Date(data?.last_date || 0).getTime() > Date.now()
                ? "cool"
                : "warm"
            }
            // loading={loading_state}
          />
        </div>
        <div>{data?.streak}</div>
        <Tooltip title={data?.activity_desc}>
          <div>{data?.activity_name}</div>
        </Tooltip>
        <StreakCounter time={data?.due || ""} />
      </div>
      {state.edit_on && (
        <div className="flex flex-wrap gap-2">
          <Input
            className="text-black"
            editOn={true}
            placeholder="Activity Name"
            value={updated_streak.activity_name}
            onchange={(e: any) =>
              setStreak({ ...updated_streak, activity_name: e })
            }
          />
          <Input
            className="text-black"
            editOn={true}
            placeholder="Activity Description"
            value={updated_streak.activity_desc}
            onchange={(e: any) =>
              setStreak({ ...updated_streak, activity_desc: e })
            }
          />
          <Input
            className="text-black"
            editOn={true}
            placeholder="Frequency"
            value={updated_streak.frequency}
            onchange={(e: any) =>
              setStreak({ ...updated_streak, frequency: e })
            }
          />
          <Input
            type="datetime-local"
            className="text-black"
            editOn={true}
            placeholder="Due Date Time"
            value={updated_streak.due}
            onchange={(e: any) => setStreak({ ...updated_streak, due: e })}
          />
          <MaterialIcon
            onClick={() =>
              update_streak(updated_streak) &&
              setState({
                ...state,
                edit_on: false,
              })
            }
            icon="save"
            cursor="pointer"
          />
        </div>
      )}
    </div>
  );
};
