import { useReducer } from "react";

export const SelectOptions = ({
  list = null,
  value = null,
  index = false,
  selected_option_res = null,
}) => {
  const [selected_option, setSelected_option] = useReducer((opt) => {
    selected_option_res(opt);
    return opt;
  }, value);
  return (
    <>
      <div
        className="custom-selector"
        style={{ width: "fit-content", background: "red" }}
      >
        <select
          class="form-select"
          aria-label="Default select example"
          value={selected_option?.key}
          onChange={(e) => setSelected_option(e.target.value)}
        >
          {list &&
            list.map((l, i) => (
              <option
                key={`index-list-item-${l}`}
                value={l}
                {...(selected_option === l ? "selected" : "")}
              >
                {`${index ? i + ". " : ""}` + l.key}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};
