import { LinkIoSchema, LinkSchema, TypePayloadAction } from "../../type";
import * as actionTypes from "../actions/actionTypes";

const links_init_state: LinkIoSchema = {
  list: [],
  wrapperView: "LINKIO",
};

export const linkIOReducer = (
  state: LinkIoSchema = links_init_state,
  action: TypePayloadAction,
): LinkIoSchema => {
  switch (action.type) {
    case actionTypes.link_update_link_to_list:
      if (action.payload) {
        if (!state.list?.length) {
          return { ...state, list: [{ ...action.payload }] };
        }
        let new_links: LinkSchema[] = state.list.map((m) =>
          m._id === action.payload._id ? { ...action.payload } : m,
        );
        return { ...state, list: new_links };
      }
      return { ...state };
      
    case actionTypes.link_set_wrapper_view:
      return { ...state, wrapperView: action.payload };

    case actionTypes.link_update_list:
      let new_list: any[] = [];
      if (action.payload && action.payload.length > 0)
        new_list = [...action.payload];
      return { ...state, list: new_list };

    case actionTypes.link_add_to_list:
      if (action.payload && action.payload.length) {
        if (!state.list?.length) {
          return { ...state, list: [...action.payload] };
        }

        let new_links: LinkSchema[] = [];
        action.payload.map((n: any) => {
          if (!state.list.find((m) => m._id === n._id)) new_links.push(n);
        });
        if (new_links?.length)
          return { ...state, list: [...new_links, ...state.list] };
      }
      return { ...state };

    default:
      return { ...state };
  }
};
