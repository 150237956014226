/**
* @param {string} typ - [ "text/html", "text/plain"]
*/
export async function ClipboardCopy(typ = undefined) {
  let items;
  // console.log("into the clipcopy",items);
  try {
    items = await navigator.clipboard.read();
    // console.log("sdfgsd",items);
  } catch (e) {
    // console.log("Error",items);
    return null;
  }
  if(!typ) return 

  for (const item of items) {
    for (const type of item.types) {
      if (type === typ) {
        try {
          const blob = await item.getType(type);
          // console.log(await blob.text());
          return await blob.text();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  return null;
}
