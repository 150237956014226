import { useState, useEffect, useReducer } from "react";
import { Link } from "../../../../pages/Link_io/link";
import { useSelector } from "react-redux";
import { vinanti } from "../../../../data/data";
import { Modal } from "../../../utils/modal";
import { updateSearchLinksList } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { Dict, Oxford } from "../../../utils/dict";
import { Button } from "../../../utils/button";
import { LinkAdder } from "../../../../pages/Link_io/link_adder";
import { MaterialIcon } from "../../../utils/material_icon";
import { Loading } from "../../../utils/loading";
// import { Display } from "../../notebook/xCompo";

export default function SearchResults({
  srchRes = undefined,
  searchState = false,
  query = undefined,
  dictRes = [],
  oxfordRes = [],
  dict_btn = null,
  oxford_dict_btn = null,
}) {
  const add_new_list = ["link"];
  const [add_new_link, toggleAdd_new_link] = useReducer(
    (add_new_link) => !add_new_link,
    false,
  );

  const dispatch = useDispatch();
  var user_info = useSelector((state) => state.user_info);
  var metadata = useSelector((state) => state.metadata);
  var search_links_list = useSelector((state) => state.search_links_list);
  // var search_oxford_list = useSelector((state) => state.search_links_list);

  const [links_res, setLinks_res] = useState(srchRes);
  const [res_no, setRes_no] = useState(0);
  let searchingState = searchState;
  const [currDel, setCurrDel] = useState([]);
  const [currDelName, setCurrDelName] = useState(undefined);
  const [delModel, setDelModel] = useState(false);

  const linkIo_delete = async () => {
    console.log(currDel, " topic sent for delete Final Operation.");
    setDelModel(false);
    await vinanti("del", "link", user_info.auth_token, {
      email: user_info.user_email,
      link_id: currDel,
    });
    for (var di = 0; di < search_links_list.length; di++) {
      if (search_links_list[di]?._id === currDel) {
        search_links_list.splice(di, 1);
        break;
      }
    }
    dispatch(updateSearchLinksList(search_links_list));
    for (di = 0; di < links_res.length; di++) {
      if (links_res[di]?._id === currDel) {
        var temp = links_res;
        temp.splice(di, 1);
        setLinks_res(temp);
        break;
      }
    }

    setCurrDel(null);
  };
  const delete_model = (id, name) => {
    console.log(name, " wants to get deleted.");
    setDelModel(true);
    setCurrDel(id);
    setCurrDelName(name);
  };
  const closeOpenModal = () => {
    setDelModel(false);
  };
  useEffect(() => {
    // console.log("eavery time useefect");
    setLinks_res(srchRes);
    setRes_no(srchRes?.length + dictRes?.length);
  }, [srchRes, dictRes]);

  return (
    <div className="absolute z-10  mt-[45px] flex max-h-[550px] lg:w-[100vw] max-w-7xl flex-col gap-2 rounded-lg bg-slate-700 py-2 sm:px-2 lg:ml-[-200px] lg:px-8">
      <div className=" flex justify-between">
        <div className="flex">
          <div className="my-0.5 w-fit rounded-md bg-white px-1 text-xs lg:text-base">
            Results : {res_no}
          </div>
          {searchingState && <Loading size_num="30" />}
        </div>
        <div className="wrapper-template-search flex-wrap">
          <div className="wrapper-template-search-item">
            {` ${metadata ? metadata.links : 0} links`}
          </div>
          <div className="wrapper-template-search-item">
            {` ${metadata ? metadata.profiles : 0} Profiles`}
          </div>
          <div className="wrapper-template-search-item">
            {` ${metadata ? metadata.tasks : 0} Tasks`}
          </div>
          <div className="wrapper-template-search-item">
            {` ${metadata ? metadata.stories : 0} Stories`}
          </div>
          <div
            className="wrapper-template-search-item"
            onClick={() => dict_btn()}
          >
            121k Words
          </div>
          <div
            className="wrapper-template-search-item"
            onClick={() => oxford_dict_btn()}
          >
            Oxford Dict
          </div>
        </div>
        <div className="mr-2 rounded-md px-1">
          <MaterialIcon icon="two_wheeler" size="15px" />
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-1 overflow-y-auto bg-black">
        <div className="srch-ress">
          {links_res?.length === 0 ? (
            <></>
          ) : (
            <div className="search-result-links">
              {links_res?.map((d, i) => (
                <div className="srch-indi-res">
                  <Link
                    d={d}
                    i={i}
                    list_type={"search_list"}
                    delete_model={() => delete_model(d._id, d.link_name)}
                  />
                </div>
              ))}
            </div>
          )}
          {dictRes?.length === 0 ? (
            <>{/* <Dict /> */}</>
          ) : (
            dictRes.map((d) => <Dict dict_info={d} />)
          )}
          {oxfordRes?.results?.length === 0 ? (
            <>No results found for Dict.</>
          ) : (
            oxfordRes?.results?.map((d) => (
              <>
                <Oxford dict_info={d} />
              </>
            ))
          )}
        </div>
      </div>
      <div style={{ display: "inline" }}>
        {add_new_link ? (
          <LinkAdder get_link_id auto_exit={() => toggleAdd_new_link()} />
        ) : (
          <></>
        )}
        {add_new_list.map((res) => (
          <Button text={"new " + res} onclick={() => toggleAdd_new_link()} />
        ))}
      </div>
      {delModel ? (
        <Modal
          handleClose={() => closeOpenModal()}
          handleConfirm={() => linkIo_delete()}
        >
          <div style={{ display: "inline" }}>
            <h5>Do you Want To Delete </h5>{" "}
            <h3>
              <b>
                {currDelName} ({currDel}){" "}
              </b>
            </h3>{" "}
            <h5>Problem ?</h5>{" "}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}
