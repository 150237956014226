import { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "../Link_io/link";
import { Button } from "../../components/utils/button";
import { vinanti } from "../../data/data";
import { updateLinksList } from "../../store/actions";
import { updateFavLinksList } from "../../store/actions";
import { Tasks } from "../Tasks/tasks";
import { MiniProfileList } from "../Profile_io/mini_profile_list";
import { MaterialIcon } from "../../components/utils/material_icon";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import PieChart from "../../components/experiment/graphs/pie_chart";
import DragII from "../../components/experiment/graphs/draw_board";
import HeatMap from "../../components/experiment/graphs/heatmap";
import { DuckStoryModel } from "../../components/utils/modal";
import TinyLineChart from "../../components/utils/ts/charts/lineChart";
import SparkLine from "../../components/utils/ts/charts/sparkLine";

export function Home2() {
 
  const [duck_story, setDuck_story] = useReducer(
    (duck_story) => !duck_story,
    false,
  );
  const [fav_links_loading, setFav_links_loading] = useState(false);

  var fav_links_list = useSelector((state) => state.link_io_fav_list);
  const custom_data = useSelector((state) => state.custom_settings);
  var page_links_list = useSelector((state) => state.link_io_page_list);
  const user_info = useSelector((state) => state.user_info);
  const dispatch = useDispatch();

  const search_links_locally = async (
    links,
    data = undefined,
    online = true,
  ) => {
    var res = [];
    var not_res = [];
    var list_local = data ? data : page_links_list ? [...page_links_list] : [];
    links
      ? links.map((d, i) => {
          const ind = list_local?.find((l) => l._id === d);
          ind ? res.push(ind) : not_res.push(d);
          return null;
        })
      : console.log("empty Glink found");
    if (online && not_res?.length > 0) {
      console.log("searching online");
      var online_links = await vinanti(
        "list_limited",
        "link",
        user_info.auth_token,
        {
          ids: not_res,
        },
      );
      res.push(...online_links);
      page_links_list
        ? [...page_links_list].push(...online_links)
        : (page_links_list = online_links);
      dispatch(updateLinksList(page_links_list));
    }
    console.log("search_links_locally", res);
    dispatch(updateFavLinksList(res));
    return res;
  };

  const fav_links_dispatch = async () => {
    setFav_links_loading(true);
    let indexes = custom_data?.custom_settings_indexes;
    for (var i = 0; i < indexes?.length; i++) {
      if (indexes[i].name === "fav_links") {
        await vinanti("get_id", "custom", user_info.auth_token, {
          id: indexes[i].id,
          email: user_info.user_email,
        }).then(async (res) => await search_links_locally(res?.[0].data));
        break;
      }
    }
    setFav_links_loading(false);
  };

  useEffect(() => {
    // getTwits("1");
    if (user_info && !fav_links_list) {
      fav_links_dispatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user_info && custom_data && !fav_links_list) {
      fav_links_dispatch();
    }
    // setDuck_story();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_info, custom_data, fav_links_list]);


  return (
    <>
      <div className="layout-wrap">
        {/* <ReactJkMusicPlayer {...options} />, */}
        {/* <div><BrushChart/></div> */}
        <div className="graphs-twitter">
          {/* <ParentSize>
            {({ width, height = 100 }) => (
              <BrushChart width={700} height={400} />
            )}
          </ParentSize> */}
          {/* <ParentSize>
            {({ width, height = 100 }) => (
              <BrushChart width={700} height={400} />
            )}
          </ParentSize> */}
          <ParentSize>
            {({ width, height }) => <HeatMap width={400} height={500} />}
          </ParentSize>
          ,
          <ParentSize>
            {({ width, height }) => <DragII width={700} height={400} />}
          </ParentSize>
          <ParentSize>
            {({ width = 300, height = 300 }) => (
              <PieChart width={300} height={300} />
            )}
          </ParentSize>
          <TinyLineChart
            dots={true}
            height={100}
            width={300}
            xAxisData={[10, 20, 50, 70, 60]}
            yAxisData={[10, 20, 50, 70, 60]}
          />
        </div>
          <SparkLine height={50} width={200} />
        <div className="fav-links-today">
          <div className="fav-links">
            <div className="fav-links-header">
              <MaterialIcon icon="link" />
              <div className="fav-links-title">
                <h6>Fav links</h6>
              </div>
              <div className="fav-links-button">
                <Button
                  text="get Fav Links"
                  onclick={() => fav_links_dispatch()}
                  loading={fav_links_loading}
                />
                <Button
                  text="read duck story"
                  onclick={() => setDuck_story()}
                  loading={fav_links_loading}
                />
              </div>
            </div>
            <div className="fav-links-list">
              {fav_links_list ? (
                fav_links_list.map((d) => <Link d={d} />)
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* <LiveVerse /> */}
          <div className="home-today">
            <Tasks />
          </div>
          <div className="home-profiles">
            <MiniProfileList />
          </div>
          {/* <MusicPlayer/> */}
        </div>
        {/* duck_story ? */}
        
        <DuckStoryModel
          display={duck_story}
          func={() => setDuck_story()}
          skip={() => setDuck_story()}
        />
        {/* :<></> */}
      </div>
    </>
  );
}
