import React, { useReducer, useState } from "react";
import { vinanti } from "../../data/data";

import { useDispatch, useSelector } from "react-redux";
import { updateLinksList } from "../../store/actions";
// import { Button } from "../../utils/button";
import { Text } from "../../components/utils/text";
import { SelectOptions } from "../../components/utils/select_options";
import { ToggleButton } from "../../components/utils/toggle_switch";
import { InputFile } from "../../components/utils/file_input_handler";
import { MaterialIcon } from "../../components/utils/material_icon";
import { AknowledgementGif } from "../../components/utils/aknowledgement_gif";
import { Input, Button } from "react-utility-yard";

const code_lan_list = [
  { key: "c++", value: "cpp" },
  { key: "Python", value: "python" },
];

/**
 * convert png clipboard or data url to png file.
 *
 * @param {any} auto_exit
 * @return {*} png file
 */

export const LinkAdder = ({
  get_link_id = false,
  auto_exit = null,
  cancel_btn = null,
}) => {
  const [glink, setGlink] = useState(false);

  const [img_file_name, setImg_file_name] = useState(null);
  const [img_file, setImg_file] = useState(null);
  const [img_clipboard_png, setImg_clipboard_png] = useState(null);
  const [isclipboardPNG, toggleClipboardPNG] = useReducer(
    (isclipboardPNG) => !isclipboardPNG,
    false,
  );
  const [img_file_uploaded, setImg_file_uploaded] = useState(false);
  const [name_availability, setName_availability] = useState("invalid"); // loading // success // invalid

  const [hyperlink, setHyperlink] = useState("");
  const [isHyperlink, toggleHyperlink] = useReducer(
    (isHyperlink) => !isHyperlink,
    false,
  );

  const [iframe_src, setIframe_src] = useState("");
  const [isIframe, toggleIframe] = useReducer((isIframe) => !isIframe, false);

  const [code_snippet, setCode_snippet] = useState(null);
  const [selected_code, setSelected_code] = useState(null);
  const [isCodeSnippet, toggleCodeSnippet] = useReducer(
    (isCodeSnippet) => !isCodeSnippet,
    false,
  );
  const [isAddImg, toggleAddImg] = useReducer((isAddImg) => !isAddImg, false);

  const [link_name, setLink_name] = useState("");
  const [link_description, setLink_description] = useState("");
  const [link_tags, setLink_tags] = useState("");

  var list = useSelector((state) => state.link_io_page_list) | [];
  const user_info = useSelector((state) => state.user_info);

  const dispatch = useDispatch();

  const newLink_submit = async () => {
    // setBusy(true);
    var link = await vinanti(
      "new",
      "link",
      user_info.auth_token,
      {
        email: user_info.user_email,
        link_name: link_name,
        link_description: link_description,
        link_tags: link_tags,
        link_type: isHyperlink ? "Hyperlink" : "link",
        hyperlink: hyperlink ? hyperlink : "",
        iframe: { src: iframe_src },
        code_snippet: {
          lan: selected_code ? selected_code.value : null,
          code: code_snippet,
          info: null,
        },
        img: img_file_name,
        // visits: visits,
        glink: glink,
      },
      // []
    );
    setLink_name("");
    setLink_description("");
    setCode_snippet("");
    setLink_tags("");
    setGlink(false);
    setHyperlink("");
    setIframe_src("");
    if (isHyperlink === true) {
      toggleHyperlink();
    }
    if (isIframe === true) {
      toggleIframe();
    }
    if (list?.length) dispatch(updateLinksList([link?.[0], ...list]));
    else dispatch(updateLinksList([link?.[0]]));
    if (get_link_id) navigator.clipboard.writeText(link?.[0]._id);
    if (auto_exit) auto_exit();
  };

  const submit_file = async (file, isPng = false) => {
    console.log("hello");
    var bodyFormData = new FormData();
    if (isPng) file = await png_to_file(img_clipboard_png);
    bodyFormData.append("file", file);
    bodyFormData.append("email", user_info.user_email);
    bodyFormData.append("fileName", "pro-admin-io/link-io/" + img_file_name);

    await vinanti("new", "storage", user_info.auth_token, bodyFormData, {
      "Content-Type": "multipart/form-data",
    }).then((m) => {
      if (m === "OK") {
        setImg_file_uploaded(true);
        // setImg_file_name(null);
        setImg_file(null);
        setImg_clipboard_png(null);
        // setImg_file_uploaded] = useState(false);
        setName_availability("invalid"); // loading // success // invalid
        // setNamecheck_freq(1000);
      } else console.log("file Upload failed!");
    });
  };
  const clipboard_to_img_local = async (func) => {
    var permissions = {
      "image/bmp": true,
      "image/gif": true,
      "image/png": true,
      "image/jpeg": true,
      "image/tiff": true,
      "text/plain": false,
    };
    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === "text/plain") {
          return func(await navigator.clipboard.readText());
        }
        if (permissions[type]) {
          const blob = await clipboardItem.getType(type);
          if (window.FileReader) {
            let reader = new FileReader();
            reader.onload = () => {
              console.log(reader.result);
              func(reader.result);
              return reader.result;
            };
            reader.onerror = () => {
              func(null);
              return null;
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  /**
   * convert png clipboard or data url to png file.
   *
   * @param {String} dataUrl
   * @param {string} [fileName="clipboard_img_" + Date.now()]
   * @param {string} [mimeType="image/png"]
   * @return {*} png file
   */
  const png_to_file = async (
    dataUrl,
    fileName = "clipboard_img_" + Date.now(),
    mimeType = "image/png",
  ) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: mimeType });
  };

  const set_img_file_name = async (name) => {
    setImg_file_name(name);
    // var diff = Date.now() - namecheck_freq;
    // setNamecheck_freq(Date.now());
    setName_availability("loading");

    const req = async () =>
      await vinanti("exists", "storage", user_info.auth_token, {
        file: "pro-admin-io/link-io/" + name,
      }).then((m) => {
        if (!m.status) {
          setName_availability("success");
        } else setName_availability("invalid");
      });
    //  Logic to make less requests to server.
    // if (diff > 500) {
    //   req();
    // } else
    //   setTimeout(() => {
    //     if (Date.now() - namecheck_freq > 500) req();
    //   }, 100);
    req();
  };
  return (
    <>
      <div className="mb-2 flex flex-col items-center">
        <div className="flex min-w-[60%] flex-wrap justify-center">
          <Text
            title="Link name"
            isEdit={true}
            width="30%"
            placeholder="Enter Link Name"
            value={link_name}
            onChange={(e) => setLink_name(e.target.value)}
          />
          <Text
            title="tags"
            isEdit={true}
            width={"70%"}
            placeholder="Enter Description..."
            value={link_description}
            onChange={(e) => setLink_description(e.target.value)}
            // textArea={true}
          />
        </div>
        <div className="flex min-w-[60%] flex-wrap justify-center">
          <ToggleButton
            state={isCodeSnippet}
            text="Code Snippet"
            onChange={() => toggleCodeSnippet()}
            children={
              isCodeSnippet ? (
                <>
                  <Text
                    title="hyperlink"
                    isEdit={true}
                    width="30%"
                    placeholder="Enter Code snippet"
                    value={code_snippet}
                    onChange={(e) => setCode_snippet(e.target.value)}
                  />

                  <SelectOptions
                    index
                    list={code_lan_list}
                    selected_option_res={(opt) => setSelected_code(opt)}
                  />
                </>
              ) : (
                <></>
              )
            }
          />
          <ToggleButton
            state={isAddImg}
            text="Add Img"
            onChange={() => toggleAddImg()}
            children={
              isAddImg ? (
                <>
                  <Text
                    title="Img"
                    isEdit={true}
                    width="250px"
                    placeholder="Enter Image Name"
                    value={img_file_name}
                    onChange={(e) => set_img_file_name(e.target.value)}
                    availability={{ state: true, status: name_availability }}
                    // isResults={true}
                    // results={["duck"]}
                  />
                  <ToggleButton
                    state={!isclipboardPNG}
                    text="file"
                    onChange={() => toggleClipboardPNG()}
                    children={
                      isclipboardPNG ? (
                        <div
                          style={{
                            background: "white",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <MaterialIcon
                            icon="content_paste_go"
                            // onclick={() => clipboard_to_img_local(png_to_file)}
                          />
                          <Text
                            title="clipboard img"
                            isEdit={true}
                            width="250px"
                            placeholder="double tap paste"
                            value={img_clipboard_png}
                            onChange={(e) =>
                              setImg_clipboard_png(e.target.value)
                            }
                            onDoubleClick={async () =>
                              clipboard_to_img_local(setImg_clipboard_png)
                            }
                          />
                          {name_availability === "success" ? (
                            <Button
                              text="Up-load"
                              onclick={() => submit_file(img_file, true)}
                            />
                          ) : (
                            <></>
                          )}

                          {img_file_uploaded ? (
                            <AknowledgementGif
                              status={img_file_uploaded ? "success" : "error"}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <InputFile
                          submit={submit_file}
                          submit_bnt={name_availability === "success"}
                          upload_done={img_file_uploaded}
                          width="420px"
                        />
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            }
          />
          <ToggleButton
            state={glink}
            text="glink"
            onChange={glink ? () => setGlink(false) : () => setGlink(true)}
          />
          <ToggleButton
            state={isHyperlink}
            text="Hyperlink"
            onChange={() => toggleHyperlink()}
            children={
              isHyperlink ? (
                <Text
                  title="hyperlink"
                  isEdit={true}
                  width="30%"
                  placeholder="Enter Link"
                  value={hyperlink}
                  onChange={(e) => setHyperlink(e.target.value)}
                />
              ) : (
                <></>
              )
            }
          />
          <ToggleButton
            state={isIframe}
            text="iFrame"
            onChange={() => toggleIframe()}
            children={
              isIframe ? (
                <Text
                  title="Iframe"
                  isEdit={true}
                  width="30%"
                  placeholder="Enter Link"
                  value={iframe_src}
                  onChange={(e) => setIframe_src(e.target.value)}
                />
              ) : (
                <></>
              )
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Text
            isEdit={true}
            width={"350px"}
            title="no."
            type="text"
            placeholder="link_tags"
            value={link_tags}
            onChange={(e) => setLink_tags(e.target.value.split(","))}
          />
          <div className="">
            <Button
              type="button"
              className="btn"
              onclick={() => newLink_submit()}
              text="Create New"
            />
          </div>
          <Button text="Cancel" onclick={cancel_btn} />
        </div>
      </div>
    </>
  );
};

export const LinkIdAdder = ({ on_attach_link }) => {
  const [link_id, setLink_id] = useState(null);
  const [new_link_toggle, setNew_link_toggle] = useState(false);

  return (
    <div className="ml-1 mr-1 flex-col rounded-md bg-slate-400 pl-3">
      <div className="flex flex-wrap items-center gap-1">
        <span>{"Link@"}</span>
        <Input
          isEdit={true}
          width={"300px"}
          title="Copy Id and double tap here"
          type="text"
          placeholder="Copy Id & double tap here"
          value={link_id}
          onChange={(e) => setLink_id(e.target.value)}
          onDoubleClick={async () => {
            const text = await navigator.clipboard.readText();
            setLink_id(text);
          }}
        />
        <div className="w-[40px]">
          <MaterialIcon
            icon="link"
            onclick={() => {
              on_attach_link(link_id);
              setLink_id(null);
            }}
            cursor={"pointer"}
          />
        </div>
        {!new_link_toggle && (
          <Button text="New Link" onclick={() => setNew_link_toggle(true)} />
        )}
      </div>
      <div>
        {new_link_toggle && (
          <LinkAdder
            cancel_btn={() => setNew_link_toggle(false)}
            auto_exit={() => setNew_link_toggle(false)}
          />
        )}
      </div>
    </div>
  );
};
