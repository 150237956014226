import "./css/story_list.css";
import { Button } from "../../components/utils/button";
import { Text } from "../../components/utils/text";

import { useState, useEffect } from "react";
import { vinanti } from "../../data/data";
import { useSelector, useDispatch } from "react-redux";
import { listUpdateAction, StateUpdateAction } from "../../store/actions";
import { Loading } from "../../components/utils/loading";
import { MaterialIcon } from "../../components/utils/material_icon";

export const StoryList = () => {
  const list = useSelector((state) => state.story_list);
  const [story_deleting, setStory_deleting] = useState(false);
  const [new_story_name, setNew_story_name] = useState();
  const [story_list, setStory_list] = useState(list);
  const [Changing_story, setChanging_story] = useState(false);

  const user_info = useSelector((state) => state.user_info);
  const dispatch = useDispatch();

  const create_story = async () => {
    await vinanti("new", "story", user_info.auth_token, {
      email: user_info.user_email,
      story_name: new_story_name,
    }).then((res) => {
      // console.log(res);
      if (res) {
        // setStory_list(res);
        setNew_story_name("");
        search_all_story();
      }
    });
  };
  const delete_story = async (id) => {
    // delete the story.
    setStory_deleting(true);
    await vinanti("del", "story", user_info.auth_token, {
      email: user_info.user_email,
      story_id: id,
    }).then((res) => {
      // console.log(res);
      if (res) {
        // setStory_list(res);
        search_all_story();
      }
    });
    setStory_deleting(false);
  };
  const search_all_story = async () => {
    setChanging_story(true);
    await vinanti("list", "story", user_info.auth_token, {
      email: user_info.user_email,
    }).then((res) => {
      if (res) {
        setStory_list(res);
        dispatch(listUpdateAction("STORY_PAGE_STORY_LIST", res));
      }
    });
    setChanging_story(false);
  };
  const set_curr_story = async (id = null) => {
    setChanging_story(true);
    if (!id) return;
    await vinanti("get", "story", user_info.auth_token, {
      email: user_info.user_email,
      story_id: id,
    }).then((res) => {
      console.log("curr story", res);
      if (!res) return;
      if (res.length === 0) res[0] = null;
      dispatch(StateUpdateAction("STORY_CURR_ACTIVE", res[0]));
    });
    setChanging_story(false);
  };
  useEffect(() => {
    if (!list) {
      search_all_story();
      // console.log("searching for stories");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="story-list-tools">
          <Button
            text={"Get All Stories"}
            onclick={() => search_all_story()}
            loading={Changing_story}
          />
          <Text
            isEdit={true}
            value={new_story_name}
            placeholder="New Story..."
            onChange={(e) => setNew_story_name(e.target.value)}
            width="300px"
          />
          <Button
            text={"Create Story"}
            onclick={() => create_story()}
            loading={Changing_story}
          />
          {/* <Text
            isEdit={true}
            value={search_story_txt}
            placeholder="Search story..."
            onChange={(e) => setSearch_story_txt(e.target.value)}
            width="300px"
          />
          <Button text={"Search Stories"} onclick={() => search_story()} /> */}
        </div>
        <div className="flex-1 px-1 overflow-y-scroll">
          {story_list ? (
            <ul className="divide-y divide-gray-100 mt-1">
              {story_list.map((d, i) => (
                <>
                  <li
                    // key={person.email}
                    className="flex justify-between gap-x-1 px-3 py-0.1 bg-slate-400"
                  >
                    <div className="flex gap-x-4">
                      <div
                        className="min-w-0 flex-auto cursor-pointer"
                        onClick={() => set_curr_story(d._id)}
                      >
                        <p className="text-sm font-semibold leading-6 text-white">
                          {d.story_name}
                        </p>
                      </div>
                    </div>
                    <div className="hidden sm:flex sm:flex-col sm:items-end cursor-pointer">
                      {story_deleting ? (
                        <Loading size_num="30" />
                      ) : (
                        <MaterialIcon
                          icon="delete"
                          onclick={() => delete_story(d._id)}
                        />
                      )}
                    </div>
                  </li>
                </>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
