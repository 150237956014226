import * as React from "react";
import { ChartContainer } from "@mui/x-charts";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";

const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];
type TinyLineChartType = {
  height?: number;
  width?: number;
  xAxisData?: any[];
  yAxisData?: any[];
  dots?: boolean;
};

export default function TinyLineChart({
  height = 300,
  width = 500,
  dots = false,
  yAxisData = pData,
  xAxisData = xLabels,
}: TinyLineChartType) {
  return (
    <div className={``}>
      <ChartContainer
        width={width}
        height={height}
        series={[{ type: "line", data: yAxisData }]}
        xAxis={[{ scaleType: "point", data: xAxisData }]}
        sx={{
          ".MuiLineElement-root": {
            stroke: "#8884d8",
            strokeWidth: 2,
          },
          ".MuiMarkElement-root": {
            stroke: "#8884d8",
            scale: "0.6",
            fill: "#fff",
            strokeWidth: 2,
          },
        }}
        disableAxisListener
      >
        <LinePlot />
        {dots && <MarkPlot />}
      </ChartContainer>
    </div>
  );
}
