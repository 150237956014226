import { useState } from "react";
import UltimateSearchLayout from "../../components/experiment/live_bar/live_index";
import { TimeHomePage } from "../../components/utils/Currrent_time";
import { ReinLogo } from "../../components/utils/rein_logo";
import { Tasks } from "../Tasks/tasks";
import { ClipBoardLinks } from "../live/clipboard_links";
import { StreakLoggerWrapper } from "../Streak/streak_log_wrapper";
import Calendar from "../Tasks/calender";
import { Drawer } from "@mui/material";
import { Button } from "react-utility-yard";

const default_home_view = {
  tasksOn: false,
  linkOn: false,
  clipboardOn: false,
  ritualIoOn: false,
  plannerOn: false,
};

export function Home() {
  const [homeView, setHomeView] = useState(default_home_view);
  return (
    <div className="relative grid h-full w-full grid-cols-7 grid-rows-6 items-center justify-items-center">
      <div className=" col-span-3 col-start-3 row-span-1 row-start-4 lg:col-span-2 lg:col-start-1 lg:row-start-2">
        <StreakLoggerWrapper />
      </div>
      <div className="col-span-1 col-start-4 row-span-1 row-start-1 lg:col-start-7">
        <TimeHomePage />
      </div>
      <div className="col-start-3 col-end-6 row-span-1 row-start-2">
        <ReinLogo />
      </div>
      <div className="col-span-full col-start-1 row-start-3 row-end-auto flex h-full w-full justify-center lg:col-span-5 lg:col-start-2">
        <div className="w-[90%] lg:w-[60%]">
          <UltimateSearchLayout />
        </div>
      </div>
      <div className="col-span-2 col-start-6 row-span-4 row-start-2">
        {homeView.tasksOn && <Tasks view="mini" taskAdderOn={false} />}
        {/* {homeView.ritualIoOn && <Tasks view="mini" taskAdderOn={false} />} */}
      </div>
      <div className="absolute right-10 h-full max-h-[70%] ">
        {homeView.plannerOn && (
          <Drawer
            anchor={"right"}
            open={homeView.plannerOn}
            onClose={(event) =>
              setHomeView({ ...homeView, plannerOn: !homeView.tasksOn })
            }
          >
            <div className="h-full w-full flex flex-col">
              <div className="flex w-full flex-row-reverse p-1">
                <Button
                  text="Close"
                  icon="close"
                  color="cool"
                  onclick={() =>
                    setHomeView({ ...homeView, plannerOn: !homeView.plannerOn })
                  }
                ></Button>
              </div>
              <div className="h-full w-full flex-1">
                <Calendar />
              </div>
            </div>
          </Drawer>
        )}
        {/* {homeView.ritualIoOn && <Tasks view="mini" taskAdderOn={false} />} */}
      </div>
      <div className="col-span-2 col-start-1 row-span-4 row-start-2">
        {homeView.clipboardOn && <ClipBoardLinks />}
      </div>
      <div className="absolute right-0 top-[50%] flex flex-col gap-2">
        <div
          className=" rounded-l-md bg-black px-1 text-white opacity-50"
          onClick={() =>
            setHomeView({ ...homeView, tasksOn: !homeView.tasksOn })
          }
        >
          Tasks
        </div>
        <div
          className=" rounded-l-md bg-black px-1 text-white opacity-50"
          onClick={() =>
            setHomeView({ ...homeView, plannerOn: !homeView.plannerOn })
          }
        >
          Planner
        </div>
        {/* <div
          className=" rounded-l-md bg-black px-1 text-white opacity-50"
          onClick={() =>
            setHomeView({ ...homeView, ritualIoOn: !homeView.ritualIoOn })
          }
        >
          Ritual
        </div> */}
      </div>
      <div className="absolute left-0 top-[50%] flex flex-col">
        <div
          className=" rounded-r-md bg-black px-1 text-white opacity-50"
          onClick={() =>
            setHomeView({ ...homeView, clipboardOn: !homeView.clipboardOn })
          }
        >
          Clipboards
        </div>
      </div>
    </div>
  );
}
