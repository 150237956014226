import { LinkSchema } from "../../../../type";
import { MaterialIcon } from "../../../../components/utils/material_icon";
// import { useEffect } from "react";

export function HyperLink({ d = null }: { d: LinkSchema | null }) {
  return (
    <>
      <div className="">
        <div className="flex items-center gap-2">
          <a
            href={d ? d.link_hyperlink : ""}
            target="_blank"
            rel="noreferrer"
            className="block w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-blue-500"
          >
            {d ? d.link_hyperlink : ""}
          </a>
          <MaterialIcon
            icon="content_copy"
            onclick={() =>
              navigator.clipboard.writeText(d ? d.link_hyperlink : "")
            }
            cursor={"pointer"}
          />
        </div>
        {/* <iframe
          title={d ? d.link_hyperlink : ""}
          width="400"
          height="250"
          className="link-preview"
          src={d ? d.link_hyperlink : ""}
          scrolling="no"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe> */}
      </div>
    </>
  );
}
