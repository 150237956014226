import { useState } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-sqlserver";
import "./mode-mdsl";

import Alert from "@mui/material/Alert/Alert";

import "./DefinitionTab.css";
import { MaterialIcon } from "../../../components/utils/material_icon";

export type DefinitionTabProps = {
  /** The definition value. */
  value: string;

  /** The callback for definition value changes. */
  onChange(value: string): void;

  /** The definition error message. */
  errorMessage?: string;

  readOnly: boolean;
};

/**
 * The DefinitionTab component.
 */
export const DefinitionTab: React.FunctionComponent<DefinitionTabProps> = ({
  value,
  onChange,
  errorMessage,
  readOnly,
}) => {
  const [editDefination, setEditDefination] = useState<Boolean>(false);
  return (
    <div className="sidebar-tab definition-tab">
      <div className="flex gap-x-0.5 bg-blue-400">
        <div className="px-2 font-bold font-mono text-center my-auto">Definition</div>
        <MaterialIcon
          icon={editDefination ? "cancel" : "edit"}
          onclick={() => setEditDefination((editDefination) => !editDefination)}
          cursor="pointer"
        />
      </div>
      <AceEditor
        className="definition-tab-ace-editor"
        value={value}
        onChange={onChange}
        readOnly={!readOnly && editDefination ? false : true}
        width="100%"
        height="inherit"
        mode="mdsl"
        theme="sqlserver"
        setOptions={{ useWorker: false }}
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </div>
  );
};
