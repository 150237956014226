import React from "react";
import App from "../../components/experiment/react-flow/canvas";
import TaskFLow from "../../components/experiment/react-flow/task-flow";
import Calendar from "../Tasks/calender";

type Props = {};

function CanvasApp({}: Props) {
  return (
    <div className="bg-black-300 h-full w-full bg-opacity-10">
      {/* <App /> */}
      {/* <TaskFLow /> */}
    
      {/* <Planner2  /> */}
      {/* <Calendar/> */}
    </div>
  );
}

export default CanvasApp;
