import React, { FC } from "react";
import { DuckList } from "./priority_list";

export const LiveVerse: FC = () => {
  //   const [items, setItems] = useState(["Item 1", "Item 2", "Item 3"]);
  return (
    <>
      <div className="flex  flex-wrap justify-center mt-5 gap-2 h-full max-h-[90%] max-w-[80%] m-auto overflow-y-auto">
        <DuckList list_name="Priority List" id="64d9485a4afa4b0008f5649c" />
        <DuckList list_name="CheckPoints List" id="64daf460aea7e600082a445e" />
        <DuckList
          list_name="Live/Commitments List"
          id="64db6905c131390008414e0b"
        />
        <DuckList list_name="Meta List" id="64db7603fc0d1000086535f4" />
        <DuckList
          list_name="Loop Statement List"
          id="64dba16aae0ebe0008b90a84"
        />
        <DuckList
          list_name="Loop Breaking List"
          id="64e19016c6d4870008236681"
        />
        <DuckList list_name="Infinity Sailor" id="64e1a7ce7f9e500008fb42ad" />
        <DuckList list_name="Frequency" id="64e18ff1c6d4870008236680" />
        <DuckList list_name="Extended Simulation" id="64e1aa14f917770008eb730c" />
        <DuckList list_name="Art of Simulation" id="64e18fc6c6d487000823667e" />
        <DuckList list_name="Statements List" id="64db7461a5658600080aa323" />
      </div>
    </>
  );
};
