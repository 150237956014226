import { IsLoggedReducer, userInfoReducer } from "./isLogged";
import { tasksReducer } from "./Tasks_Page";
import {
  twitterTrendsListReducer,
  customSettingsReducer,
  searchLinksListReducer,
  favLinksListReducer,
  storyCurrReducer,
  storylistReducer,
  vinantilistReducer,
  metadataReducer,
  daylistReducer,
  ritualCurrReducer,
  rituallistReducer,
} from "./Home_Page";

import { usersListReducer } from "./Users_Page";
import { projectsListReducer } from "./Projects_Page";
import SelectPageReducer from "./selectPage";
import { combineReducers } from "redux";
import { leetCodeReducer } from "./Leetcode_Page";
import { linkIOReducer } from "./Link_IO_Page.tsx";
import { kardzReducer } from "./Kardz_Page";
import { profileReducer } from "./Profile_Page";
import { todayReducer } from "./Today_Page.ts";
import { streaksReducer } from "./Streaks.ts";

const allReducers = combineReducers({
  log_in_status: IsLoggedReducer,
  user_info: userInfoReducer,
  users_page_users: usersListReducer,
  leetcode_page_problems: leetCodeReducer,
  custom_settings: customSettingsReducer,
  link_io_fav_list: favLinksListReducer,
  search_links_list: searchLinksListReducer,
  home_page_twittertrends: twitterTrendsListReducer,
  projects_page_projects: projectsListReducer,
  story_list: storylistReducer,
  ritual_list: rituallistReducer,
  story_curr_active: storyCurrReducer,
  ritual_curr_active: ritualCurrReducer,
  page_live: SelectPageReducer,
  vinanti_res: vinantilistReducer,
  metadata: metadataReducer,
  days_list: daylistReducer,

  // Updated Reducer Formats
  profile_io: profileReducer,
  kardz: kardzReducer,
  tasks: tasksReducer,
  links: linkIOReducer,
  today_io: todayReducer,
  streaks: streaksReducer,
});

export default allReducers;
