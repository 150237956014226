import React, { useReducer, useState } from "react";
import {
  Button,
  Input,
  MaterialIcon,
  SelectOptions,
  ToggleSwitch,
} from "react-utility-yard";
import { InputFile } from "../../../components/utils/file_input_handler";
import { useAppSelector } from "../../../store/store";
import { vinanti, vinanti_bodyForm } from "../../../data/data";

const png_to_file = async ({
  dataUrl,
  fileName = "clipboard_img_" + Date.now(),
  mimeType = "image/png",
}: {
  dataUrl: string;
  fileName?: string;
  mimeType?: string;
}) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: mimeType });
};

type Props = {};

const CloudImageAdder = (props: Props) => {
  const [store_location, setStore_location] = useState("pro-admin-io/link-io");
  const [img_file_name, setImg_file_name] = useState(null);
  const [img_file, setImg_file] = useState(null);
  const [img_clipboard_png, setImg_clipboard_png] = useState(null);
  const [isclipboardPNG, toggleClipboardPNG] = useReducer(
    (isclipboardPNG) => !isclipboardPNG,
    false,
  );
  const [img_file_uploaded, setImg_file_uploaded] = useState(false);
  const [name_availability, setName_availability] = useState("invalid"); // loading // success // invalid

  const user_info = useAppSelector((state) => state.user_info);

  const submit_file = async (file: any, isPng = false) => {
    var bodyFormData = new FormData();
    if (isPng) file = await png_to_file({ dataUrl: img_clipboard_png || "" });
    bodyFormData.append("file", file);
    bodyFormData.append("email", user_info.user_email);
    bodyFormData.append("fileName", store_location + img_file_name);

    const res = await vinanti_bodyForm("new", "storage", bodyFormData, {
      "Content-Type": "multipart/form-data",
    });
    if (res) {
      if (res === "OK") {
        setImg_file_uploaded(true);
        setImg_file(null);
        setImg_clipboard_png(null);
        setName_availability("invalid"); // loading // success // invalid
      } else console.log("file Upload failed!");
    }
  };

  const clipboard_to_img_local = async (func: any) => {
    var permissions = [
      "image/bmp",
      "image/gif",
      "image/png",
      "image/jpeg",
      "image/tiff",
    ];
    const clipboardItems = await navigator.clipboard.read();
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === "text/plain") {
          return func(await navigator.clipboard.readText());
        }
        if (permissions.includes(type)) {
          const blob = await clipboardItem.getType(type);
          if (window.FileReader) {
            let reader = new FileReader();
            reader.onload = () => {
              console.log(reader.result);
              func(reader.result);
              return reader.result;
            };
            reader.onerror = () => {
              func(null);
              return null;
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  const set_img_file_name = async (name: any) => {
    setImg_file_name(name);
    setName_availability("loading");

    const req: any = async () =>
      await vinanti("exists", "storage", user_info.auth_token, {
        file: "pro-admin-io/link-io/" + name,
      });

    const res: any = await req();

    if (res) {
      if (!res?.status) {
        setName_availability("success");
      } else setName_availability("invalid");
    }
  };
  return (
    <div className="flex flex-wrap items-center gap-1 rounded-md bg-white/50 p-1 backdrop-blur-md">
      <SelectOptions
        onClick={setStore_location}
        default_value="pro-admin-io/link-io/"
        options={[
          "pro-admin-io/profile-io/profiles/",
          "pro-admin-io/link-io/",
          "pro-admin-io/profile-io/Images/",
        ]}
      />
      <Input
        editOn={true}
        placeholder="Enter Image Name"
        value={img_file_name || ""}
        onchange={(value: any) => set_img_file_name(value)}
        className={`${
          name_availability === "success"
            ? "bg-green-200"
            : name_availability === "loading"
              ? " bg-blue-200"
              : "bg-red-200"
        }`}
      />

      <ToggleSwitch
        color="blue"
        size="sm"
        check={!isclipboardPNG}
        text="file"
        onChange={() => toggleClipboardPNG()}
      />
      {isclipboardPNG ? (
        <div
          style={{
            background: "white",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <MaterialIcon
            icon="content_paste_go"
            // onclick={() => clipboard_to_img_local(png_to_file)}
          />
          <Input
            editOn={true}
            placeholder="double tap paste"
            value={img_clipboard_png || ""}
            onchange={(value: any) => setImg_clipboard_png(value)}
            onDoubleClick={async () =>
              clipboard_to_img_local(setImg_clipboard_png)
            }
          />
          {name_availability === "success" ? (
            <Button
              text="Up-load"
              onclick={() => submit_file(img_file, true)}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <InputFile
          // submit={console.log}
          submit={submit_file}
          submit_bnt={name_availability === "success"}
          upload_done={img_file_uploaded}
          width="420px"
        />
      )}
    </div>
  );
};

export default CloudImageAdder;
