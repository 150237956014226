import { link_add_to_list } from "../../store/actions/actionTypes";
import { store } from "../../store/store";
import { LinkSchema } from "../../type";
import { vinanti } from "../../data/data";

export const search_links_locally = async (links: string[]) => {
  const list = store.getState().links.list;
  let links_res: LinkSchema[] = [];
  let not_found: string[] = [];
  if (links.length && list?.length) {
    links.map((d) => {
      const ind = list.find((l: LinkSchema) => l._id === d);
      ind ? links_res.push(ind) : not_found.push(d);
      return null;
    });
    return { links_res: links_res, not_found: not_found };
  } else return { links_res: links_res, not_found: links };
};

export const search_links_online = async (links: string[]) => {
  let online_links: LinkSchema[] = [];
  if (links?.length) {
    online_links = await vinanti("list_limited", "link", null, {
      ids: links,
    });
    // console.log("online Links : ", online_links);
    store.dispatch({
      type: link_add_to_list,
      payload: [...online_links],
    });
  }
  return online_links;
};

export const search_links = async (links: string[]) => {
  const res = await search_links_locally(links);
  // console.log("Search Result", res);
  if (res.not_found.length) {
    const online_links = await search_links_online(res.not_found);
    if (!res.links_res.length) return [...res.links_res, ...online_links];
    else return online_links;
  }
  return res.links_res;
};
