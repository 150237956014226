

/**
 * @param {Object} aciton - return from actions
 */
// export const listUpdateReducer = (state = null, action) => {
export const storylistReducer = (state = null, action) => {
  switch (action.type) {
    case "STORY_PAGE_STORY_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const rituallistReducer = (state = null, action) => {
  switch (action.type) {
    case "RITUAL_PAGE_RITUAL_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const daylistReducer = (state = null, action) => {
  switch (action.type) {
    case "DAYS_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const metadataReducer = (state = null, action) => {
  switch (action.type) {
    case "META_DATA":
      return action.payload;
    default:
      return state;
  }
};
export const vinantilistReducer = (state = null, action) => {
  switch (action.type) {
    case "VINANTI_RES_LIST":
      return action.payload;
    default:
      return state;
  }
};

// export const StateUpdateReducer = (state = null, action) => {
export const storyCurrReducer = (state = null, action) => {
  switch (action.type) {
    case "STORY_CURR_ACTIVE":
      return action.payload;
    default:
      return state;
  }
};
// export const StateUpdateReducer = (state = null, action) => {
export const ritualCurrReducer = (state = null, action) => {
  switch (action.type) {
    case "RITUAL_CURR_ACTIVE":
      return action.payload;
    default:
      return state;
  }
};

export const twitterTrendsListReducer = (state = null, action) => {
  // console.log("Reducer >>  Task_Kist");
  // console.log("Task page List : ", state);
  switch (action.type) {
    case "HOME_PAGE_TTRENDS_LIST":
      return action.payload;
    default:
      return state;
  }
};

export const customSettingsReducer = (state = null, action) => {
  switch (action.type) {
    case "CUSTOM_SETTINGS":
      return action.payload;
    default:
      return state;
  }
};
export const profilesListReducer = (state = null, action) => {
  switch (action.type) {
    case "PROFILE_INFO_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const storyListReducer = (state = null, action) => {
  switch (action.type) {
    case "STORYIO_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const ritualListReducer = (state = null, action) => {
  switch (action.type) {
    case "RITUALIO_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const searchLinksListReducer = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_LINKS_LIST":
      return action.payload;
    default:
      return state;
  }
};
export const favLinksListReducer = (state = null, action) => {
  switch (action.type) {
    case "FAV_LINKS_IO_LIST":
      return action.payload;
    default:
      return state;
  }
};
