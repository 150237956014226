import React, { useState } from "react";
import { LinkIo } from "./LinkIO";
import Topics from "./topics";
import { SelectOptions } from "react-utility-yard";
import { useAppSelector } from "../../store/store";
import { wrapperViews } from "../../type";

type Props = {
  view?: wrapperViews;
};

const LinksIOWrapper = ({ view }: Props) => {
  const _view = useAppSelector((state) => state.links.wrapperView);
  const [View, setView] = useState(_view);
  return (
    <div className="h-full">
      <div className="fixed flex h-10 w-full gap-2">
        <SelectOptions options={["LINKIO", "TOPICS"]} onClick={setView} />
      </div>
      <div className="h-full pt-8">
        {View === "LINKIO" ? <LinkIo /> : <Topics />}
      </div>
    </div>
  );
};

export default LinksIOWrapper;
