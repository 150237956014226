import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { LoginTab } from "../login/login";
import { useDispatch, useSelector } from "react-redux";
import { selectPage } from "../../store/actions/index";
import UltimateSearchLayout from "../experiment/live_bar/live_index";
import Admin_logo from "./../../data/images/Admin_io_logo.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavTailwind(props) {
  const navigation = [
    { name: "Home", page: "Home" },
    { name: "Kardz", page: "Kardz" },
    { name: "Tasks", page: "Tasks" },
    { name: "Link-Io", page: "Link_io" },
    { name: "Profile-Io", page: "Profile_io" },
    { name: "Story-Io", page: "Story_io" },
    { name: "TimeLine-Io", page: "Timeline_io" },
  ];
  const navigation_unsorted = [
    { name: "Exacalidraw", page: "Exacalidraw" },
    { name: "LiveVerse", page: "LiveVerse" },
    { name: "PlannerX", page: "PlannerX" },
    { name: "Canvas", page: "Canvas" },
    { name: "Gallary", page: "Gallary" },
    { name: "Home2", page: "Home2" },
    { name: "Users", page: "Users" },
    { name: "Leetcode", page: "Leetcode" },
    // { name: "Time", page: "Time" },

    { name: "Projects", page: "Projects" },
    { name: "Ritual-Io", page: "Ritual_io" },
    { name: "iFrame", page: "IFrame" },
    { name: "Apps", page: "Apps" },
    { name: "Custom_Settings", page: "Custom_Settings" },
    { name: "Docs-Analyser", page: "Docs_analyser" },
  ];
  const dispatch = useDispatch();
  const page_info = useSelector((state) => state.page_live);
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto  px-2 sm:px-6 lg:px-8">
            {/* nav bar content */}
            <div className="relative flex h-10 items-center justify-center sm:justify-between">
              {/* collapsed menu */}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* logo and nav tabs wrapper */}
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                {/* logo */}
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={Admin_logo}
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    src={Admin_logo}
                    alt="Your Company"
                  />
                </div>
                {/* nav tabs wrapper */}
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        // href={""}
                        className={classNames(
                          item.page === page_info
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "flex flex-col justify-center rounded-md px-0 text-sm font-medium md:px-1 lg:py-2",
                        )}
                        onClick={() => dispatch(selectPage(item.page))}
                        aria-current={
                          item.page === page_info ? "page" : undefined
                        }
                      >
                        {item.name}
                      </div>
                    ))}
                    {/* unsorted menus */}
                    <Menu as="div" className="relative z-[100] ml-3 mt-0">
                      <div>
                        <Menu.Button>
                          <div
                            className={classNames(
                              false
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-1 py-2 text-sm font-medium",
                            )}
                          >
                            Unsorted
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {navigation_unsorted.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <div
                                  //   href="#"
                                  // key={item.name}
                                  className={classNames(
                                    item.page === page_info
                                      ? "bg-gray-200"
                                      : " hover:bg-gray-300 hover:text-white",
                                    "block px-4 py-2 text-sm text-gray-700",
                                  )}
                                  onClick={() =>
                                    dispatch(selectPage(item.page))
                                  }
                                >
                                  {item.name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
              <div className=" ml-2 flex-1">
                {page_info !== "Home" ? (
                  <UltimateSearchLayout />
                ) : (
                  <>
                    <div> </div>
                  </>
                )}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <LoginTab />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  //   href={item.href}
                  className={classNames(
                    item.page === page_info
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium",
                  )}
                  onClick={() => dispatch(selectPage(item.page))}
                  aria-current={item.page === page_info ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
