// import "./custom.css";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github_dark";

import React, { useState } from "react";
import { useEffect } from "react";
import { vinanti } from "../../data/data";
import { useDispatch, useSelector } from "react-redux";

import "draft-js/dist/Draft.css";
import { Button } from "../../components/utils/button";
import { MaterialIcon } from "../../components/utils/material_icon";
import { updateCustomSettings } from "../../store/actions";
import { Text } from "../../components/utils/text";
import { RootState } from "../../store/store";
// import { UserInfo } from "../../../interface";

export function CustomSettings() {
  const dispatch = useDispatch();
  const user_info: any = useSelector<RootState>((state) => state.user_info);
  const indxs: any = useSelector<RootState>(
    (state) => state.custom_settings?.custom_settings_indexes,
  );
  const [error, setError] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [index_list, setIndex_list] = useState<any[]>(indxs);
  const [selected_index, setSelected_index] = useState(undefined);
  const [custom_data, setCustom_data] = useState("{}");
  const [loading_state, setLoading_state] = useState(false);

  const [new_index_name, setNew_index_name] = useState("");

  const add_new_custom = async () => {
    // console.log("new custom added");
    setLoading_state(true);
    await vinanti("new", "custom", user_info.auth_token, {
      email: user_info.user_email,
      updated_info: {
        name: new_index_name,
        data: [],
      },
    });
    get_custom_list();

    setNew_index_name("");
    setIndex_list(indxs);
    setSelected_index(index_list?.[0].id);
    setLoading_state(false);
  };

  const get_custom_data = async () => {
    setLoading_state(true);
    const data = await vinanti("get_id", "custom", user_info.auth_token, {
      id: selected_index,
      email: user_info.email,
    });
    if (data && data.length) {
      setCustom_data(JSON.stringify(data[0], undefined, 4));
    }
    setLoading_state(false);
    console.log(custom_data);
  };

  const get_custom_list = async () => {
    let data = await vinanti("list", "custom", user_info.auth_token, {
      email: user_info.user_email,
    });
    dispatch(updateCustomSettings(data?.[0]));
  };

  const handleCustomData = (data: any) => {
    let raw_data = data;
    try {
      raw_data = JSON.stringify(JSON.parse(raw_data), null, 4);
      setError(null);
    } catch (err) {
      // console.log(err);
      setError(String(err));
    } finally {
      setCustom_data(raw_data);
    }
  };

  const save_settings = async () => {
    setLoading_state(true);
    let data = JSON.parse(custom_data);
    await vinanti("update", "custom", user_info.auth_token, {
      updated_info: data,
      email: user_info.user_email,
    });
    setIsEdit(false);
    setLoading_state(false);
    // console.log("description Updated Success.", data);
  };
  useEffect(() => {
    setIndex_list(indxs);
    // console.log("im offeced");
    setSelected_index(index_list?.[0].id);
  }, [indxs, index_list]);

  useEffect(() => {
    get_custom_list();
  }, []);

  // console.log(JSON.stringify(custom_data, null, 4));
  return (
    <div className="flex h-full flex-col">
      <div className="m-1 flex flex-col items-center justify-center">
        <div className="flex w-[500px] items-center ">
          <select
            className="form-select"
            aria-label="Default select example"
            value={selected_index}
            onChange={(e: any) => setSelected_index(e.target.value)}
          >
            {index_list?.map((l, i) => (
              <option value={l.id} selected={selected_index === l.id}>
                {i + ". " + l.name}
              </option>
            ))}
          </select>

          <Button
            text="Fetch_Data"
            onclick={() => get_custom_data()}
            loading={loading_state}
          />
        </div>
        <div className="flex items-center">
          <Text
            isEdit={true}
            width="200px"
            placeholder={"new index name"}
            value={new_index_name}
            onChange={(e: any) => setNew_index_name(e.target.value)}
          />
          <Button
            text="Add_New"
            onclick={() => add_new_custom()}
            loading={loading_state}
          />
        </div>
      </div>

      <div className=" w-fit rounded-md bg-white px-1">
        {!isEdit ? (
          <MaterialIcon icon={"edit"} onclick={() => setIsEdit(true)} />
        ) : (
          <>
            <MaterialIcon icon="save" onclick={() => save_settings()} />
            <MaterialIcon icon="cancel" onclick={() => setIsEdit(false)} />
          </>
        )}
      </div>
      <div className="flex flex-1 flex-col">
        <AceEditor
          className="definition-tab-ace-editor"
          value={custom_data}
          onChange={handleCustomData}
          readOnly={!isEdit}
          width="100%"
          height="auto"
          mode="json"
          theme="github_dark"
          setOptions={{ useWorker: false }}
        />
        {error && <div className="bg-white p-1 pl-2">{error}</div>}
      </div>
    </div>
  );
}
