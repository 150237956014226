import { TasksState, TypePayloadAction } from "../../type";
import * as actionTypes from "../actions/actionTypes";

const tasks_init_state: TasksState = {
  list: [],
};

export const tasksReducer = (
  state: TasksState = tasks_init_state,
  action: TypePayloadAction,
): TasksState => {
  switch (action.type) {
    case actionTypes.tasks_update_list:
      let new_list: any[] = [];
      if (action.payload && action.payload.length > 0)
        new_list = [...action.payload];
      return { ...state, list: new_list };

    case actionTypes.tasks_new_task:
      if (state.list.length > 0) {
        return { ...state, list: [action.payload, ...state.list] };
      } else return { ...state, list: [action.payload] };

    case actionTypes.tasks_update_task_to_list:
      let nlist = state.list.map((m) =>
        m._id === action.payload._id ? action.payload : m,
      );
      return { ...state, list: [...nlist] };

    case actionTypes.tasks_delete_task_from_list:
      let del_list = state.list.filter((m) => m._id !== action.payload._id);
      return { ...state, list: [...del_list] };

    default:
      return { ...state };
  }
};
