import { Button } from "../../components/utils/button";
import React, { useState } from "react";
import { Text } from "../../components/utils/text";
import { vinanti } from "../../data/data";
import { useSelector } from "react-redux";

export const IFrame = ({ link = null, width = "100%", height = "100%" }) => {
  const [input_src, setInputSrc] = useState(null);
  const [input_link, setInputLink] = useState(link);
  const [results, setResults] = useState([]);
  const user_info = useSelector((state) => state.user_info);

  const cancel = () => {
    setInputLink(null);
    setInputSrc(null);
  };
  const clear = () => {
    // setInputLink(null);
    // setInputSrc(null);
    setResults([]);
  };
  const search_Iframe = async (src) => {
    var list = await vinanti("search", "link", user_info.auth_token, {
      email: user_info.user_email,
      search: src,
      type: "iframe", // not implemented yet
    }).then((res) => {
      let new_ls = [];
      if (res.length > 0) {
        new_ls = res.map((m) => (
          <div
            onClick={() => setInputLink(m.iframe?.src)}
            style={{ cursor: "pointer" }}
          >
            {m.link_name}
          </div>
        ));
      }
      return new_ls;
    });
    // console.log(list);
    setResults(list);
  };

  const onChange = (e) => {
    setInputSrc(e);
    if (e === "") {
      setResults([]);
    } else search_Iframe(e);
  };

  return (
    <>
      <div className="group flex fixed justify-center mt-2 h-1 w-1 left-1/2">
        <div
          className="flex fixed justify-center items-center mx-auto opacity-5 hover:opacity-100 transition transform 
            -translate-y-1 ease-in-out hover:translate-y-0 "
        >
          <Text
            title="Link"
            isEdit={true}
            width="450px"
            placeholder="Enter Link "
            value={input_src}
            onChange={(e) => onChange(e.target.value)}
            isResults={true}
            results={results}
          />
          <Button text="Iframe" onclick={() => setInputLink(input_src)} />
          <Button text="Clear" onclick={() => clear()} />
          <Button text="X" onclick={() => cancel()} />
        </div>
      </div>
      {input_link ? (
        <>
          <iframe
            title="Iframe Site Viewer"
            // src="https://calendar.google.com/calendar/embed?src=vinfinitysailor%40gmail.com&ctz=Asia%2FKolkata"
            // src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKolkata&src=dmluZmluaXR5c2FpbG9yQGdtYWlsLmNvbQ&src=MWYyZjA1MGJhZTNkNzMxOWZjZTY0MGJiYmI4YzU2NWJjZTkzOTVkNjY2MjdlYjA0Nzk0Nzc0MzAwMWY0YzAyNEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZmFtaWx5MTQxNDQzNDgxMzYzNDE4MzY1NTZAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZW4uaW5kaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZWZjYWpsbnF2ZHFqZW91ZDJzcHNpcGhucWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=azIzajIzM2d0Y3ZhdTdhOHVsazJwMzYwbTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23039BE5&color=%23A79B8E&color=%2333B679&color=%23009688&color=%230B8043&color=%237CB342&color=%23616161"
            src={input_link}
            //   style={{border: 0, width:"auto", height:"auto"}}
            width={width}
            height={height}
            frameborder="0"
            //   scrolling="no"
          ></iframe>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
