import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data.js";
import { Loading } from "../../components/utils/loading.js";
import { RootState } from "../../store/store.ts";
import { Button, Input } from "react-utility-yard";
import { MaterialIcon } from "react-utility-yard";

interface prop {
  list_name?: string;
  id?: string;
}

export const ClipBoardLinks: FC<prop> = ({
  list_name = "Raw_Clipboard",
  id = "65f1dd00e109677ee4cf09df",
}: prop) => {
  list_name = list_name ?? "Duck";
  const [items, setItems] = useState<any[]>([]);
  const [text_input, setText_input] = useState<any>({
    link_name: "",
    link: "",
  });
  const [loading_state, setLoading_state] = useState(false);
  const user_info = useSelector((state: RootState) => state.user_info);

  const add_new = (item: object = {}) => {
    setItems([...items, text_input]);
    setText_input({
      link_name: "",
      link: "",
    });
  };

  const save_list = async () => {
    setLoading_state(true);
    let data = {
      _id: id,
      name: list_name,
      data: items,
    };
    await vinanti("update", "custom", user_info.auth_token, {
      updated_info: data,
      email: user_info.user_email,
    });
    setLoading_state(false);
  };

  const get_list = async () => {
    setLoading_state(true);
    let data: any = await vinanti("get_id", "custom", user_info.auth_token, {
      id: id,
      email: user_info.email,
    });
    if (data) {
      setItems(data[0]?.data);
      // console.log(data);
    }
    setLoading_state(false);
  };

  useEffect(() => {
    // console.log("useeffect Ran", Date.now());
    get_list();
    return () => {
      setItems([]);
      list_name = "Duck";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" min-h-auto max-h-[350px]  rounded bg-white p-2">
      <div className="flex bg-slate-400  pl-2">
        {list_name}
        {loading_state && <Loading size_num={25} />}
      </div>
      <div className="m-2 max-h-[220px] max-w-[350px] overflow-scroll">
        {items?.length > 0 && (
          <ul className=" [&>*:nth-child(even)]:bg-slate-200">
            {items.map((m) => (
              <li className="px-1">
                <div className="flex justify-between gap-2">
                  <div>{m.link_name}</div>
                  <div className="flex gap-3">
                    <MaterialIcon
                      icon="open_in_new"
                      cursor="pointer"
                      onClick={() => window.open(m.link)}
                    />
                    <MaterialIcon
                      icon="content_copy"
                      cursor="pointer"
                      onClick={async () =>
                        await navigator.clipboard.writeText(m.link)
                      }
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="m-1 rounded-md bg-pink-200">
        <div className="mx-1 flex gap-1  p-1">
          <Input
            className="w-32"
            editOn={true}
            size="sm"
            value={text_input.link_name}
            onchange={(value: any) =>
              setText_input({ ...text_input, link_name: value })
            }
            placeholder={"New " + list_name.split(" ")[0]}
          />
          <Input
            className="w-40"
            editOn={true}
            size="sm"
            value={text_input.link}
            onchange={(value: any) =>
              setText_input({ ...text_input, link: value })
            }
            placeholder={"Add link "}
          />
        </div>
        <div className="mx-1 flex gap-1 p-1">
          <Button
            text="Add"
            onclick={() => add_new()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
          <Button
            text="Save"
            onclick={() => save_list()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
          <Button
            text="Get List"
            onclick={() => get_list()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
        </div>
      </div>
    </div>
  );
};
