import  { useState } from "react";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data";
import { StoryEditor } from "../../pages/Story_io/story_editor";
import { Button } from "./button";
import "./css/modal.css";

export const Modal = ({ handleClose, handleConfirm, children }) => {
  // const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={"modal display-block"}>
      <section className="modal-main">
        {children}
        <Button text={"Confirm"} onclick={handleConfirm} />
        <Button text={"CLose"} onclick={handleClose} />
      </section>
    </div>
  );
};

export const DuckStoryModel = ({
  display = true,
  func = null,
  skip = null,
}) => {
  const user_info = useSelector((state) => state.user_info);
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(false);
  const get_story = async () => {
    setLoading(true);
    await vinanti("get", "story", user_info.auth_token, {
      email: user_info.user_email,
      story_id: "63ad876e3eb8692c2897cd22",
    }).then((res) => {
      console.log("duclk", res);
      if (!res) setStory(null);
      setStory(res[0]);
    });
    setLoading(false);
  };

  return (
    <>
      {display ? (
        <div className={"modal display-block "}>
          <section className="modal-story">
            {story ? <StoryEditor story={story} /> : <></>}
            <Button text={"get Story"} onclick={() => get_story()} />
            {/* <Button text={"Accept"} onclick={func} /> */}
            <Button
              text={"Skip"}
              loading = {loading}
              onclick={() => {
                setStory(null);
                skip();
              }}
            />
          </section>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
