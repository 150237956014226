// import { Button } from "./button";
import { MaterialIcon } from "./material_icon";
import { useState, useEffect } from "react";
import "./css/text_to_speech.css";

export const TextToSpeech = ({ txt = null }) => {
  const synth = window.speechSynthesis;
  const [speechOn, setSpeechOn] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [voices, setVoices] = useState(synth ? synth.getVoices() : null);
  const [curr_voice, setCurr_voice] = useState(voices ? 0 : null);

  const bug_fix = (time) =>
    setTimeout(async () => {
      console.log("to be paused");
      synth.pause();
      synth.resume();
      bug_fix(14 * 1000);
    }, time);

  const speak = async () => {
    setSpeechOn(true);
    if (!window.speechSynthesis) {
      alert("Your browser don't support speech.");
      return;
    }
    let text = txt;
    if (!text) {
      text = "No text found";
    }

    var utrc = new SpeechSynthesisUtterance();
    utrc.text = text;
    if (curr_voice !== null) {
      utrc.voice = voices[curr_voice];
      utrc.lang = voices[curr_voice].lang;
    } else {
      console.log("curr lang error");
    }
    // console.log(voices);

    utrc.addEventListener("end", () => cancel_play());

    synth.speak(utrc);

    bug_fix(14 * 1000);
    // if(curr_voice > 4){
    // }

    setIsPause(false);
  };

  const toggle_play = () => {
    if (isPause) {
      synth.resume();
      setIsPause(false);
      //   bug_fix(14 * 1000);
    } else {
      clearTimeout(bug_fix);
      synth.pause();
      setIsPause(true);
    }
  };
  const cancel_play = () => {
    setSpeechOn(false);
    setIsPause(true);
    synth.cancel();
    clearTimeout(bug_fix);
  };

  useEffect(() => {
    setVoices(synth.getVoices());
  }, [txt]);

  return (
    <>
      <div className="text-to-speech">
        {!speechOn ? (
          <MaterialIcon
            cursor={"pointer"}
            icon="volume_up"
            onclick={() => speak()}
          />
        ) : (
          <div>
            <MaterialIcon
              cursor={"pointer"}
              icon={isPause ? "play_arrow" : "pause"}
              onclick={() => {
                toggle_play();
              }}
            />
            <MaterialIcon
              cursor={"pointer"}
              icon="cancel"
              onclick={() => cancel_play()}
            />
          </div>
        )}
        {voices ? (
          <div>
            <select
              class="form-select"
              aria-label="Default select example"
              value={curr_voice}
              onChange={(e) => setCurr_voice(e.target.value)}
            >
              {voices.map((l, i) => (
                <option
                  key={`voices-key-${i}`}
                  value={i}
                  selected={curr_voice === i}
                >
                  {i + ". " + l.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
