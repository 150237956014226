const KardzApp = () => {
  return (
    <iframe
      title="Iframe Site Viewer"
      src={"https://kardz.infinitysailor.com/korean/"}
      width={"100%"}
      height={"100%"}
    ></iframe>
  );
};

export default KardzApp;
