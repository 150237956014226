import * as React from "react";
import { State, BehaviourTree, FlattenedTreeNode } from "mistreevous";
import { toast } from "react-toastify";

import "./App.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

import { CanvasElements, MainPanel } from "./MainPanel";
import { BoardTab } from "./BoardTab";
import { DefinitionTab } from "./DefinitionTab";
import { ExamplesMenu } from "./ExamplesMenu";
import { Example, Examples } from "./Examples";

import GithubIcon from "./workflo/icons/github-circle.png";
import { MaterialIcon } from "../../../components/utils/material_icon";

import UtilDialog from "../../../components/utils/ts/dialog";
export enum SidebarTab {
  Definition = 0,
  Board = 1,
}

/**
 * The App component state.
 */
export type AppState = {
  layoutId: string | null;
  activeSidebarTab: SidebarTab;
  definiton: string;
  board: string;
  boardExceptionMessage: string;
  behaviourTree: typeof BehaviourTree | null;
  behaviourTreeExceptionMessage: string;
  behaviourTreePlayInterval: NodeJS.Timer | null;
  canvasElements: CanvasElements;
  sync: boolean;
};

/**
 * The App component.
 */

export class App extends React.Component<
  { ritual: any; onSave: any },
  AppState
> {
  /**
   * Creates the App element.
   * @param props The control properties.
   */
  public constructor(props: any) {
    super(props);

    // Set the initial state for the component.
    this.state = {
      layoutId: this.props.ritual.ritual_name,
      sync: true,
      activeSidebarTab: SidebarTab.Definition,
      definiton: this.props.ritual.defination, // definiton // defination / definition
      board: this.props.ritual.agent,
      boardExceptionMessage: "",
      behaviourTree: null,
      behaviourTreeExceptionMessage: "",
      behaviourTreePlayInterval: null,
      canvasElements: { nodes: [], edges: [] },
    };

    const pressedKeyCodes: any = {} as any;

    window.onkeyup = (event) => {
      pressedKeyCodes[event.key] = false;
    };
    window.onkeydown = (event) => {
      pressedKeyCodes[event.key] = true;
    };

    // BehaviourTree.register("IsKeyDown", (agent: any, keyy: string) => !!pressedKeyCodes[keyy]);

    this._onDefinitionChange = this._onDefinitionChange.bind(this);
    this._onBoardChange = this._onBoardChange.bind(this);
    this._onExampleSelected = this._onExampleSelected.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  /**
   * Renders the component.
   */
  public render(): React.ReactNode {
    const isSidebarReadOnly = !!this.state.behaviourTreePlayInterval;

    return (
      <Box className="app-box">
        <AppBar position="static">
          <Toolbar variant="dense">
            <ExamplesMenu onExampleSelected={this._onExampleSelected} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Ritual-Io
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {this.state.layoutId}
            </Typography>
            {/* <ExamplesMenu onExampleSelected={this._onExampleSelected} /> */}
            <div
              onClick={this._onSubmit}
              className={`${
                this.state.sync ? "bg-green-400" : "bg-red-700"
              } mb-1 h-10 rounded-md p-1`}
            >
              {/* {this.state.sync ? "" : "Save"} */}
              <MaterialIcon icon="save" cursor="pointer" size="30px" />
            </div>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              href="https://github.com/nikkorn/mistreevous"
            >
              <img width="30px" src={GithubIcon} alt="Github" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid
            container
            item
            className={`sidebar ${isSidebarReadOnly ? "read-only" : ""}`}
            xs={3}
            direction="column"
          >
            <DefinitionTab
              value={this.state.definiton}
              onChange={this._onDefinitionChange}
              errorMessage={this.state.behaviourTreeExceptionMessage}
              readOnly={!!this.state.behaviourTreePlayInterval}
            />
            <BoardTab
              value={this.state.board}
              onChange={this._onBoardChange}
              errorMessage={this.state.boardExceptionMessage}
              readOnly={!!this.state.behaviourTreePlayInterval}
            />
          </Grid>
          <Grid item xs={9}>
            <MainPanel
              layoutId={this.state.layoutId}
              elements={this.state.canvasElements}
              showPlayButton={
                !!this.state.behaviourTree &&
                !this.state.behaviourTreePlayInterval
              }
              showReplayButton={!!this.state.behaviourTreePlayInterval}
              showStopButton={!!this.state.behaviourTreePlayInterval}
              onPlayButtonClick={() => this._onPlayButtonPressed()}
              onReplayButtonClick={() => this._onPlayButtonPressed()}
              onStopButtonClick={() => this._onStopButtonPressed()}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  /**
   * Runs after the first render() lifecycle.
   */
  public componentDidMount() {
    const currentUrl = new URL(window.location.href);
    const exampleParamValue = currentUrl.searchParams.get("example");
    this._onDefinitionChange(this.props.ritual.defination);
    this._onBoardChange(this.props.ritual.agent);
    this.setState({ sync: true });

    if (exampleParamValue) {
      const example = Examples.find((item) => item.name === exampleParamValue);
      if (example) {
        setTimeout(() => this._onExampleSelected(example), 10);
      }
    }
  }
  public componentDidUpdate(prevProps: any) {
    // if (
    //   this.state.board === this.props.ritual.agent &&
    //   this.state.definiton === this.props.ritual.defination
    // )
    //   this.setState({ sync: true });
    // else this.setState({ sync: false });
    if (prevProps.ritual.ritual_name !== this.props.ritual.ritual_name) {
      let example = { ...this.props.ritual };
      example.definition = example.defination;
      example.board = example.agent;
      example.caption = example.ritual_name;
      this._onExampleSelected(example);
    }
  }

  /**
   * Handles a change of definition.
   * @param definition
   */
  private _onDefinitionChange(definition: string): void {
    if (
      this.state.board === this.props.ritual.agent &&
      definition === this.props.ritual.defination
    )
      this.setState({ sync: true });
    else this.setState({ sync: false });

    let behaviourTreeExceptionMessage = "";
    let canvasElements: CanvasElements = { nodes: [], edges: [] };

    try {
      // Try to create the behaviour tree.
      const behaviourTreeVal = new BehaviourTree(
        definition,
        {} /** We don't need aboard here as we are only validating the definition. */,
      );

      canvasElements = this._parseNodesAndConnectors(
        (behaviourTreeVal as any).getFlattenedNodeDetails(),
      );
    } catch (error) {
      behaviourTreeExceptionMessage = `${(error as any).message}`;
    }

    const behaviourTree = this._createTreeInstance(
      definition,
      this.state.board,
    );

    this.setState({
      definiton: definition,
      behaviourTreeExceptionMessage: behaviourTreeExceptionMessage,
      canvasElements: canvasElements,
      behaviourTree: behaviourTree,
    });
  }

  /**
   * Handles a change of board class definition.
   * @param board
   */
  private _onBoardChange(boardClassDefinition: string): void {
    if (
      boardClassDefinition === this.props.ritual.agent &&
      this.state.definiton === this.props.ritual.defination
    )
      this.setState({ sync: true });
    else this.setState({ sync: false });
    let boardExceptionMessage = "";

    try {
      // Attempt to create a board instance, we just want to know if we can.
      this._createBoardInstance(boardClassDefinition);
    } catch (error) {
      boardExceptionMessage = `${(error as any).message}`;
    }

    const behaviourTree = this._createTreeInstance(
      this.state.definiton,
      boardClassDefinition,
    );

    this.setState({
      board: boardClassDefinition,
      boardExceptionMessage: boardExceptionMessage,
      behaviourTree: behaviourTree,
    });
  }
  /**
   * Handles a change of board class definition.
   * @param board
   */
  private _onSubmit(): void {
    let ritual = { ...this.props.ritual };
    ritual.agent = this.state.board;
    ritual.defination = this.state.definiton;
    this.props.onSave(ritual);
    this.setState({ sync: true });
  }

  /**
   * Handles an example being selected.
   * @param example The selected example.
   */
  private _onExampleSelected(example: Example): void {
    const behaviourTree = this._createTreeInstance(
      example.definition,
      example.board,
    );
    const canvasElements = this._parseNodesAndConnectors(
      (behaviourTree as any).getFlattenedNodeDetails(),
    );

    this.setState({
      layoutId: example.caption,
      definiton: example.definition,
      board: example.board,
      behaviourTree,
      canvasElements,
    });
  }

  /**
   * Creates the behaviour tree instance.
   * @param definition
   * @param board
   * @returns The behaviour tree instance.
   */
  private _createTreeInstance(
    definition: string,
    boardClassDefinition: string,
  ): typeof BehaviourTree | null {
    try {
      // Create the board object.
      const board = this._createBoardInstance(boardClassDefinition);

      const options = {
        // We are calling step() every 100ms in this class so a delta of 0.1 should match what we expect.
        getDeltaTime: () => 0.1,
      };

      const behaviourTree = new BehaviourTree(definition, board, options);

      return behaviourTree;
    } catch (error) {
      return null;
    }
  }

  /**
   * Creates an instance of a board based on the class definition provided.
   * @param boardClassDefinition The board class definition.
   * @returns An instance of a board based on the class definition provided.
   */
  private _createBoardInstance(boardClassDefinition: string): any {
    const boardClassCreator = new Function(
      "BehaviourTree",
      "State",
      "getStringValue",
      "getNumberValue",
      "getBooleanValue",
      "showErrorToast",
      "showInfoToast",
      `return ${boardClassDefinition};`,
    );

    const getStringValue = (message: string) => window.prompt(message);
    // const dialog = (message : string) => {
    //   UtilDialog
    // }
    const getNumberValue = (message: string) =>
      parseFloat(window.prompt(message) as string);
    const getBooleanValue = (message: string) =>
      window.confirm(`${message}. (Ok=true Cancel=false)`);
    const showErrorToast = (message: string) => toast.error(message);
    const showInfoToast = (message: string) => toast.info(message);

    const boardClass = boardClassCreator(
      BehaviourTree,
      State,
      getStringValue,
      getNumberValue,
      getBooleanValue,
      showErrorToast,
      showInfoToast,
    );

    const boardInstance = new boardClass();

    return boardInstance;
  }

  /**
   * Parse the nodes and connectors.
   * @param flattenedNodeDetails
   * @returns The parsed nodes and connectors.
   */
  private _parseNodesAndConnectors(
    flattenedNodeDetails: (typeof FlattenedTreeNode)[],
  ): CanvasElements {
    let result: CanvasElements = { nodes: [], edges: [] };

    flattenedNodeDetails.forEach((flattenedNode) => {
      result.nodes.push({
        id: flattenedNode.id,
        caption: flattenedNode.caption,
        state: flattenedNode.state,
        type: flattenedNode.type,
        args: flattenedNode.args,
        callbacks: flattenedNode.callbacks,
        guards: flattenedNode.guards,
        variant: "default",
      } as any);

      if (flattenedNode.parentId) {
        let variant;

        switch (flattenedNode.state) {
          case State.RUNNING:
            variant = "active";
            break;

          case State.SUCCEEDED:
            variant = "succeeded";
            break;

          case State.FAILED:
            variant = "failed";
            break;

          default:
            variant = "default";
        }

        result.edges.push({
          id: `${flattenedNode.parentId}_${flattenedNode.id}`,
          from: flattenedNode.parentId,
          to: flattenedNode.id,
          variant,
        });
      }
    });

    return result;
  }

  private _onPlayButtonPressed(): void {
    const { behaviourTree, behaviourTreePlayInterval } = this.state;

    // There is nothing to de if we have no behaviour tree instance.
    if (!behaviourTree) {
      return;
    }

    // Reset the tree.
    behaviourTree.reset();

    // Clear any existing interval.
    if (behaviourTreePlayInterval) {
      clearInterval(behaviourTreePlayInterval);
    }

    // Create an interval to step the tree until it is finished.
    const playInterval = setInterval(() => {
      // Step the behaviour tree, if anything goes wrong we will stop the tree playback.
      try {
        behaviourTree.step();
      } catch (exception: any) {
        // Clear the interval.
        clearInterval(playInterval);
        this.setState({ behaviourTreePlayInterval: null });

        // Reset the tree.
        behaviourTree.reset();

        // Notify the user of the exception via a toast.
        toast.error(exception.toString());
      }

      // If the tree root is in a finished state then stop the interval.
      if (!behaviourTree.isRunning()) {
        // Clear the interval.
        clearInterval(playInterval);
        this.setState({ behaviourTreePlayInterval: null });
      }

      // var x: (typeof FlattenedTreeNode)[] =
      //   behaviourTree.getFlattenedNodeDetails();

      this.setState({
        canvasElements: this._parseNodesAndConnectors(
          behaviourTree.getFlattenedNodeDetails(),
        ),
      });
    }, 100);

    this.setState({ behaviourTreePlayInterval: playInterval });
  }

  private _onStopButtonPressed(): void {
    const { behaviourTree, behaviourTreePlayInterval } = this.state;

    behaviourTree?.reset();

    if (behaviourTreePlayInterval) {
      clearInterval(behaviourTreePlayInterval);
    }

    this.setState({
      behaviourTreePlayInterval: null,
      canvasElements: behaviourTree
        ? this._parseNodesAndConnectors(
            (behaviourTree as any).getFlattenedNodeDetails(),
          )
        : { nodes: [], edges: [] },
    });
  }
}
