import * as React from "react";
import { State, BehaviourTree } from "mistreevous";
import { toast } from "react-toastify";
import { MaterialIcon } from "../utils/material_icon";

export type ExampleCategory =
  | "misc"
  | "leaf"
  | "composite"
  | "decorator"
  | "guard-callback"
  | "global";

/**
 * An example definition and board commbination.
 */
export type Example = {
  name: string;
  caption: string;
  category: ExampleCategory;
  definition: string;
  board: string;
};

const example: Example = {
  name: "Rein",
  caption: "Sorting Lunch",
  category: "misc",
  definition: `root {
    repeat {
        sequence {
            wait [60000]
            action [Hello]
        }
    }
}`,
  board: `class Agent { 
    Hello(){ 
      console.log("Hello Rein"); 
      showInfoToast("Rein is Happening..."); 
      return State.SUCCEEDED; } }`,
};

/**
 * The App component state.
 */
export type AppState = {
  layoutId: string | null;
  definiton: string;
  board: string;
  boardExceptionMessage: string;
  behaviourTree: typeof BehaviourTree | null;
  behaviourTreeExceptionMessage: string;
  behaviourTreePlayInterval: NodeJS.Timer | null;
  sync: boolean;
};

/**
 * The App component.
 */

export class Rein extends React.Component<{}, AppState> {
  /**
   * Creates the App element.
   * @param props The control properties.
   */
  public constructor(props: any) {
    super(props);

    // Set the initial state for the component.
    this.state = {
      layoutId: "",
      sync: true,
      definiton: "", // definiton // defination / definition
      board: "",
      boardExceptionMessage: "",
      behaviourTree: null,
      behaviourTreeExceptionMessage: "",
      behaviourTreePlayInterval: null,
    };
    this._onExampleSelected = this._onExampleSelected.bind(this);
  }

  /**
   * Renders the component.
   */
  public render(): React.ReactNode {
    return (
      <div className=" my-[2px] flex items-center gap-1 rounded-sm  bg-white px-1">
        Rein
        {!this.state.behaviourTreePlayInterval ? (
          <MaterialIcon
            icon="play_arrow"
            onclick={() => this._onPlayButtonPressed()}
            cursor="pointer"
          />
        ) : (
          <MaterialIcon
            icon="stop"
            onclick={() => this._onStopButtonPressed()}
            cursor="pointer"
            size="16px"
          />
        )}
      </div>
    );
  }

  /**
   * Runs after the first render() lifecycle.
   */
  public componentDidMount() {
    this.setState({ sync: true });
    if (example) {
      setTimeout(() => this._onExampleSelected(example), 10);
    }
  }

  public componentDidUpdate(prevProps: any) {}

  /**
   * Handles an example being selected.
   * @param example The selected example.
   */
  private _onExampleSelected(example: Example): void {
    const behaviourTree = this._createTreeInstance(
      example.definition,
      example.board,
    );

    this.setState({
      layoutId: example.caption,
      definiton: example.definition,
      board: example.board,
      behaviourTree,
    });
  }

  private _onPlayButtonPressed(): void {
    const { behaviourTree, behaviourTreePlayInterval } = this.state;

    // There is nothing to de if we have no behaviour tree instance.
    if (!behaviourTree) {
      return;
    }

    // Reset the tree.
    behaviourTree.reset();

    // Clear any existing interval.
    if (behaviourTreePlayInterval) {
      clearInterval(behaviourTreePlayInterval);
    }

    // Create an interval to step the tree until it is finished.
    const playInterval = setInterval(() => {
      // Step the behaviour tree, if anything goes wrong we will stop the tree playback.
      try {
        behaviourTree.step();
      } catch (exception: any) {
        // Clear the interval.
        clearInterval(playInterval);
        this.setState({ behaviourTreePlayInterval: null });

        // Reset the tree.
        behaviourTree.reset();

        // Notify the user of the exception via a toast.
        toast.error(exception.toString());
      }

      // If the tree root is in a finished state then stop the interval.
      if (!behaviourTree.isRunning()) {
        // Clear the interval.
        clearInterval(playInterval);
        this.setState({ behaviourTreePlayInterval: null });
      }
    }, 100);

    this.setState({ behaviourTreePlayInterval: playInterval });
  }
  private _onStopButtonPressed(): void {
    const { behaviourTree, behaviourTreePlayInterval } = this.state;

    behaviourTree?.reset();

    if (behaviourTreePlayInterval) {
      clearInterval(behaviourTreePlayInterval);
    }

    this.setState({
      behaviourTreePlayInterval: null,
    });
  }

  /**
   * Creates the behaviour tree instance.
   * @param definition
   * @param board
   * @returns The behaviour tree instance.
   */
  private _createTreeInstance(
    definition: string,
    boardClassDefinition: string,
  ): typeof BehaviourTree | null {
    try {
      // Create the board object.
      const board = this._createBoardInstance(boardClassDefinition);

      const options = {
        // We are calling step() every 100ms in this class so a delta of 0.1 should match what we expect.
        getDeltaTime: () => 0.1,
      };

      const behaviourTree = new BehaviourTree(definition, board, options);

      return behaviourTree;
    } catch (error) {
      return null;
    }
  }

  /**
   * Creates an instance of a board based on the class definition provided.
   * @param boardClassDefinition The board class definition.
   * @returns An instance of a board based on the class definition provided.
   */
  private _createBoardInstance(boardClassDefinition: string): any {
    const boardClassCreator = new Function(
      "BehaviourTree",
      "State",
      "getStringValue",
      "getNumberValue",
      "getBooleanValue",
      "showErrorToast",
      "showInfoToast",
      `return ${boardClassDefinition};`,
    );

    const getStringValue = (message: string) => window.prompt(message);
    const getNumberValue = (message: string) =>
      parseFloat(window.prompt(message) as string);
    const getBooleanValue = (message: string) =>
      window.confirm(`${message}. (Ok=true Cancel=false)`);
    const showErrorToast = (message: string) => toast.error(message);
    const showInfoToast = (message: string) => toast.info(message);

    const boardClass = boardClassCreator(
      BehaviourTree,
      State,
      getStringValue,
      getNumberValue,
      getBooleanValue,
      showErrorToast,
      showInfoToast,
    );

    const boardInstance = new boardClass();

    return boardInstance;
  }
}
