import { Button } from "react-utility-yard";
import { vinanti } from "../../data/data";
import { get_date } from "../../components/utils/general_utils";
import { useEffect, useState } from "react";
import knowMe from "./knowMe.json";
import KnowMe from "./KnowMe";
import { TodaySchema } from "../../type";

const PlannerX = () => {
  const [Today, setToday] = useState<TodaySchema | null>(null);
  const get_today = async () => {
    let data = await vinanti("get", "today", null, {
      today: get_date(Date.now(), true),
    });
    // let { items } = await vinanti("new", "today", null, null);
    if (data && data.length) {
      setToday(data[0]);
      //   setEvents(data[0].items);
      // console.log(data[0]);
    } // return items;
  };
  const del_today = async () => {
    console.log("Deleting ...");
    let data = await vinanti("del", "today", null, {
      today_id: Today?._id,
    });
    // let { items } = await vinanti("new", "today", null, null);
    if (data && data.length) {
      // console.log(data);
      setToday(null);
    } // return items;
  };
  useEffect(() => {
    get_today();
    return () => {};
  }, []);

  return (
    <div>
      <KnowMe knowMe={knowMe} />
      <div>
        <Button text="Plan Today" onclick={get_today} />
        <Button text="Del Today" onclick={del_today} />
        <div className="bg-white p-2">{Today && JSON.stringify(Today)}</div>
      </div>
    </div>
  );
};

export default PlannerX;
