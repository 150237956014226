export const get_date = (date, year = false, hour_min = false) => {
  var data = new Date(date);
  // console.log(data.toLocaleDateString('en-US', options));
  var options = { weekday: "short", month: "short", day: "numeric" };
  if (year) options["year"] = "numeric";
  // if (hour_min)
  //   options = {
  //     ...options,
  //     hour: "numeric",
  //     // minute: "numeric"
  //   };
  // console.log(data.toLocaleDateString("en-US", options));
  return data.toLocaleDateString("en-US", options);
};
export const get_time = (date) => {
  var data = new Date(date);
  // console.log(data.toLocaleDateString('en-US', options));
  // var options = { weekday: "short", month: "short", day: "numeric" };

  return data.getHours() + ":" + data.getMinutes();
};
export const get_week_number = () => {
  var startDate = new Date(new Date().getFullYear(), 0, 1);
  var days = Math.floor((new Date() - startDate) / (24 * 60 * 60 * 1000));

  var weekNumber = Math.ceil(days / 7);
  return weekNumber + 1;
};

export const getDayNum = function () {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};
export const getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof dowOffset == "number" ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date(new Date().getFullYear(), 0, 1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = day >= 0 ? day : day + 7;
  var daynum =
    Math.floor(
      (new Date().getTime() -
        newYear.getTime() -
        (new Date().getTimezoneOffset() - newYear.getTimezoneOffset()) *
          60000) /
        86400000,
    ) + 1;
  var weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      let nYear = new Date(new Date().getFullYear() + 1, 0, 1);
      let nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
                the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;
};

export const get_date_diff = (date) => {
  var today = Date.now();
  var data = new Date(date);
  // get total seconds between the times
  var delta = Math.abs(today - data) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  // var options = { weekday: "short", month: "short", day: "numeric" };
  // console.log(data.toLocaleDateString('en-US', options));
  return days + "d ago";
};

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const get_curr_time_counter = (Due = new Date().toString()) => {
  const due = new Date(Due).getTime();
  const now = new Date();
  // console.log(now, due, Due);
  // const data = new Date(
  //   "Sun Apr 28 2024 02:53:52 GMT+0530 (India Standard Time)",
  // );
  // console.log(data, data.toString());

  // get total seconds between the times
  const delta = due - now;
  const diff = Math.abs(delta);
  // calculate (and subtract) whole days
  // Convert the difference to days, hours, minutes, and seconds.
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${delta < 0 ? "-" : ""} ${
    days > 0 ? days + " d" : ""
  } ${hours} h ${minutes} m ${seconds} s`;
};
