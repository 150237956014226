import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data";
import { Button } from "../../components/utils/button";
import { ProfileMini } from "./profile_mini";
import { RootState, useAppDispatch } from "../../store/store";
import { ProfileSchema, TypePayloadAction } from "../../type";
import {
  profile_set_curr_active,
  profile_update_list,
} from "../../store/actions/actionTypes";
import Input from "../../components/utils/ts/input";
import { MaterialIcon } from "../../components/utils/material_icon";

type filterState = "All" | "Fictional" | "Real";

export const MiniProfileList = () => {
  const [busy, setBusy] = useState(false);
  const list: ProfileSchema[] = useSelector(
    (state: RootState) => state.profile_io.list,
  );
  const [filtered_list, setFiltered_list] = useState(list);
  const [filter_state, setFilter_state] = useState<filterState>("All");
  const [searchProfile, setSearchProfile] = useState("");
  const [filter_state_input, setFilter_state_input] = useState({
    fictional: false,
    real: false,
  });

  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const filter = ({ option = filter_state }: { option: filterState }) => {
    let nlist: ProfileSchema[] = (searchProfile !== "" &&
      list.filter((m) =>
        m.profile_name.toLowerCase().includes(searchProfile.toLowerCase()),
      )) || [...list];
    switch (option) {
      case "Fictional":
        nlist = nlist.filter((m) => Number(m.isFictional));
        setFiltered_list(nlist);
        break;
      case "Real":
        nlist = nlist.filter((m) => !m.isFictional);
        setFiltered_list(nlist);
        break;
      default:
        setFiltered_list(nlist);
        break;
    }
  };

  const get_list = async () => {
    setBusy(true);
    var data = await vinanti("list", "profile", null, {});
    setBusy(false);
    dispatch_action({ type: profile_update_list, payload: data });
  };

  const set_curr_active = (profile: ProfileSchema) => {
    dispatch_action({ type: profile_set_curr_active, payload: profile });
  };
  const search_profiles = async () => {
    console.log(searchProfile);
  };
  useEffect(() => {
    if (!list || !list.length) {
      get_list();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filter({ option: filter_state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_state, searchProfile, list]);
  return (
    <>
      {/* Side Bar */}
      <div className="flex h-full flex-col bg-transparent backdrop-brightness-100">
        <div className=" my-2 flex flex-col items-center justify-center gap-1">
          <div className="mx-1 flex w-full items-center justify-center">
            <Input
              className="ml-2 w-full"
              size="md"
              placeholder="Search Profile"
              value={searchProfile}
              onchange={setSearchProfile}
              editOn={true}
            />
            <MaterialIcon
              icon={"search"}
              onclick={search_profiles}
              cursor="pointer"
            />
          </div>
          <div className="flex items-center justify-center gap-2 rounded-md bg-white pl-2">
            <input
              type="checkbox"
              checked={filter_state_input.real}
              onChange={() => {
                setFilter_state(
                  filter_state_input.fictional
                    ? !filter_state_input.real
                      ? "All"
                      : "Fictional"
                    : !filter_state_input.real
                      ? "Real"
                      : "All",
                );
                setFilter_state_input({
                  ...filter_state_input,
                  real: !filter_state_input.real,
                });
              }}
            />
            <h1 className="mb-1 ">Real</h1>
            <input
              type="checkbox"
              checked={filter_state_input.fictional}
              onChange={() => {
                setFilter_state(
                  !filter_state_input.fictional
                    ? filter_state_input.real
                      ? "All"
                      : "Fictional"
                    : filter_state_input.real
                      ? "Real"
                      : "All",
                );
                setFilter_state_input({
                  ...filter_state_input,
                  fictional: !filter_state_input.fictional,
                });
              }}
            />
            <h1 className="mb-1 ">Fictional</h1>

            <Button
              onclick={() => get_list()}
              text={"Get-Profiles"}
              loading={busy}
            />
          </div>
        </div>

        <div className="flex-1 overflow-scroll px-1">
          {filtered_list ? (
            filtered_list.map((d: any) => (
              <ProfileMini info={d} onclick={() => set_curr_active(d)} />
            ))
          ) : (
            <>No Profiles found</>
          )}
        </div>
      </div>
    </>
  );
};
