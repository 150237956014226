// import "./css/lexical.css";
// import "./src/styles.css";
import Editor from "./new/src";
import React, { useEffect, useState } from "react";

export const StoryEditor = ({ story = null }) => {
  const [story_local, setStory_local] = useState(story);

  const NewEditor = () => <Editor story={story_local} />;

  useEffect(() => {
    setStory_local(story);
  }, [story]);

  return (
    <>
      {story_local ? (
        <NewEditor />
      ) : (
        <>
          <Editor />
        </>
      )}
    </>
  );
};
