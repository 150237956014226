import React, { useEffect, useState } from "react";
import { Button, Input } from "react-utility-yard";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { vinanti } from "../../data/data";
import { Link } from "./link";
import { link_add_to_list } from "../../store/actions/actionTypes";

type Props = {};

const Topics = (props: Props) => {
  const [search_link_name, setSearch_link_name] = useState("");
  const [search_results, setSearch_results] = useState([]);

  var list = useAppSelector((state) => state.links.list);

  const dispatch = useAppDispatch();

  // const get_problems_List = async () => {
  //   // var data = await linksList(user_info.auth_token, user_info.user_email);
  //   var data = await vinanti("list", "link", user_info.auth_token, {
  //     email: user_info.user_email,
  //   });
  //   data.map((d: any, i: number) => {
  //     d.glink
  //       ? (data[i]["glinks_sorted"] = search_links_locally(
  //           d.glinks,
  //           (data = data),
  //         ))
  //       : console.log("no links found to update");
  //   });
  //   // console.log("Links List ", data);
  //   dispatch(updateLinksList(data));
  // };

  const search_links_online = async () => {
    var data =
      (await vinanti("search", "link", null, {
        search: search_link_name,
      })) || [];
    // data = data ? data : [];
    // data.map((d: any, i: number) => {
    //   d.glink
    //     ? (data[i]["glinks_sorted"] = search_links_locally(
    //         d.glinks,
    //         (data = data),
    //       ))
    //     : console.log("no links found to update");
    //   return null;
    // });
    dispatch({ type: link_add_to_list, payload: data });
    setSearch_results(data);
  };

  // const search_links_locally = (links: any, data = undefined) => {
  //   var res: any = [];
  //   var list_local: any;
  //   data ? (list_local = data) : (list_local = list);
  //   links
  //     ? links.map((d: any, i: number) => {
  //         const ind = list_local.find((l: any) => l._id === d);
  //         res.push(ind);
  //       })
  //     : console.log("empty Glink found");
  //   // console.log("search_links_locally", res);
  //   return res;
  // };

  useEffect(() => {}, [search_results]);

  return (
    <div className="h-full overflow-y-auto pt-3 backdrop-blur-lg">
      <div className="flex w-full flex-col items-center gap-2 lg:w-[50]">
        <div className="flex items-center gap-1">
          <Input
            editOn={true}
            // title="Search Link here..."
            type="text"
            placeholder="Search Link here"
            value={search_link_name}
            onchange={(e: any) => setSearch_link_name(e)}
            onkeyDown={(e: any) => {
              if (e.key === "Enter") {
                search_links_online();
              }
            }}
          />
          <Button onclick={() => search_links_online()} text="Search Link" />
        </div>
        <div className="flex items-center gap-1">
          <h1 className="rounded-md bg-white px-2">
            No. of Links: {list ? list.length : 0}
          </h1>
          <Button
            //  onclick={() => get_problems_List()}
            text="Get Links"
          />
        </div>
        <div className="mt-3 w-full overflow-y-scroll p-1">
          {search_results?.length ? (
            <div className="flex flex-col">
              <div className="my-1 flex items-center gap-2">
                <h4 className="m-1 w-fit rounded-md bg-gray-500 p-1 text-white">
                  {search_results.length} Search Results Found
                </h4>
                <Button text={"Clear"} onclick={() => setSearch_results([])} />
              </div>
              <ul className=" flex flex-col gap-1">
                {search_results.map((d, i) => (
                  <Link d={d} i={i} desc_on={true} />
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
          <h4 className="m-1 w-fit rounded-md bg-gray-500 p-1 text-white">
            {list.length} Links Available Locally
          </h4>
          <ul className=" mt-2 flex flex-col gap-1">
            {list ? (
              list.map((d, i) => (
                <Link
                  d={d}
                  i={i}
                  //   delete_model={() => openModal(d._id, d.link_name)}
                  desc_on={true}
                />
              ))
            ) : (
              <li className="flex items-center justify-center rounded-md bg-white py-1">
                No Data Found -_-
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Topics;
