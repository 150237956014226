import React, { useState, useEffect, useCallback } from "react";
import { vinanti } from "../../data/data.js";
// import { Button } from "../../utils/button.tsx";
import { Loading } from "../../components/utils/loading.js";
import { ImgGallery } from "../../components/experiment/image_gallary.js";
import UserProfile from "./user_profile.tsx";
import { LinkIdAdder } from "../Link_io/link_adder.js";
import { Button, SideBar } from "react-utility-yard";
import { MiniProfileList } from "./mini_profile_list.tsx";
import { NewProfile } from "./new_profile.tsx";
import {
  ProfileExpSchema,
  TypePayloadAction,
  insightSchama,
} from "../../type";
import { RootState, useAppDispatch } from "../../store/store.ts";
import { useSelector } from "react-redux";
import { profile_set_curr_exp } from "../../store/actions/actionTypes.ts";
import Input from "../../components/utils/ts/input.tsx";
import { MaterialIcon } from "../../components/utils/material_icon.js";
import { Link } from "../Link_io/link.tsx";

export function ProfileIo() {
  const _profileExp: ProfileExpSchema = {
    virtual_ID: "", // vitual id information
    gallary: [], // links to images
    PD: "", //Personality descriptor
    insights: [{ key: "hatori", value: "hinata" }], //short description
    observations: [],
  };
  const curr_profile_exp =
    useSelector((state: RootState) => state.profile_io.curr_profile_exp) ||
    _profileExp;

  const [profileExp, setCurr_profile_exp] = useState(curr_profile_exp);

  const {
    virtual_ID, // vitual id information
    gallary, // links to images
    PD, //Personality descriptor
    insights, //short description
    observations,
  } = profileExp;

  const [gallary_loading, setGallary_loading] = useState(false);
  const curr_profile = useSelector(
    (state: RootState) => state.profile_io.curr_profile,
  );
  const dispatch = useAppDispatch();
  const dispatch_action = useCallback(
    ({ type, payload }: TypePayloadAction) =>
      dispatch({ type: type, payload: payload }),
    [dispatch],
  );

  const get_profile_exp = async ({ id = null }: { id?: string | null }) => {
    setGallary_loading(true);
    if (!id && curr_profile?._v_id) id = curr_profile?._v_id;

    let data = await vinanti("get_exp", "profile", null, {
      id: id,
    });
    if (data?.length) {
      setCurr_profile_exp(data[0]);
      dispatch_action({ type: profile_set_curr_exp, payload: data[0] });
    }
    setGallary_loading(false);
  };

  const update_profile_exp = async () => {
    let data = await vinanti("update_exp", "profile", null, {
      updated_info: { ...profileExp },
    });
    if (data) {
      dispatch_action({
        type: profile_set_curr_exp,
        payload: { ...profileExp },
      });
    }

    // get_list();
    // setIsprofile_exp_edit(false);
  };
  const update_insights = (insight: typeof insightSchama) => {
    if (typeof insights !== "string") {
      let new_insights = insights.find((m) => m.key === insight.key)
        ? insights.map((m) => {
            if (m.key === insight.key) {
              return insight;
            } else return m;
          })
        : [...insights, insight];
      setCurr_profile_exp({ ...profileExp, insights: [...new_insights] });
    }
  };
  const add_link_to_observations = (link: string) => {
    let obsr = Array.isArray(observations) ? observations : [];
    if (!obsr.includes(link)) {
      setCurr_profile_exp({ ...profileExp, observations: [link, ...obsr] });
    }
  };

  useEffect(() => {}, [gallary]);
  useEffect(() => {
    get_profile_exp({ id: null });
    return () => {};
  }, [curr_profile]);

  return (
    <div className="flex h-full max-h-full flex-row justify-between">
      <SideBar
        sideBar_list={<MiniProfileList />}
        leftside={false}
        title={"Profiles List"}
        width={"w-[20rem]"}
        titleBarColor="bg-transparent"
      >
        {/* Main container */}
        <div className="flex max-h-full w-full flex-col">
          {/* Add new Profile wrapper. */}
          <NewProfile />
          {/* Profile Info wrapper*/}
          <div className="flex flex-1 flex-wrap justify-between overflow-hidden">
            {/*  profile Info*/}
            <div className="flex flex-col overflow-hidden">
              {/* User profile card */}
              <UserProfile />
              <div className=" flex flex-col rounded-b-xl pb-1 backdrop-brightness-50">
                <div className="mx-2 flex items-center justify-between">
                  <h1 className="m-1 text-lg font-semibold text-green-400">
                    Insights
                  </h1>
                  <Button
                    text="Update"
                    onclick={() => {
                      update_profile_exp();
                    }}
                  />
                </div>

                {typeof insights !== "string" ? (
                  insights?.length &&
                  insights.map((m) => (
                    <InsightItem insight={m} updateInsight={update_insights} />
                  ))
                ) : (
                  <div>{insights}</div>
                )}
                <div className="p-1">
                  <InsightItem
                    editOnly={true}
                    insight={{ key: "", value: "" }}
                    updateInsight={update_insights}
                  />
                </div>
              </div>
            </div>
            {/* profile description links wrapper */}
            <div className="flex max-h-[100%] flex-1 flex-col gap-1 overflow-y-scroll pl-1 pr-1">
              {/* <ul className=" flex flex-col gap-1">
                {observations.map((m, i) => (
                  <Link id={m} i={i} desc_on={true} />
                ))}
              </ul> */}
              {Array.isArray(observations) ? (
                <>
                  {observations.length ? (
                    observations.map((m, i) => (
                      <Link id={m} i={i} desc_on={true} />
                    ))
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <LinkIdAdder on_attach_link={add_link_to_observations} />
              <div>
                <Button text="Save" onclick={update_profile_exp} />
              </div>
            </div>
            {/* Image gallary wrapper */}
            {/* <div className="max-h-[100%] overflow-y-scroll">
              {gallary_loading ? (
                <Loading size_num={60} />
              ) : (
                <>
                  {gallary ? (
                    <ImgGallery imgs={gallary} gallary_edit={true} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div> */}
          </div>
        </div>
      </SideBar>
    </div>
  );
}

type InsightItemSchema = {
  insight: typeof insightSchama;
  editOnly?: boolean;
  updateInsight?: any;
};
const InsightItem = ({
  insight,
  editOnly = false,
  updateInsight,
}: InsightItemSchema) => {
  const [new_insight, setNew_insight] = useState(insight);
  const [editOn, setEditOn] = useState(false);

  useEffect(() => {
    setNew_insight(insight);

    return () => {};
  }, [insight]);

  return (
    <div className="flex items-center gap-0 overflow-auto px-2 backdrop-brightness-50 ">
      {editOn || editOnly ? (
        <>
          {!editOnly && (
            <MaterialIcon icon="cancel" onclick={() => setEditOn(false)} />
          )}
          <Button
            text={editOnly ? "Add" : "Update"}
            onclick={() => {
              updateInsight(new_insight);
              setNew_insight({ key: "", value: "" });
              setEditOn(false);
            }}
          />
        </>
      ) : (
        <MaterialIcon icon="edit" onclick={() => setEditOn(true)} />
      )}
      <Input
        className={
          editOn
            ? "mx-1 w-20 "
            : "mx-1 w-20 bg-transparent font-semibold text-orange-400"
        }
        editOn={editOn || editOnly}
        placeholder="New Key"
        value={new_insight.key}
        onchange={(e: any) => setNew_insight({ ...new_insight, key: e })}
      />
      <Input
        className={
          editOn
            ? "mx-1 w-20 "
            : "mx-1 w-20 bg-transparent font-semibold text-white"
        }
        editOn={editOn || editOnly}
        placeholder="Value"
        value={new_insight.value}
        onchange={(e: any) => setNew_insight({ ...new_insight, value: e })}
      />
    </div>
  );
};
