import React, { useState } from "react";

const default_knowMe = {
  title: "Know Me is Here to help you!",
  desc: " Long long discription goes here.",
  todos: ["task One", "task Two"],
};

type Props = {
  knowMe: typeof default_knowMe;
};

const default_state = {
  looking: false,
};
type State = typeof default_state;

function KnowMe({ knowMe = default_knowMe }: Props) {
  const [state, setState] = useState<State>(default_state);
  return (
    <div className="absolute h-full w-full p-20">
      {state.looking ? (
        <div className="flex flex-col items-center justify-center gap-2 overflow-scroll rounded-md bg-white p-2">
          <div className="p-4 text-lg font-bold">{knowMe.title}</div>
          <div className="p-4 text-sm font-semibold">{knowMe.desc}</div>
          <div className="flex gap-2">
            <div className="flex flex-col">
              <div className="p-4 text-base font-bold">States</div>
              <div className="flex flex-col gap-1">
                <ol className="list-disc">
                  {[
                    "planToday : will look for existing Today if not found will create one based on rules defined.",
                    " Rules : There is one specific format decided for Events/Activities.",
                    "Today Created ( mongodb ) : To maintain the records instead of recreating each time based on preferences",
                    "Add Activity ( Re-plan )",
                    "(Tasks,Events,Etc) => Parser (calender-format) => Sorter => Final List",
                    "We have to Maintain a Today, which will have the current state of Today",
                  ].map((m) => (
                    <li>{m}</li>
                  ))}
                </ol>
              </div>
            </div>
            {knowMe.todos ? (
              <div className="flex flex-col">
                <div className="p-4 text-base font-bold">Todos</div>
                <div className="flex list-decimal flex-col gap-1">
                  {knowMe.todos.map((m) => (
                    <li>{m}</li>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col">
            <div className="p-4 text-base font-bold">KnowMe Features !</div>
            <div className="flex list-decimal flex-col gap-1">
              {[
                "Schema Section to be added",
                "Iframe for system design",
                "",
              ].map((m) => (
                <li>{m}</li>
              ))}
            </div>
          </div>
          <div className="p-4 text-xs ">
            This has to be here and not on the server. Just go with the
            KnowMe.json File
          </div>
          <div
            onClick={() => setState({ ...state, looking: !state.looking })}
            className="h-fit w-fit rounded-md bg-green-300 p-2 text-sm"
          >
            Close
          </div>
        </div>
      ) : (
        <div
          onClick={() => setState({ ...state, looking: !state.looking })}
          className="absolute right-6 top-6 flex h-8 w-8 items-center justify-center rounded-md border border-red-500 bg-white"
        >
          i
        </div>
      )}
    </div>
  );
}

export default KnowMe;
