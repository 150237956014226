import "./css/loading.css";
import loading from "../../data/images/Loading-Img.gif";

export function Loading({ size = "medium", size_num = 60 }) {
  return (
    <>
      <div className="loading-img-wrap">
        <img
          className={"loading-img"}
          style={{
            height: size_num + "px",
            // width: size_num + "px"
          }}
          alt="topic-wait"
          src={loading}
        />
      </div>
    </>
  );
}
