export const timeToNumber = (time: string) => {
  console.log(time);
  let ans =
    Number(time.split(":")[0]) * 60 * 60 + Number(time.split(":")[1]) * 60;
  return ans;
};

export const timeToString = (time: number) => {
  let ans =
    Math.floor(time / 3600)
      .toString()
      .padStart(2, "0") +
    ":" +
    ((time % 3600) / 60).toString().padStart(2, "0");
  return ans;
};
