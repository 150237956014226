import { Button } from "react-utility-yard";
import { AknowledgementGif } from "./aknowledgement_gif";
// import { MaterialIcon } from "./material_icon";
import { useState } from "react";

export const InputFile = ({
  fileType = null,
  submit,
  submit_bnt = true,
  upload_done = false,
  width = "200px",
  value = null,
}) => {
  const [files, setFiles] = useState(value);

  const input_file = (e) => {
    setFiles(e.target.files);
  };
  const on_submit = () => {
    // console.log(files[0]);
    if (files && files.length > 0) submit(files[0]);
  };

  return (
    <div
      className="input-file-handler"
      style={{
        display: "flex",
        alignItems: "center",
        background: "white",
        width: width,
        borderRadius: "5px",
        margin: "2px",
      }}
    >
      <input
        // className="w-fit"
        type="file"
        name="file"
        // value={files && files.length > 0 ? files[0] : ""}
        placeholder="No file Selected"
        onChange={(e) => input_file(e)}
      />
      <Button
        color={submit_bnt ? "cool" : "warm"}
        text="Up-load"
        onclick={() => submit_bnt && on_submit()}
      />
      {upload_done ? (
        <AknowledgementGif status={upload_done ? "success" : "error"} />
      ) : (
        <></>
      )}
    </div>
  );
};
