import React, { useState, FC, useEffect } from "react";
import { List, arrayMove, arrayRemove } from "react-movable";
import { useSelector } from "react-redux";
import { vinanti } from "../../data/data.js";
import { Loading } from "../../components/utils/loading.js";
import { RootState } from "../../store/store.ts";
import { Button, Input } from "react-utility-yard";

interface prop {
  list_name: string;
  id: string;
}

export const DuckList: FC<prop> = ({ list_name, id }: prop) => {
  list_name = list_name ?? "Duck";
  const [items, setItems] = useState<string[]>([]);
  const [text_input, setText_input] = useState("");
  const [loading_state, setLoading_state] = useState(false);
  const user_info = useSelector((state: RootState) => state.user_info);

  const add_new = (item: string = "None") => {
    setItems([...items, text_input]);
    setText_input("");
  };

  const save_list = async () => {
    setLoading_state(true);
    let data = {
      _id: id,
      name: list_name,
      data: items,
    };
    await vinanti("update", "custom", user_info.auth_token, {
      updated_info: data,
      email: user_info.user_email,
    });
    setLoading_state(false);
  };

  const get_list = async () => {
    setLoading_state(true);
    let data: any = await vinanti("get_id", "custom", user_info.auth_token, {
      id: id,
      email: user_info.email,
    });
    if (data) {
      setItems(data[0]?.data);
      console.log(data);
    }
    setLoading_state(false);
  };

  useEffect(() => {
    // console.log("useeffect Ran", Date.now());
    get_list();
    return () => {
      setItems([]);
      list_name = "Duck";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" min-h-auto max-h-[350px]  rounded bg-white p-2">
      <div className="flex bg-slate-400  pl-2">
        {list_name}
        {loading_state && <Loading size_num={25} />}
      </div>
      <div className="m-2 max-h-[220px] max-w-[350px] overflow-scroll">
        {items?.length > 0 && (
          <List
            values={items}
            onChange={({ oldIndex, newIndex }) => {
              if (newIndex === -1) {
                setItems([...arrayRemove(items, oldIndex)]);
              } else setItems([...arrayMove(items, oldIndex, newIndex)]);
            }}
            removableByMove={true}
            renderList={({ children, props }) => (
              <ul
                className="[&>*:nth-child(1)]:font-black [&>*:nth-child(even)]:bg-slate-200"
                {...props}
              >
                {children}
              </ul>
            )}
            renderItem={({ value, props }) => (
              // rendering issue is there for adding the links mostly relatd to useeffect
              <li className="px-1" {...props}>
                {value}
              </li>
            )}
          />
        )}
      </div>
      <div className="m-1">
        <Input
          editOn={true}
          size="sm"
          value={text_input}
          onchange={(value: any) => setText_input(value)}
          placeholder={"New " + list_name.split(" ")[0]}
        />
        <div className="m-1 flex">
          <Button
            text="Add"
            onclick={() => add_new()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
          <Button
            text="Save"
            onclick={() => save_list()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
          <Button
            text="Get List"
            onclick={() => get_list()}
            focus={false}
            onkeydown={null}
            z_id={10}
            size="sm"
            loading={false}
            // icon={null}
          />
        </div>
      </div>
    </div>
  );
};
