//Tasks
export const tasks_update_list = "TASKS_LIST_UPDATE";
export const tasks_new_task = "TASKS_NEW_TASK";
export const tasks_update_task_to_list = "TASKS_UPDATE_TASK_TO_LIST";
export const tasks_delete_task_from_list = "TASKS_DELETE_TASK_FROM_LIST";

// Profile Io
export const profile_update_list = "PROFILE_LIST_UPDATE";
export const profile_update_profile_to_list = "PROFILE_UPDATE_PROFILE_TO_LIST";
export const profile_set_curr_active = "PROFILE_SET_CURRENT_ACTIVE";
export const profile_set_curr_exp = "PROFILE_SET_CURRENT_ACTIVE_EXP";

// Links Io
export const link_update_list = "LINK_LIST_UPDATE";
export const link_update_link_to_list = "LINK_LIST_UPDATE_LINK";
export const link_add_to_list = "LINK_LIST_ADD";
export const link_set_wrapper_view = "LINK_SET_WRAPPER_VIEW";

// Today
export const today_update_raw_list = "TODAY_UPDATE_RAW_LIST";
export const today_update_today = "TODAY_UPDATE_TODAY";

// Streaks
export const streaks_update_raw_list = "STREAKS_UPDATE_RAW_LIST";