// import { useRef, useEffect } from "react";
import "./css/dict.css";
import { TextToSpeech } from "./text_to_speech";

export function Oxford({ dict_info = null }) {
  const info = dict_info;
  let audio = new Audio(
    info?.lexicalEntries?.[0].entries?.[0].pronunciations?.[0].audioFile,
  );
  return (
    <>
      <div className="dict-outer-wrapper">
        <div className="dict-header-wrapper">
          <div className="dict-header-text">{info?.word}</div>
          <div className="dict-header-audio" onClick={() => audio.play()}>
            <span>
              <i className="material-icons">volume_up</i>
            </span>
          </div>
          <div className="dict-header-text-mini">
            {
              info?.lexicalEntries?.[0].entries?.[0].pronunciations?.[0]
                .phoneticSpelling
            }
          </div>
        </div>
        <div className="dict-content-wrapper">
          {info?.lexicalEntries.map((d) => (
            <div className="dict-content-item">
              <div className="dict-content-item-header">
                {d.lexicalCategory.text}
              </div>
              <ol className="dict-content-items">
                {d.entries?.[0].senses.map((e) => (
                  <li className="dict-content-item-info-wrapper">
                    <div className="dict-content-item-info">
                      <div className="dict-content-item-info-one">
                        {e.definitions?.[0]}
                      </div>
                      <div className="dict-content-item-info-two">
                        "{e.examples?.[0].text}"
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const dummy_dict = {
  _id: "636254ef1279834c385ba7d1",
  word: "MOON",
  data: {
    MEANINGS: {
      1: [
        "Noun",
        "the natural satellite of the Earth",
        [],
        [
          "the average distance to the Moon is 384,400 kilometers",
          "men first stepped on the moon in 1969",
        ],
      ],
      2: [
        "Verb",
        "be idle in a listless or dreamy way",
        ["Idle", "Laze", "Slug", "Stagnate"],
        [],
      ],
      3: [
        "Verb",
        "expose one's buttocks to",
        ["Expose", "Exhibit", "Display"],
        ["moon the audience"],
      ],
      5: [
        "Noun",
        "United States religious leader (born in Korea) who founded the Unification Church in 1954; was found guilty of conspiracy to evade taxes (born in 1920)",
        [],
        [],
      ],
      6: [
        "Noun",
        "any natural satellite of a planet",
        ["Satellite"],
        ["Jupiter has sixteen moons"],
      ],
    },
    ANTONYMS: ["dark"],
    SYNONYMS: ["Moonshine", "Synodic month", "Lunar month", "Daydream", "Moon"],
  },
  visits: ["[object Object]"],
  date_created: "[object Object]",
  oxford: null,
};
export function Dict({ dict_info = dummy_dict }) {
  const info = dict_info;
  return (
    <>
      <div className="dict-outer-wrapper rounded-lg">
        <div className="dict-header-wrapper gap-2">
          <div className="dict-header-text">
            {info?.word[0].toUpperCase() + info?.word.slice(1).toLowerCase()}
          </div>
          <TextToSpeech txt={info.word} />
          {/* <div className="dict-header-text-mini">{"nukes"}</div> */}
        </div>
        <div className="dict-content-wrapper">
          {Object.keys(info.data.MEANINGS).map((key, i) => {
            const value = info.data.MEANINGS[key];
            // console.log("imp key ", key, value);
            return (
              <>
                <div className="dict-content-item">
                  <div className="dict-content-item-header">{value[0]}</div>
                  <ol className="dict-content-items">
                    <li className="dict-content-item-info-wrapper">
                      <div className="dict-content-item-info">
                        <div className="dict-content-item-info-one">
                          {value?.[1]}
                        </div>
                        {value[3].map((m) => (
                          <div className="dict-content-item-info-two">{m}</div>
                        ))}
                      </div>
                    </li>
                  </ol>
                </div>
              </>
            );
          })}
          {info.data.SYNONYMS.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="text-green-400">Similar</div>
              <div className="flex gap-1 text-black">
                {info.data.SYNONYMS.map((m) => (
                  <div className="m-1 rounded-md bg-slate-200 px-1">{m}</div>
                ))}
              </div>
            </div>
          )}
          {info.data.ANTONYMS.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="text-green-400">Antonyms</div>
              <div className="flex gap-1 text-black">
                {info.data.ANTONYMS.map((m) => (
                  <div className="m-1 rounded-md bg-slate-200 px-1">{m}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
